import "react-toastify/dist/ReactToastify.css";

import React from "react";
import { toast,ToastContainer } from "react-toastify";

const ReactNotifications = ({ title, body }) => {
  toast.info(
    <div>
      <h4>{title}</h4>
      <p>{body}</p>
    </div>
  );

  return <ToastContainer />;
};

export default ReactNotifications;
