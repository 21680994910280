import './AreaOfExpertise.css';

import { Done } from '@material-ui/icons';
import { Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getAllCategories } from 'redux/actions/CategoriesExpertiseAction';

import {
  getUserData,
  updateAreaOfExperties,
} from '../../../redux/actions/UserAction';
import ProfileClickedContext from './../../../utils/context';
import Loader from './../../Loader/Loader';

const AreaOfExpertise = () => {
  const dispatch = useDispatch();
  const profileClickedContext = useContext(ProfileClickedContext);
  profileClickedContext.profileClickedHandler(true);
  const location = useLocation();
  const massage = location?.state?.massage;
  const [isReadonly, setIsReadonly] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const toastOptions = {
    position: 'top-right',
    autoClose: 4000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  };

  useEffect(() => {
    if (massage) {
      toast.error(massage, toastOptions);
    }
  }, [massage]);

  const { allCategories } = useSelector(
    (state) => state.CategoriesExpertiseReducer
  );

  const { userDetails } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    dispatch(getUserData());
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    if (userDetails?.data?.levelOfExpertise.length < 1) {
      setIsReadonly(false);
    } else if (userDetails && allCategories?.length) {
      let subCats = [...selectedSubCategories];
      for (let i = 0; i < allCategories.length; i++) {
        for (let j = 0; j < allCategories[i].subCategories.length; j++) {
          let expertieeAvailable = userDetails.data?.levelOfExpertise.find(
            (el) => el.value == allCategories[i].subCategories[j].id
          );
          if (expertieeAvailable) {
            if (!subCats.includes(allCategories[i].subCategories[j].id)) {
              subCats.push(allCategories[i].subCategories[j].id);
            }
          }
        }
      }
      setSelectedSubCategories(subCats);
    }
  }, [userDetails, allCategories]);

  let handleFormEnabled = () => {
    setIsReadonly(!isReadonly);
  };

  const handleResponse = (data) => {
    if (data.status) {
      setIsReadonly(true);
      setLoading(false);
      toast.success(data.message, toastOptions);
    } else {
      setLoading(false);
      toast.error(data.message, toastOptions);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(updateAreaOfExperties(selectedSubCategories, handleResponse));
  };

  const handleOpenSubCategories = (categoryId) => {
    if (selectedCategory == categoryId) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryId);
    }
  };

  const handleSelectSubCategory = (subCategoryId) => {
    let subCategories = [...selectedSubCategories];
    if (subCategories.includes(subCategoryId)) {
      let index = subCategories.indexOf(subCategoryId);
      if (index > -1) {
        subCategories.splice(index, 1);
      }
    } else {
      subCategories.push(subCategoryId);
    }
    setSelectedSubCategories(subCategories);
  };

  return (
    <div className="Font_Family theme_text_color area_expertise_main_div">
      <Container>
        <ToastContainer />
        <Row className="area_expertise_row_container">
          <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
            <div className="area_expertise_heading_div">
              <Typography variant="h6" component="div">
                AREA OF EXPERTISE
              </Typography>
              {userDetails.data?.levelOfExpertise.length > 0 ? (
                <Button
                  className="text-uppercase"
                  style={{
                    color: '#d40000',
                    backgroundColor: 'white',
                    borderColor: 'white',
                    fontWeight: 'bold',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                  type="submit"
                  onClick={handleFormEnabled}
                >
                  {isReadonly ? (
                    <span className="ps-2 capitalize">Edit</span>
                  ) : (
                    <span className="ps-2 capitalize">Cancel</span>
                  )}
                </Button>
              ) : (
                ''
              )}
            </div>

            <form onSubmit={handleSubmit}>
              {allCategories.map((category, index) => (
                <div key={index}>
                  <div>
                    <div
                      style={{
                        borderBottom:
                          selectedCategory && selectedCategory == category.id
                            ? 'none'
                            : '1px solid #b2b3b5',
                        fontSize: '15px',
                      }}
                      onClick={() => {
                        handleOpenSubCategories(category.id);
                      }}
                      className="ps-3 text-uppercase area_expertise_category_div"
                    >
                      <b>{category.name}</b>
                    </div>
                    {selectedCategory &&
                      selectedCategory == category.id &&
                      category.subCategories.map((subCategory, index) => (
                        <div
                          key={index}
                          className="area_expertise_subCategory_div"
                          onClick={() => {
                            if (isReadonly == false) {
                              handleSelectSubCategory(subCategory.id);
                            }
                          }}
                        >
                          <div
                            style={{
                              width: '80%',
                              cursor: 'pointer',
                              fontSize: '13px',
                            }}
                            className="ps-5 Font_Family silver_Color text-uppercase"
                          >
                            {subCategory.name}
                          </div>
                          <div
                            style={{
                              width: '20%',
                            }}
                          >
                            {selectedSubCategories.includes(subCategory.id) ? (
                              <Done style={{ color: '#d40000' }} />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
              <div className="my-5 text-center">
                {isReadonly == true || selectedSubCategories.length < 1 ? (
                  <Button
                    className="area_expertise_button text-uppercase"
                    style={{
                      backgroundColor: '#d40000',
                      borderColor: '#d40000',
                      borderRadius: '0px 15px 0px 15px',
                      height: '50px',
                      fontWeight: 'bold',
                    }}
                    disabled
                    type="submit"
                  >
                    <span className="ps-2 capitalize">Update</span>
                  </Button>
                ) : (
                  <Button
                    className="area_expertise_button text-uppercase"
                    style={{
                      backgroundColor: '#d40000',
                      borderColor: '#d40000',
                      borderRadius: '0px 15px 0px 15px',
                      height: '50px',
                    }}
                    onClick={handleSubmit}
                  >
                    <span className="d-flex justify-content-center align-content-center w-100 fw-bold">
                      {loading ? <Loader /> : <span>Update</span>}
                    </span>
                  </Button>
                )}
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AreaOfExpertise;
