import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  R8tingCategoryList: {},
};

const r8tingCategoryListSlice = createSlice({
  name: 'r8tingCategoryList',
  initialState,
  reducers: {
    setR8tingCategoryList: (state, action) => {
      state.R8tingCategoryList = action.payload.data;
    },
  },
});

export const { setR8tingCategoryList } = r8tingCategoryListSlice.actions;

export default r8tingCategoryListSlice.reducer;
