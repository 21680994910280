import "./CurrentJob.css";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { Grid } from "@mui/material";
import { getAllBuyerCurrentJob } from "../../../redux/actions/buyer/BuyerJobAction";

const CurrentJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllBuyerCurrentJob());
  }, []);

  const { allCurrentJobs } = useSelector((state) => state.BuyerJobReducer);

  const handleOpenDetails = (currentJob) => {
    navigate("/job-detail", {
      state: { currentJobData: currentJob },
    });
  };

  return (
    <div
      className="theme_text_color Font_Family buyer_current_job_main_div"
      style={{ marginTop: "20px" }}
    >
      {allCurrentJobs.length < 1 ? (
        <div
          style={{ textAlign: "center", fontSize: "20px", marginTop: "50px" }}
        >
          <b>NO JOB AVAILABLE</b>
        </div>
      ) : (
        <div className="d-flex flex-column w-100">
          <tbody
            style={{ display: "block" }}
            className="currentjob_tbody_buyer1 Font_Family"
          >
            <tr className="currentjob_tr_buyer">
              <td
                className="currentjob_td_buyer Font_Family text-theme_text_color text-uppercase d-flex flex-row "
                style={{
                  width: "40%",
                }}
              >
                <span
                  style={{
                    fontSize: "10",
                    alignItems: "center",
                    color: "white",
                  }}
                  className="theme_text_color Font_Family fw-bold"
                >
                  VEHICLE
                </span>
              </td>
              <td
                className="currentjob_td Font_Family text-theme_text_color "
                style={{
                  width: "30%",
                }}
              >
                <span
                  style={{
                    fontSize: "10",
                    alignItems: "center",
                    color: "white",
                  }}
                  className="theme_text_color Font_Family fw-bold"
                >
                  PRICE
                </span>
              </td>
              <td className="currentjob_td currentjob_td_status Font_Family text-theme_text_color d-flex flex-row justify-content-lg-start">
                <span
                  className="Font_Family fw-bold text-uppercase"
                  style={{
                    fontSize: "10",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  status
                </span>
              </td>
            </tr>
          </tbody>
          {allCurrentJobs.map((item) => (
            <tbody
              key={item.id}
              style={{ display: "block" }}
              className="currentjob_tbody_buyer Font_Family"
              onClick={() => handleOpenDetails(item)}
            >
              <tr key={item.id} className="currentjob_tr_buyer">
                <td
                  className="currentjob_td_buyer Font_Family text-theme_text_color text-uppercase d-flex flex-row align-items-center"
                  style={{
                    width: "40%",
                  }}
                >
                  <svg
                    viewBox="0 0 100 100"
                    version="1.1"
                    xmlns={item.category.icon}
                    style={{ marginRight: "10px" }}
                    className="siglejob_svg"
                  >
                    <defs>
                      <pattern
                        id={item.id}
                        patternUnits="userSpaceOnUse"
                        width="100"
                        height="100"
                      >
                        <image
                          xlinkHref={item.category.icon}
                          x="-25"
                          width="250"
                          height="100"
                        />
                      </pattern>
                    </defs>
                    <polygon
                      points="50 1 95 25 95 75 50 99 5 75 5 25"
                      fill={`url(#${item.id})`}
                    />
                  </svg>
                  {/* <div className="square">
                    <img src={item.category.icon} className="pic" />
                  </div> */}
                  <br />
                  <span
                    style={{
                      // width: "",
                      alignItems: "center",
                    }}
                  >
                    {/* <span> */}
                    <p className="buyer_current_job_discription">
                      {item.make}
                      <br />
                      <span
                        className="Font_Family silver_Color fw-bold"
                        style={{ fontSize: "10px" }}
                      >
                        {item.model}
                      </span>
                      <br />
                      {item.year && (
                        <span
                          style={{
                            fontSize: "10px",
                            backgroundColor: "#edeeee",
                            borderRadius: "15px",
                            padding: "2px 4px",
                            textAlign: "center",
                            color: "#414042",
                          }}
                          className="Font_Family fw-bold"
                        >
                          {item.year}
                        </span>
                      )}
                    </p>
                    {/* </span>
                   <br /> */}
                  </span>
                </td>
                <td
                  className="currentjob_td Font_Family text-theme_text_color "
                  style={{
                    width: "30%",
                  }}
                >
                  <span className="theme-color Font_Family fw-bold buyer_current_job_price">
                    ${item.askingPrice}
                  </span>
                </td>
                <td className="currentjob_td  currentjob_td_status Font_Family text-theme_text_color d-flex flex-row justify-content-lg-start">
                  <span
                    className="Font_Family fw-bold text-uppercase buyer_current_job_status"
                    style={{
                      color:
                        item.status == "Accepted" ||
                        item.status == "Media Pending"
                          ? "green"
                          : "orange",
                    }}
                  >
                    {item.status}
                  </span>
                </td>
              </tr>
            </tbody>
            //{" "}
            // </table>
          ))}
          <div style={{ width: "100%", height: "100px" }}></div>
        </div>
      )}
    </div>
  );
};

export default CurrentJob;
