import React, { useEffect,useState } from "react";

import { getToken } from "../../../src/firebaseInit.js";

const Notifications = () => {
  const [tokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    let data = null;

    const tokenFunction = async () => {
      data = await getToken(setTokenFound);
      if (data) {
        console.log("Token is", tokenFound);
        console.log("Token is", data);
      }
      return data;
    };
    tokenFunction();
  }, [setTokenFound]);

  return <></>;
};

export default Notifications;
