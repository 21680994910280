import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jobData: {},
};

const singleJobDetailSlice = createSlice({
  name: 'singleJobDetail',
  initialState,
  reducers: {
    setSingleJobDetails: (state, action) => {
      state.jobData = action.payload.data;
    },
  },
});

export const { setSingleJobDetails } = singleJobDetailSlice.actions;

export default singleJobDetailSlice.reducer;
