import './specialNotes.css';

import apiProtected from 'api/apiClient';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { ApiUrl } from '../../../ApiUrl';
import Loader from '../../Loader/Loader';

function SpecialNotes() {
  const navigate = useNavigate();
  const location = useLocation();
  const jobData = location.state.jobData;
  const [loading, setLoading] = useState(false);
  const [specialNotes, setSpecialNotes] = useState(null);

  const handlePressNext = () => {
    setLoading(true);

    apiProtected
      .post(`${ApiUrl.server_url}v1/rating-special-messages/`, {
        jobId: jobData.id,
        specialMessage: specialNotes,
        active: true,
      })
      .then(() => {
        navigate('/r8tr-rating', { state: { jobData: jobData } });
      })
      .catch((error) => {
        setLoading(false);
        console.log('Error occured in special notes', error);
      });
  };

  return (
    <Container className="r8ting_main_container">
      <Row className="R8trRating_text_row" style={{ marginTop: '30px' }}>
        <Row className="R8trRating_inner_row" style={{ marginTop: '30px' }}>
          <Col md={2} lg={2} className="R8trRating_text_col"></Col>
          <Col xs={12} sm={12} md={8} lg={8} className="R8trRating_text_col">
            <div style={{ width: '100%' }}>
              <p
                style={{ fontSize: '18px' }}
                className="fw-bold theme_text_color Font_Family"
              >
                SPECIAL NOTES ...
              </p>
              <textarea
                type="text"
                placeholder="TYPE YOUR MESSAGE HERE..."
                value={specialNotes}
                style={{
                  width: '100%',
                  height: '100px',
                  resize: 'none',
                  padding: '5px',
                }}
                onChange={(e) => setSpecialNotes(e.target.value)}
              ></textarea>
            </div>
          </Col>
          <Col md={2} lg={2} className="R8trRating_text_col"></Col>
        </Row>

        <button
          className="btn Font_Family"
          style={{
            height: '45px',
            borderRadius: '0px 15px 0px 15px',
            backgroundColor: '#e61d2a',
            color: 'white',
            width: '20%',
          }}
          disabled={!specialNotes}
          onClick={() => {
            handlePressNext();
          }}
        >
          <span className="d-flex justify-content-center align-content-center w-100">
            {loading ? <Loader /> : <span> NEXT</span>}
          </span>
        </button>
      </Row>
    </Container>
  );
}

export default SpecialNotes;
