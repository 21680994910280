import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  R8tingCategoryDamages: [],
};

const r8tingCategoryDamagesSlice = createSlice({
  name: 'r8tingCategoryDamages',
  initialState,
  reducers: {
    setR8tingCategoryDamages: (state, action) => {
      state.R8tingCategoryDamages = [...action.payload.data];
    },
  },
});

export const { setR8tingCategoryDamages } = r8tingCategoryDamagesSlice.actions;

export default r8tingCategoryDamagesSlice.reducer;
