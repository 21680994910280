import { createSlice } from '@reduxjs/toolkit';

type LoginSliceType = {
  isLoggedIn: boolean;
  isLoading: boolean;
};

const initialState: LoginSliceType = {
  isLoggedIn: false,
  isLoading: false,
};

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsLoggedIn(state, action) {
      state.isLoading = false;
      state.isLoggedIn = action.payload;
    },
  },
});

export const { setIsLoggedIn, setIsLoading } = loginSlice.actions;
export default loginSlice.reducer;
