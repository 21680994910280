import { makeStyles } from "@material-ui/core";

export const LayoutStyle = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    // height: "100vh",
    // position: "fixed",
    // overflow: "scroll",
  },
  main: {
    width: "100%",
    // position: "relative",
    // height: "100vh",
  },
  topBarWidth: {
    height: "65px",
    // height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
  },
  // height: (theme) => `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
}));
