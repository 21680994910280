import apiProtected from 'api/apiClient';
import { deleteUserAccount } from 'redux/reducers/DeleteUserAccountReducer';

import { ApiUrl } from '../../ApiUrl';
// import { useNavigate } from "react-router-dom";
export const DeleteUserAccountAction =
  (DeleteUserAccountActionRes) => (dispatch) => {
    apiProtected
      .delete(`${ApiUrl.server_url}v1/users/delete-me`)
      .then((res) => {
        DeleteUserAccountActionRes();
        dispatch(deleteUserAccount(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
