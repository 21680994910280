import './AddressDetails.css';

import { Container } from '@mui/material';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { BsAsterisk } from 'react-icons/bs';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { completeCreateJob } from 'redux/reducers/buyer/HireR8trReducer';
import swalWithBootstrapButtons from 'sweetalert2';

const AddressDetails = ({ onPressNext, handleTosterFire }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listing, setListing] = useState('');
  const [r8tedVehicleAddress, setR8tedVehicleAddress] = useState('');
  const [jobLatitude, setJobLatitude] = useState(null);
  const [jobLongitude, setJobLongitude] = useState(null);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  const { createJob } = useSelector((state) => state.HireR8trReducer);

  const handleListingChange = (event) => {
    setListing(event.target.value);
  };

  // const handleR8tedVehicleAddressChange = (event) => {
  //   setR8tedVehicleAddress(event.target.value);
  // };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleZipChange = (event) => {
    setZip(event.target.value);
  };

  const handleSelectAddress = async (jobAddress) => {
    if (jobAddress) {
      try {
        let results = await geocodeByAddress(jobAddress);
        let latLng = await getLatLng(results[0]);
        for (let i = 0; i < results[0].address_components.length; i++) {
          if (
            results[0].address_components[i].types.includes(
              'administrative_area_level_1'
            )
          ) {
            setState(results[0].address_components[i].long_name);
          }

          if (
            results[0].address_components[i].types.includes(
              'administrative_area_level_2'
            )
          ) {
            setCity(results[0].address_components[i].long_name);
          }
        }
        setR8tedVehicleAddress(jobAddress);
        setJobLatitude(latLng.lat);
        setJobLongitude(latLng.lng);
      } catch (error) {
        console.error('Error', error);
        setR8tedVehicleAddress('');
        setJobLatitude(null);
        setJobLongitude(null);
      }
    }
  };

  const handleChangeAddress = (address) => {
    setR8tedVehicleAddress(address);
    setJobLatitude(null);
    setJobLongitude(null);
  };
  const handleJobsCancelled = () => {
    swalWithBootstrapButtons
      .fire({
        title:
          'Do you really want to go back to dashboard without creating job ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel it!',
        cancelButtonText: "No, don't cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success',
        },
        buttonsStyling: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // Swal.fire("Canceled Job!", "Your job has been canceled.", "success");
          navigate('/');
        }
        // status = 1;
      });
  };
  const handleSubmit = async () => {
    if (!r8tedVehicleAddress || !city || !state || !zip || zip < 1) {
      handleTosterFire('Please fill the required fields');

      return;
    }

    let latitude = jobLatitude;
    let longitude = jobLongitude;
    if (!latitude || !longitude) {
      try {
        let results = await geocodeByAddress(r8tedVehicleAddress);
        let latLng = await getLatLng(results[0]);
        latitude = latLng.lat;
        longitude = latLng.lng;
      } catch (error) {
        handleTosterFire(`${r8tedVehicleAddress} is an invalid address`);
        return;
      }
    }

    let data = {
      link: listing,
      address: r8tedVehicleAddress,
      city: city,
      state: state,
      zip: zip,
      description: createJob.description,
      vinOrSerial: createJob.vinOrSerial,
      year: createJob.year,
      make: createJob.make,
      model: createJob.model,
      categoryId: createJob.categoryId,
      subCategoryId: createJob.subCategoryId,
      buyerCost: createJob.buyerCost,
      askingPrice: createJob.askingPrice,
      jobLatitude: latitude,
      jobLongitude: longitude,
    };
    dispatch(completeCreateJob(data));
    onPressNext();
  };

  return (
    <div className="theme_text_color Font_Family address_detail_main_div">
      <Container>
        <div className="w-100 fw-bold mt-3 ps-2 div_title_address_details theme_text_color">
          HIRE A R8TR STEP 3
        </div>
        <div className="ps-2 div_style_address_details justify-content-between">
          <input
            className="input_address_details  w-100 "
            type="text"
            name="listing"
            placeholder="LINK TO LISTING (RECOMMENDED)"
            // required
            value={listing || ''}
            onChange={handleListingChange}
            style={{ border: 'none' }}
          />
          <BsAsterisk style={{ color: '#a7a9ac', fontSize: '10px' }} />
        </div>

        <div className="ps-2 div_style_address_details justify-content-between">
          <PlacesAutocomplete
            value={r8tedVehicleAddress}
            onChange={(address) => {
              handleChangeAddress(address);
            }}
            onSelect={(address) => {
              handleSelectAddress(address);
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={{ width: '100%' }}>
                <input
                  {...getInputProps({
                    placeholder: 'ADDRESS OF THE VEHICLE TO BE R8TED',
                    className:
                      'location-search-input input_address_details  w-100 ',
                  })}
                  style={{ width: '100%' }}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, suggestionIndex) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    const style = suggestion.active
                      ? {
                          backgroundColor: '#fafafa',
                          cursor: 'pointer',
                        }
                      : {
                          backgroundColor: '#ffffff',
                          cursor: 'pointer',
                        };
                    return (
                      <div
                        key={suggestionIndex}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <BsAsterisk style={{ color: '#a7a9ac', fontSize: '10px' }} />
        </div>
        <div className="ps-2 div_style_address_details justify-content-between">
          <input
            className="input_address_details  w-100 "
            type="text"
            name="city"
            placeholder="CITY"
            required
            value={city}
            onChange={handleCityChange || ''}
            style={{ border: 'none' }}
          />
          <BsAsterisk style={{ color: '#a7a9ac', fontSize: '10px' }} />
        </div>

        <div className="ps-2 div_style_address_details justify-content-between">
          <input
            className="input_address_details theme_text_color w-100 "
            type="text"
            name="state"
            placeholder="STATE"
            required
            value={state}
            onChange={handleStateChange || ''}
            style={{ border: 'none' }}
          />
          <BsAsterisk style={{ color: '#a7a9ac', fontSize: '10px' }} />
        </div>

        <div className="ps-2 div_style_address_details justify-content-between">
          <input
            className="input_address_details  w-100 "
            type="number"
            name="zip"
            placeholder="ZIP"
            required
            value={zip || ''}
            onChange={handleZipChange}
            min={1}
            style={{ border: 'none' }}
          />
          <BsAsterisk style={{ color: '#a7a9ac' }} />
        </div>

        <div className="my-3 text-center">
          <Button
            className="job_price_button theme_text_color"
            style={{
              backgroundColor: '#d40000',
              borderColor: '#e61d2a',
              borderRadius: '0px 15px 0px 15px',
              height: '40px',
            }}
            // disabled={
            //   !r8tedVehicleAddress || !city || !state || !zip || zip < 1
            // }
            type="button"
            onClick={handleSubmit}
          >
            <span className="d-flex justify-content-center align-content-center w-100">
              NEXT
            </span>
          </Button>
          <Button
            className="job_price_button theme_text_color mx-3"
            style={{
              backgroundColor: 'black',
              borderColor: 'black',
              borderRadius: '0px 15px 0px 15px',
              height: '40px',
            }}
            type="button"
            onClick={handleJobsCancelled}
          >
            <span className="d-flex justify-content-center align-content-center w-100 text-uppercase">
              Cancel
            </span>
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default AddressDetails;
