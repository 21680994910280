import './notification.css';

import apiProtected from 'api/apiClient';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ApiUrl } from '../../../ApiUrl';
import close from '../../../assets/images/notification/close-icon.webp';
import { R8trNotificationAction } from '../../../redux/actions/R8tr/R8trNotificationAction';
import * as SweetAlert from '../../SweetAlert';

function Notification() {
  const dispatch = useDispatch();
  // const [deleteId, setDeleteId] = useState("");
  const { NotificationData } = useSelector(
    (state) => state.R8trNotificationReducer
  );
  useEffect(() => {
    dispatch(R8trNotificationAction());
  }, []);

  const handleNotificationDelete = (id) => {
    apiProtected
      .delete(`${ApiUrl.server_url}v1/notifications`, {
        params: { notificationId: id },
      })
      .then(() => {
        dispatch(R8trNotificationAction());
      });
  };
  // SweetAlert.errorMessage(error.response.data.message)
  const handleNotificationDeleteAlt = (id) => {
    // setDeleteId(id);
    SweetAlert.altMessage(handleNotificationDelete, id);
  };
  return (
    <div style={{ width: '100%' }}>
      <Container className="notification_main_container">
        <Row className="notification_row_container">
          <Col xxl={10} xl={10} lg={10} md={10} sm={11} xs={11}>
            <table className="table table-striped w-100">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{ fontWeight: 'bold' }}
                    className="Font_Family text-uppercase"
                  >
                    Notification
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: '100%' }}>
                {NotificationData.map((item) => (
                  //   <div key={item.id} className="notificationlist_container">
                  //     <p className="Font_Family">
                  //       {item.title}
                  //       <br />
                  //       <span style={{ fontSize: "10px" }} className="silver_Color">
                  //         {item.body}
                  //       </span>
                  //     </p>
                  //   </div>
                  <tr
                    key={item.id}
                    style={{ width: '100%' }}
                    className="notification_tr"
                  >
                    <th scope="row" className="table_data">
                      <p
                        className="Font_Family text-uppercase"
                        style={{ fontSize: '14px' }}
                      >
                        {item.title}
                        <br />
                        <span
                          style={{ fontSize: '10px' }}
                          className="silver_Color text-uppercase"
                        >
                          {item.body}
                        </span>
                      </p>
                      <img
                        src={close}
                        alt="close"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleNotificationDeleteAlt(item.id)}
                      />
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Notification;
