import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import BuyerJobReducer from './buyer/BuyerJobReducer';
import BuyerRatingR8trReducer from './buyer/BuyerRatingR8trReducer';
import CouponVerifyReducer from './buyer/CouponVerifyReducer';
import HireR8trReducer from './buyer/HireR8trReducer';
import CategoriesExpertiseReducer from './CategoriesExpertiseReducer.js';
import DeleteUserAccountReducer from './DeleteUserAccountReducer';
import GeneratePdfReducer from './GeneratePdfReducer';
import LogInReducer from './LogInReducer';
import loginSlice from './loginSlice';
import R8tinglistReducer from './R8tinglistReducer';
import AcceptedJobsReducer from './R8tr/AcceptedJobsReducer';
import AvailableJobsReducer from './R8tr/AvailableJobsReducer';
import CancelledJobsReducer from './R8tr/CancelledJobsReducer';
import CurrentJobsReducer from './R8tr/CurrentJobsReducer';
import DefaultAddressReducer from './R8tr/DefaultAddressReducer';
import R8tingCategoryDamagesReducer from './R8tr/R8tingCategoryDamagesReducer';
import R8tingCategoryDataReducer from './R8tr/R8tingCategoryDataReducer';
import R8tingCategoryListReducer from './R8tr/R8tingCategoryListReducer';
import R8tingCriteriaRatingsReducer from './R8tr/R8tingCriteriaRatingsReducer';
import R8trNotificationReducer from './R8tr/R8trNotificationReducer';
import SingleJobDetailReducer from './R8tr/SingleJobDetailReducer';
import UserDashboardStatReducer from './R8tr/UserDashboardStatReducer';
import settings from './settings';
import UserReducer from './UserReducer';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
};

const rootReducer = combineReducers({
  loginSlice,
  settings,
  UserDashboardStatReducer,
  DeleteUserAccountReducer,
  SingleJobDetailReducer,
  DefaultAddressReducer,
  AvailableJobsReducer,
  CurrentJobsReducer,
  R8tinglistReducer,
  AcceptedJobsReducer,
  CancelledJobsReducer,
  UserReducer,
  CategoriesExpertiseReducer,
  LogInReducer,
  R8trNotificationReducer,
  R8tingCategoryListReducer,
  R8tingCategoryDataReducer,
  R8tingCategoryDamagesReducer,
  R8tingCriteriaRatingsReducer,
  BuyerJobReducer,
  HireR8trReducer,
  BuyerRatingR8trReducer,
  GeneratePdfReducer,
  CouponVerifyReducer,
});

export { rootPersistConfig, rootReducer };
