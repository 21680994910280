import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  createJob: {},
};

const hireR8trSlice = createSlice({
  name: 'hireR8tr',
  initialState,
  reducers: {
    completeCreateJob: (state, action) => {
      state.createJob = action.payload;
    },
  },
});

export const { completeCreateJob } = hireR8trSlice.actions;

export default hireR8trSlice.reducer;
