import apiProtected from 'api/apiClient';
import { setJobAccepted } from 'redux/reducers/R8tr/AcceptedJobsReducer';

import { ApiUrl } from '../../../ApiUrl';

export const AcceptedJobsAction = (id, handleJobsAcceptedRes) => (dispatch) => {
  apiProtected
    .patch(
      `${ApiUrl.server_url}v1/jobs/job-accepted-by-r8tr`,
      {},
      { params: { jobId: id } }
    )
    .then((res) => {
      handleJobsAcceptedRes(res.data.message);
      dispatch(setJobAccepted(res.data));
    });
};
