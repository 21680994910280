import './sendemail.css';

import { Grid } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import { ToastContainer, toast } from "react-toastify";
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import apiProtected from 'api/apiClient';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ApiUrl } from '../../ApiUrl';
// const toastOptions = {
//   position: "top-right",
//   autoClose: 2000,
//   pauseOnHover: true,
//   draggable: true,
//   theme: "dark",
// };
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function AddCoupon({ open, handleCloseSendEmailModal }) {
  const user = JSON.parse(localStorage.getItem('user'));
  const [state, setState] = useState({
    value: window.location.href + user?.user?.id,
    copied: false,
  });
  const handleClose = () => {
    handleCloseSendEmailModal();
  };
  const handleInvitationLink = () => {
    apiProtected
      .post(ApiUrl.server_url + `v1/invitations/add-invitation`, {
        invitationUrl: state.value,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      {/* <ToastContainer /> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ width: '100% !important' }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className="theme_text_color Font_Family"
        >
          INVITATION LINK
        </BootstrapDialogTitle>
        <hr
          style={{
            marginTop: '-3px',
          }}
        />
        <DialogContent sx={{ width: '100% !important' }}>
          <Grid
            container
            spacing={2}
            className="d-flex justify-content-center align-Items-center"
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="d-flex justify-content-center align-Items-center theme_text_color Font_Family fw-bold"
            >
              COPY AND SHARE THIS LINK
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="d-flex justify-content-center align-Items-center mb-4"
            >
              <div
                style={{
                  background: '#F7F7F7',
                  padding: '10px 10px',
                  borderRadius: '15px',
                }}
              >
                <input
                  style={{
                    width: '400px',
                    height: '40px',
                    border: 'none',
                    background: '#F7F7F7',
                  }}
                  label="url"
                  disabled={true}
                  value={state.value}
                  onChange={({ target: { value } }) =>
                    setState({ value, copied: false })
                  }
                />
                &nbsp; &nbsp;
                <CopyToClipboard
                  text={state.value}
                  onCopy={() => setState({ copied: true })}
                >
                  <button
                    style={{
                      backgroundColor: '#d40000',
                      borderColor: '#d40000',
                      color: 'white',
                      border: 'none',
                      padding: '13px 15px',
                      borderRadius: '15px',
                    }}
                    onClick={handleInvitationLink}
                  >
                    {state.copied ? 'Copied.' : 'Copy'}
                  </button>
                </CopyToClipboard>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default AddCoupon;
