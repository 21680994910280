import apiProtected from 'api/apiClient';

import { ApiUrl } from '../../../ApiUrl';

export const getBuyerRatingR8tr =
  (id, tabeApp, tabeR8tr, commentOne, commentTwo, handlePressNextRes) =>
  (dispatch) => {
    apiProtected
      .post(ApiUrl.server_url + 'v1/user-ratings', {
        rating: tabeR8tr ? tabeR8tr : 0,
        ratingType: 'r8tr',
        appRating: tabeApp ? tabeApp : 0,
        betterAsACompanyComment: commentOne,
        improvementComment: commentTwo,
        jobId: id,
      })
      .then((res) => {
        dispatch(getBuyerRatingR8tr(res.data));
      })
      .catch((error) => {
        const errorMassage = error.response.data.message;
        handlePressNextRes(errorMassage);
      });
  };

export const getBuyerRatingResponce =
  (
    id,
    saveMoney,
    helpR8tr,
    buyProduct,
    handlePressNextRes,
    handlePressNextSuccessRes
  ) =>
  (dispatch) => {
    apiProtected
      .post(
        ApiUrl.server_url + 'v1/buyer-purchase-stats/post-buyer-purchase-stat',
        {
          moneyR8trSavedYou: saveMoney,
          r8trHelpedYouDecide: helpR8tr,
          boughtTheProduct: buyProduct,
          jobId: id,
        }
      )
      .then((res) => {
        handlePressNextSuccessRes(res.data.message);
        dispatch(getBuyerRatingResponce(res.data));
      })
      .catch((error) => {
        const errorMassage = error.response.data.message;
        handlePressNextRes(errorMassage);
      });
  };
