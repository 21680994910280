import React from "react";
import { useLocation } from "react-router-dom";

// import ReactToPdf from "react-to-pdf";
import PastRating from "../PastRating/PastRating";
import SingleJobDetail from "../Singlejobdetail/SingleJobDetail";
import SpecialNotes from "../SpecialNotes/SpecialNotes";
import UploadedVideos from "../UploadedVideos/UploadedVideos";
// import BuyerRatingR8tr from "../BuyerRatingR8tr/BuyerRatingR8tr";
function RatingDetail() {
  const location = useLocation();
  const jobData = location?.state?.jobData;
  // const ref = React.createRef();
  // const options = {
  //   orientation: "p",
  //   unit: "mm",
  //   format: "a4",
  //   putOnlyUsedFonts: true,
  //   floatPrecision: 16,
  // };
  return (
    <>
      {/* <ReactToPdf
        targetRef={ref}
        filename="div-blue.pdf"
        options={options}
        x={5}
        y={5}
        scale={0.6}
      >
        {({ toPdf }) => <button onClick={toPdf}>Generate pdf</button>}
      </ReactToPdf> */}
      <div
        className="mb-4"
        //  ref={ref}
      >
        <SingleJobDetail jobId={jobData.id} />
        {jobData?.includeWalkAroundVideos && (
          <>
            <UploadedVideos jobData={jobData} />
            <SpecialNotes jobData={jobData} />
          </>
        )}
        <PastRating jobData={jobData} />
        {/* <BuyerRatingR8tr /> */}
      </div>
    </>
  );
}

export default RatingDetail;
