import { api } from 'api/apiClient';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../ApiUrl';
import Loader from '../Loader/Loader';
function OtpVerification({
  handleShowOtpVerificationModal,
  forgetPassword,
  phoneNumber,
  userRole,
  itemId,
}) {
  const navigator = useNavigate();
  const toastOptions = {
    position: 'top-right',
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  };
  // const [loading, setLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const handleChange = (otp) => setOtp(otp);
  const handleClose = () => {
    handleShowOtpVerificationModal();
    // console.log(otp);
  };
  const handleVerifyOtp = () => {
    setLoading(true);

    api
      .post(`${ApiUrl.server_url}v1/auth/verify-otp`, {
        phoneNumber: phoneNumber,
        otp: otp,
      })
      .then((res) => {
        console.log(res);
        handleShowOtpVerificationModal();
        if (itemId) {
          if (forgetPassword) {
            setLoading(false);

            navigator(`/set-password?itemID=${itemId}`, {
              state: { phoneNumber: phoneNumber, title: 'Reset Password' },
            });
          } else {
            setLoading(false);

            navigator(`/set-password?itemID=${itemId}`, {
              state: { phoneNumber: phoneNumber, title: 'Set Your Password' },
            });
          }
        } else {
          if (forgetPassword) {
            setLoading(false);

            navigator('/set-password', {
              state: { phoneNumber: phoneNumber, title: 'Reset Password' },
            });
          } else {
            setLoading(false);

            navigator('/set-password', {
              state: { phoneNumber: phoneNumber, title: 'Set Your Password' },
            });
          }
        }
      })
      .catch((error) => {
        let errorMessage = error.response.data.message;

        toast.error(
          errorMessage ? errorMessage : 'Something went wrong',
          toastOptions
        );
        setLoading(false);
      });
  };
  const handleResendOtp = () => {
    setLoading(true);
    api
      .post(`${ApiUrl.server_url}v1/auth/send-otp`, {
        phoneNumber: phoneNumber,
        userRole: userRole,
        forgetPasswordOTP: forgetPassword,
      })
      .then((res) => {
        console.log(res);
        toast.success('OTP has been sent', toastOptions);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = error.data.message;

        toast.error(
          errorMessage ? errorMessage : 'Something went wrong',
          toastOptions
        );
      });
  };
  return (
    <div>
      <ToastContainer />
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <div className=" flex text-center justify-center mt-2">
          <p className="Font_Family">Verify OTP</p>
        </div>
        <Modal.Body
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <OtpInput
            containerStyle={{ width: '260px', height: '80px' }}
            inputStyle={{
              width: '60px',
              height: '50px',
              borderRadius: '20px',
              border: '1px solid lightgray',
            }}
            value={otp}
            onChange={handleChange}
            numInputs={4}
            separator={<span>-</span>}
          />
        </Modal.Body>
        <div className=" flex text-center justify-center">
          <p className="Font_Family">Don`t get OTP</p>
          {loading ? (
            <Loader />
          ) : (
            <p
              className="Font_Family theme-color pe-auto"
              style={{ cursor: 'pointer' }}
              onClick={handleResendOtp}
            >
              <u>RESEND</u>
            </p>
          )}
        </div>
        <div className=" flex text-center justify-center mb-4">
          <button
            type="button"
            onClick={handleVerifyOtp}
            className="btn  w-50 Font_Family"
            style={{
              height: '45px',
              borderRadius: '0px 25px 0px 25px',
              backgroundColor: '#e61d2a',
              color: 'white',
            }}
          >
            <span className="d-flex justify-content-center align-content-center w-100">
              {loading ? <Loader /> : <span>DONE</span>}
            </span>
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default OtpVerification;

OtpVerification.propTypes = {
  handleShowOtpVerificationModal: PropTypes.func,
};
