import './singlejobdetail.css';

import CloseIcon from '@mui/icons-material/Close';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import apiProtected from 'api/apiClient';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import useScreenType from "react-screentype-hook";
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../../ApiUrl';
import { AcceptedJobsAction } from '../../../redux/actions/R8tr/AcceptedJobsAction';
import { CancelledJobsAction } from '../../../redux/actions/R8tr/CancelledJobsAction';
import { SingleJobDetailAction } from '../../../redux/actions/R8tr/SingleJobDetailAction';
import Loader from '../../Loader/Loader';
import * as SweetAlert from '../../SweetAlert';

const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};
function SingleJobDetail() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [openAlert, setOpenAlert] = React.useState(false);
  const jobId = location.state.jobId;
  const { jobData } = useSelector((state) => state.SingleJobDetailReducer);
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const handleJobsAcceptedRes = (message) => {
    dispatch(SingleJobDetailAction(jobId));
    toast.success(message, toastOptions);
  };
  const handleJobsCancelledRes = (message) => {
    setLoading(false);
    dispatch(SingleJobDetailAction(jobId));
    toast.success(message, toastOptions);
  };
  const handleCancelJobsConfirm = () => {
    setLoading(true);
    dispatch(CancelledJobsAction(jobId, handleJobsCancelledRes));
  };
  const handleJobsCancelled = () => {
    SweetAlert.CancelJobsAlert(handleCancelJobsConfirm);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);

    apiProtected
      .patch(
        `${ApiUrl.server_url}v1/jobs/on-my-way`,
        {},
        { params: { jobId: jobData.id } }
      )
      .then(() => {
        dispatch(SingleJobDetailAction(jobId));
      });
  };
  const handleJobsAccepted = () => {
    setLoading(true);
    dispatch(AcceptedJobsAction(jobData.id, handleJobsAcceptedRes));
  };
  const handleR8tingDetail = () => {
    // if (screenType.isMobile) {
    //   setOpen(true);
    // } else {
    // if (jobData?.onMyWay == null || jobData?.onMyWay == false) {
    //   toast.error("Please Select On My Way first", toastOptions);
    // } else {
    //   if (
    //     jobData?.specialMessageAvailable &&
    //     jobData?.specialMessageAvailable == true
    //   ) {
    //     navigator("/r8tr-rating", { state: { jobData: jobData } });
    //   } else if (
    //     jobData?.walkAroundVideoAvailable &&
    //     jobData?.walkAroundVideoAvailable == true
    //   ) {
    //     navigator("/special-notes", { state: { jobData: jobData } });
    //   } else if (
    //     jobData?.ratingsCompleted &&
    //     jobData?.ratingsCompleted == true
    //   ) {
    //     navigator("/upload-videos", { state: { jobData: jobData } });
    //   } else {
    //     navigator("/r8ting", { state: { jobData: jobData } });
    //   }
    // }
    setOpenAlert(true);
    // }
  };
  useEffect(() => {
    dispatch(SingleJobDetailAction(jobId));
  }, [jobId]);
  return (
    <div className="siglejob_Container mb-4">
      <ToastContainer />
      <Container className="siglejob_Container mt-4">
        <Row className="siglejob_tbody">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="siglejob_first_title_container mt-2 mb-4"
          >
            <span
              className="Font_Family theme_text_color h4 title_span w-100 text-uppercase"
              style={{ fontSize: '18px', fontWeight: 'bold' }}
            >
              {jobData?.make}
            </span>

            <span className="Font_Family theme-color w-100 mt-3 d-flex justify-content-end">
              {jobData?.status == 'Accepted' &&
                jobData?.ratingStartedAt == null &&
                (jobData?.onMyWay == null || jobData?.onMyWay == false) && (
                  <p
                    onClick={handleJobsCancelled}
                    style={{
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                    className="text-uppercase"
                  >
                    Cancel Job
                  </p>
                )}
            </span>
          </Col>

          <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12} className="mt-2">
            <div className="siglejob_banner_container ">
              <div className="siglejob_banner ">
                <span className="Font_Family siglejob_banner_span text-uppercase siglejob_banner_name_part">
                  {jobData.description}
                  <br />
                  <span
                    style={{
                      fontWeight: 'normal',
                      fontSize: '16px',
                    }}
                  >
                    {jobData.category?.name}- {jobData.subCategory?.name}
                  </span>
                </span>
                <div className="vertical"></div>

                <span className="Font_Family siglejob_banner_span siglejob_banner_price_part">
                  <span
                    className="theme-color"
                    style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                    }}
                  >
                    ${jobData.buyerCost / 2}
                  </span>
                  <br />
                  <span style={{ fontWeight: 'normal', fontSize: '15px' }}>
                    JOB VALUE
                  </span>
                </span>
              </div>
            </div>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="siglejob_tbody mt-4"
          >
            <span
              className="Font_Family h4 title_span theme_text_color text-uppercase w-100"
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                paddingBottom: '10px',
                borderBottom: '1px solid #a7a9ac',
              }}
            >
              Info
              {/* <hr /> */}
            </span>
          </Col>

          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="siglejob_tbody"
          >
            <table className="siglejob_tbody">
              <tbody className="siglejob_tbody">
                <tr className="siglejob_tr1">
                  <th>YEAR</th>
                  <td className="silver_Color">{jobData?.model}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>MAKE</th>
                  <td className="silver_Color ">{jobData?.make}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>MODEL</th>
                  <td className="silver_Color">{jobData?.model}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>CURRENT ASKING PRICE</th>
                  <td className="silver_Color">{jobData?.askingPrice}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>VIN/SERIAL NUMBER</th>
                  <td className="silver_Color">{jobData?.vinOrSerial}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>LINK TO LIST</th>
                  <td className="theme-color">
                    {/* <Link to={jobData?.link}>bgfhg</Link> */}
                    <a
                      href={`${
                        jobData?.link?.includes('https://')
                          ? jobData?.link
                          : 'https://' + jobData?.link
                      }`}
                      style={{ textDecoration: 'none', color: '#d40000' }}
                      target="blank"
                    >
                      VIEW LISTING
                    </a>
                  </td>
                </tr>
                {jobData?.status == 'Accepted' &&
                  (jobData?.onMyWay == null || jobData?.onMyWay == false) && (
                    <tr className="siglejob_tr1">
                      <th>ON MY WAY</th>
                      <td>
                        <Switch
                          checked={checked}
                          color="success"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </td>
                    </tr>
                  )}
                <tr className="siglejob_tr1">
                  <th>DIRECTION</th>
                  <td className="theme-color direction_td">
                    <a
                      target="blank"
                      href={`https://maps.google.com?q=${jobData?.latitude},${jobData?.longitude}`}
                      style={{ textDecoration: 'none', color: '#d40000' }}
                    >
                      VIEW DIRECTION
                    </a>
                  </td>
                </tr>
                <span
                  className="Font_Family h4 mt-5 title_span theme_text_color"
                  style={{ fontSize: '18px', fontWeight: 'bold' }}
                >
                  LOCATION AND POINT OF CONTACT
                  <hr />
                </span>
                <tr className="siglejob_tr1">
                  <th>ADDRESS</th>
                  <td className="silver_Color direction_td">
                    {jobData?.address}
                  </td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>CONTACT NUMBER</th>
                  <td className="silver_Color">{jobData?.creatorNumber}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>EMAIL</th>
                  <td className="silver_Color">{jobData?.contactEmail}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>POINT OF CONTACT</th>
                  <td className="silver_Color">{jobData?.contactName}</td>
                </tr>
              </tbody>
            </table>
            <span
              className="Font_Family h4 mt-2 title_span theme_text_color"
              style={{ fontSize: '18px', fontWeight: 'bold' }}
            >
              SPECIAL INSTRUCTIONS
              <hr />
              <p
                style={{
                  color: '#5C647C',
                  textAlign: 'left',
                  fontWeight: 'normal',
                  fontSize: '16px',
                }}
              >
                {jobData.specialInstructions ? jobData.specialInstructions : ''}
              </p>
            </span>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="siglejob_tbody"
          >
            {jobData?.status == 'Accepted' ? (
              openAlert ? (
                <Collapse
                  className="mt-4 mb-4"
                  in={openAlert}
                  style={{
                    width: '100%',
                  }}
                >
                  <Alert
                    icon={<PhoneIphoneIcon />}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2, display: 'flex', alignItems: 'center' }}
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.r8tr_app"
                      style={{
                        // textDecoration: "none",
                        // color: "#414042",
                        cursor: 'pointer',
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      Please Download R8tr App for Rating
                    </a>
                  </Alert>
                </Collapse>
              ) : (
                <button
                  type="button"
                  className="btn  Font_Family mt-4 text-center mb-4"
                  style={{
                    height: '45px',
                    borderRadius: '0px 25px 0px 25px',
                    backgroundColor: '#D40000',
                    color: 'white',
                    width: '53%',
                  }}
                  onClick={handleR8tingDetail}
                >
                  <span className="d-flex justify-content-center align-content-center w-100">
                    {jobData?.ratingStartedAt !== null
                      ? ' CONTINUE R8TING'
                      : ' BEGIN R8TING'}
                  </span>
                </button>
              )
            ) : (
              <button
                type="button"
                className="btn  Font_Family mt-4 text-center mb-4"
                style={{
                  height: '45px',
                  borderRadius: '0px 25px 0px 25px',
                  backgroundColor: '#D40000',
                  color: 'white',
                  width: '45%',
                }}
                onClick={handleJobsAccepted}
              >
                <span className="d-flex justify-content-center align-content-center w-100">
                  {loading ? <Loader /> : <span> ACCEPT JOB</span>}
                </span>
              </button>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SingleJobDetail;
