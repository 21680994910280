import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allCategories: [],
  allCosts: [],
};

const categoriesExpertiseSlice = createSlice({
  name: 'categoriesExpertise',
  initialState,
  reducers: {
    getAllExpertiseCategories: (state, action) => {
      state.allCategories = [...action.payload.data];
    },
    getAllCosts: (state, action) => {
      state.allCosts = [...action.payload.data];
    },
  },
});

export const { getAllExpertiseCategories, getAllCosts } =
  categoriesExpertiseSlice.actions;

export default categoriesExpertiseSlice.reducer;
