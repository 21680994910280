// import { useLocation } from "react-router-dom";
import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
  // PDFDownloadLink,
} from "@react-pdf/renderer";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { GeneratePdfAction } from "../../../redux/actions/GeneratePdfAction";
function PdfPage() {
  //   const location = useLocation();
  const dispatch = useDispatch();
  //   const jobId = location.state.jobId;
  const { pdfData } = useSelector((state) => state.GeneratePdfReducer);
  useEffect(() => {
    dispatch(GeneratePdfAction(422));
  }, []);
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      // color: 'white',
    },
    sectionHeading: {
      margin: 10,
      padding: 10,
      textAlign: "center",
      color: "white",
      backgroundColor: "black",
    },
    sections: {
      margin: 10,
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "22px",
    },
    sectionContainer: {
      display: "flex",
      flexDirection: "row",
      marginLeft: "auto",
      marginRight: "auto",

      // width: '100%',
    },
    sectionContainerVideo: {
      display: "flex",
      flexDirection: "row",
      padding: "10px 20px",
    },
    sectionTableVideo: {
      border: "1px solid lightgray",
      // padding: '10px 20px',
      width: "100%",
    },
    sectionTable: {
      border: "1px solid lightgray",
      padding: "10px 20px",
      width: "30%",
    },
    sectionTableRating: {
      border: "1px solid lightgray",
      padding: "10px 20px",
      width: "80%",
    },
    text: {
      fontSize: "12px",
      marginTop: "2px",
    },
    images: {
      width: "100%",
      height: "100px",
      objectFit: "cover",
    },
    sectionContainerCol: {
      display: "flex",
      flexDirection: "row",
      width: "80%",
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
                {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : 'Download now!'
                }
            </PDFDownloadLink> */}
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.sections}>
              <Text>Vehical Inspection</Text>
            </View>
            <View style={styles.sectionHeading}>
              <Text>Vehical Info</Text>
            </View>
            <View style={styles.sectionContainer}>
              <View style={styles.sectionTable}>
                <Text>Year</Text>
                <Text style={styles.text}>{pdfData?.year}</Text>
              </View>
              <View style={styles.sectionTable}>
                <Text>Make</Text>
                <Text style={styles.text}>{pdfData?.make}</Text>
              </View>
              <View style={styles.sectionTable}>
                <Text>Model</Text>
                <Text style={styles.text}>{pdfData?.model}</Text>
              </View>
            </View>
            <View style={styles.sectionHeading}>
              <Text>Location</Text>
            </View>
            <View style={styles.sectionContainer}>
              <View style={styles.sectionTable}>
                <Text>Location</Text>
                <Text style={styles.text}>{pdfData?.contactName}</Text>
              </View>
              <View style={styles.sectionTable}>
                <Text>Address</Text>
                <Text style={styles.text}>{pdfData?.address}</Text>
              </View>
              <View style={styles.sectionTable}>
                <Text>Zip Code</Text>
                <Text style={styles.text}>{pdfData?.zip}</Text>
              </View>
            </View>
            <View style={styles.sectionContainer}>
              <View style={styles.sectionTable}>
                <Text>Contact Number</Text>
                <Text style={styles.text}>{pdfData?.contactNumber}</Text>
              </View>
              <View style={styles.sectionTable}>
                <Text>City</Text>
                <Text style={styles.text}>{pdfData?.city}</Text>
              </View>
              <View style={styles.sectionTable}>
                <Text>State</Text>
                <Text style={styles.text}>{pdfData?.state}</Text>
              </View>
            </View>
          </Page>
          {pdfData?.ratingCriteriaUsers?.map((item) => (
            <>
              <Page key={item.id}>
                <View style={styles.sectionHeading}>
                  <Text>{item?.ratingCriteria?.title}</Text>
                </View>
                <View style={styles.sectionContainer}>
                  <View style={styles.sectionTableRating}>
                    <Text>Damages</Text>
                    <Text style={styles.text}>
                      {item?.ratingCriteriaOption?.ratingTitle}
                    </Text>
                  </View>
                </View>
                {/* <View style={styles.sectionContainerVideo}> */}
                <Row style={styles.sectionContainerVideo}>
                  {item?.ratingCriteriaUserMedia.map((video) => (
                    <Col
                      xl={1}
                      lg={1}
                      md={1}
                      key={video.id}
                      style={styles.sectionContainerCol}
                    >
                      {/* <View style={styles.sectionTableVideo} key={video.id}> */}
                      <Image src={video?.url} style={styles.images} />
                      {/* </View> */}
                    </Col>
                  ))}
                </Row>
                {/* </View> */}
              </Page>
              {/* {setShowPdf(true)} */}
            </>
          ))}
          <Page>
            <View style={styles.sectionHeading}>
              <Text>Special Message</Text>
            </View>
            {console.log("item?.ratingCriteriaOption?.ratingTitle")}
            <View style={styles.sectionContainer}>
              {/* {item?.videos.map((video) => ())} */}
              <View style={styles.sectionMessage}>
                <Text>{pdfData?.specialMessage?.specialMessage}</Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
}

export default PdfPage;
