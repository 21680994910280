import './support.css';

import apiProtected from 'api/apiClient';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../ApiUrl';
import Loader from '../Loader/Loader';
const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};

function Support() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    setLoading(true);

    apiProtected
      .post(`${ApiUrl.server_url}v1/dashboards/post-email-message-to-admins`, {
        message: message,
      })
      .then(() => {
        setLoading(false);
        toast.success('Your support request has been sent', toastOptions);
        setMessage('');
      })
      .catch((error) => {
        setLoading(false);
        console.log('Error occured in support', error);
        toast.success(error.response.data.message, toastOptions);
      });
  };

  return (
    <Row className="Support_text_row" style={{ marginTop: '30px' }}>
      <ToastContainer />
      <Row className="Support_inner_row" style={{ marginTop: '30px' }}>
        <Col md={2} lg={2} className="Support_text_col"></Col>
        <Col xs={12} sm={12} md={8} lg={8} className="Support_text_col">
          <div style={{ width: '100%' }}>
            <p
              style={{ fontSize: '18px', borderBottom: '1px solid #E0E0E0' }}
              className="fw-bold theme_text_color Font_Family"
            >
              REQUEST
            </p>
            IF YOU ARE ENCOUNTERING AN ISSUE OR PROBLEM, PLEASE DESCRIBE YOUR
            EXPERIENCE IN DETAIL, THEN PRESS THE SUBMIT BUTTON TO SEND YOUR
            SUPPORT REQUEST
            <br></br>
            <br></br>
            <textarea
              type="text"
              placeholder="TYPE YOUR MESSAGE HERE..."
              value={message}
              style={{
                width: '100%',
                height: '100px',
                resize: 'none',
                padding: '5px',
                border: '1px solid #E0E0E0',
              }}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
        </Col>
        <Col md={2} lg={2} className="Support_text_col"></Col>
      </Row>

      <button
        className="btn Font_Family"
        style={{
          marginTop: '25px',
          height: '45px',
          borderRadius: '0px 15px 0px 15px',
          backgroundColor: '#e61d2a',
          color: 'white',
          width: '20%',
        }}
        disabled={!message}
        onClick={() => {
          handleSubmit();
        }}
      >
        <span className="d-flex justify-content-center align-content-center w-100">
          {loading ? <Loader /> : <span> SUBMIT</span>}
        </span>
      </button>
    </Row>
  );
}

export default Support;
