import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pdfData: {},
};

const generatePdfSlice = createSlice({
  name: 'generatePdf',
  initialState,
  reducers: {
    getPdfData: (state, action) => {
      state.pdfData = action.payload.data;
    },
  },
});

export const { getPdfData } = generatePdfSlice.actions;

export default generatePdfSlice.reducer;
