import "./singlejobdetail.css";

import { Checkbox } from "@mui/material";
import {
  Document,
  Font,
  Image,
  Page,
  // PDFViewer,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PoppinsBold from "../../../assets/fonts/Poppins-Bold.ttf";
import PoppinsSemiBold from "../../../assets/fonts/Poppins-SemiBold.ttf";
import logo from "../../../assets/images/dreamer.png";
import { GeneratePdfAction } from "../../../redux/actions/GeneratePdfAction";
import { SingleJobDetailAction } from "../../../redux/actions/R8tr/SingleJobDetailAction";
Font.register({
  family: "PoppinsSemi",
  fonts: [
    { src: PoppinsSemiBold },
    { src: PoppinsSemiBold, fontWeight: "bold" },
  ],
});
Font.register({
  family: "PoppinsBold",
  fonts: [{ src: PoppinsBold }, { src: PoppinsBold, fontWeight: "bold" }],
});
const styles = StyleSheet.create({
  reviewJobSectionHeadingText: {
    fontSize: "10px",
    fontWeight: 700,
    color: "black",
    fontFamily: "PoppinsSemi",
  },
  reviewJobBottomMarginView: {
    marginBottom: "25px",
  },
  reviewJobRightMarginView: {
    marginRight: "15px",
  },
  reviewJobRowIdPoNumberInnerBox: {
    width: "100%",
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    lineHeight: "1.5px",
  },
  reviewJobCheckIcons: {
    width: "10px",
    height: "10px",
    marginRight: "6px",
  },
  page: {
    backgroundColor: "white",
  },
  sectionsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  sectionHeading: {
    marginLeft: 20,
    marginRight: 20,

    fontSize: "14px",
    fontWeight: "black",
    borderBottom: "3px solid black",
    marginTop: "20px",
    fontFamily: "PoppinsSemi",
  },
  sectionHeadingBanner: {
    marginLeft: 20,
    marginRight: 20,
    color: "white",
    // padding: '10px 10px',
    height: "35px",
    fontSize: "16px",
    // fontWeight: 'bold',
    fontFamily: "PoppinsSemi",
    backgroundColor: "#E61D2A",
    marginBottom: "10px",
    minHeight: "40px",

    textTransform: "capitalize",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionHeadingText: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  logoImage: {
    height: "70px",
    width: "120px",
  },
  sections: {
    margin: 10,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "22px",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "10px",
    // width: '100%',
  },
  sectionOverallRating: {
    marginLeft: 20,
    marginRight: 20,
    border: "1px solid black",
    marginTop: "10px",
    width: "555px",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
  },
  sectionOverallRatingBox: {
    // border: '1px solid lightgray',
    padding: "20px 10px",
    width: "15%",
    fontSize: "24px",
  },
  sectionMessage: {
    marginLeft: "15px",
    fontSize: "12px",
    // textAlign: 'center',
    padding: "20px 0px",
    width: "400px",
    height: "100%",
  },
  ratingCriteriaOptionContainer: {
    marginLeft: 20,
    marginRight: 20,
    width: "555px",
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    // textAlign: 'center',
    // marginTop: '10px',
  },
  ratingCriteriaOptionBox: {
    border: "1px solid black",
    // padding: '10px 10px',
    textAlign: "center",
    width: "15%",
    height: "50px",
    fontSize: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  ratingCriteriaOptionHeading: {
    fontSize: "12px",
    fontWeight: "bold",
    fontFamily: "PoppinsSemi",
  },
  ratingCriteriaOptionHeadingBox: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: "12px",
    fontWeight: "bold",
    marginTop: "20px",
    fontFamily: "PoppinsSemi",
  },
  r8trCommentsContainer: {
    marginLeft: 20,
    marginRight: 20,
    border: "1px solid black",
    width: "555px",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    // textAlign: 'center',
    marginTop: "10px",
    padding: "10px 20px",
  },
  sectionContainerVideo: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: "15px 22px",
  },
  sectionTableVideo: {
    flex: "0 0 33.3333%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5px",
    marginRight: "5px",
  },
  sectionTable: {
    padding: "10px 20px",
    width: "25%",
    fontSize: "14px",
  },
  sectionTableJob: {
    padding: "10px 20px",
    width: "15%",
    fontSize: "14px",
  },
  sectionTableIn: {
    padding: "10px 10px",
    width: "35%",
    fontSize: "14px",
  },
  sectionTableRating: {
    border: "1px solid lightgray",
    padding: "10px 20px",
    width: "80%",
  },
  sectionTableHead: {
    fontSize: "10px",
    fontWeight: "bold",
    fontFamily: "PoppinsSemi",
  },
  text: {
    fontSize: "12px",
    marginTop: "5px",
    color: "#414042",
  },
  ratingCriteriaOptionValue: {
    fontSize: "12px",
    color: "#414042",
  },
  textBold: {
    fontWeight: "black",
    fontSize: "20px",
    fontFamily: "PoppinsBold",
  },
  images: {
    width: "100%",
    height: "150px",
    objectFit: "cover",
  },
  sectionLink: {
    color: "#E61D2A",
    textDecoration: "underline",
  },
  firstContainer: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  secondContainer: {
    marginTop: "50px",
  },
  topMarginView: {
    marginTop: "20px",
  },
  ratingCriteriaOptionsTypeHeading: {
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "10px",
    marginBottom: "10px",
  },
  ratingCriteriaOptionMianContainer: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  ratingCriteriaUpperBox: {
    // marginLeft: 15,
    border: "1px solid black",
    width: "100%",
    fontSize: "12px",
    padding: "10px 2px",
  },
  ratingCriteriaOptionImageTitleBox: {
    marginTop: 15,
    border: "1px solid black",
    width: "40%",
    fontSize: "12px",
    padding: "10px 2px",
  },
  sectionContainerImages: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    padding: "15px 0px",
  },
  sectionInnerContainerImage: {
    flex: "0 0 33.3333%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});
function SingleJobDetail({ jobId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPdf, setShowPdf] = useState(false);
  const { pdfData } = useSelector((state) => state.GeneratePdfReducer);
  const { jobData } = useSelector((state) => state.SingleJobDetailReducer);
  useEffect(() => {
    dispatch(SingleJobDetailAction(jobId));
    dispatch(GeneratePdfAction(jobId));
  }, [jobId]);
  useEffect(() => {
    // if (jobData?.status === 'Reviewed' && !jobData.company) {
    handleDataManage();
    // }
  }, [pdfData]);
  const handleDataManage = () => {
    setTimeout(() => {
      MyDoc();
    }, 3000);
  };
  const MyDoc = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.firstContainer}>
          <View style={styles.sectionsHeader}>
            <Image src={logo} style={styles.logoImage} />
            <Text style={styles.textBold}>R8TR Vehicle Condition Report</Text>
          </View>
          <View style={styles.sectionHeading}>
            <Text>JOB INFORMATION</Text>
          </View>
          <View style={styles.sectionContainer}>
            <View style={styles.sectionTableJob}>
              <Text style={styles.sectionTableHead}>JOB#</Text>
              <Text style={styles.text}>{pdfData.id}</Text>
            </View>
            <View style={styles.sectionTableIn}>
              <Text style={styles.sectionTableHead}>INSPECTION DATE</Text>
              <Text style={styles.text}>{pdfData.ratingStartedAt}</Text>
            </View>
            <View style={styles.sectionTable}>
              <Text style={styles.sectionTableHead}>PREPARED FOR</Text>
              <Text style={styles.text}>{pdfData?.buyer}</Text>
            </View>
            <View style={styles.sectionTable}>
              <Text style={styles.sectionTableHead}>R8TR</Text>
              <Text style={styles.text}>
                {pdfData?.r8tr ? pdfData?.r8tr : ""}
              </Text>
            </View>
          </View>
          <View style={styles.sectionHeading}>
            <Text>VEHICLE INFORMATION</Text>
          </View>
          <View style={styles.sectionContainer}>
            <View style={styles.sectionTableJob}>
              <Text style={styles.sectionTableHead}>YEAR</Text>
              <Text style={styles.text}>{pdfData.year}</Text>
            </View>
            <View style={styles.sectionTable}>
              <Text style={styles.sectionTableHead}>MAKE</Text>
              <Text style={styles.text}>{pdfData.make}</Text>
            </View>
            <View style={styles.sectionTable}>
              <Text style={styles.sectionTableHead}>MODEL</Text>
              <Text style={styles.text}>{pdfData.model}</Text>
            </View>
            <View style={styles.sectionTableIn}>
              <Text style={styles.sectionTableHead}>VIN/SERIAL NUMBER</Text>
              <Text style={styles.text}>{pdfData.vinOrSerial}</Text>
            </View>
          </View>
          {pdfData?.overAllComments && (
            <View>
              <View style={styles.sectionHeading}>
                <Text>JOB RESULT</Text>
              </View>
              <View style={styles.sectionHeadingBanner}>
                <Text>Message from the R8TR</Text>
              </View>
              <View style={styles.sectionOverallRating}>
                {/* <View style={styles.sectionOverallRatingBox}>
                                <Text>
                                    {pdfData?.overAllRating?.toFixed(1)}/8
                                </Text>
                            </View> */}
                <View style={styles.sectionMessage}>
                  <Text style={styles.ratingCriteriaOptionValue}>
                    {pdfData?.overAllComments
                      ? pdfData?.overAllComments
                      : " no comments available"}
                  </Text>
                </View>
              </View>
            </View>
          )}
        </View>
      </Page>
      <Page wrap>
        <View style={styles.topMarginView} fixed></View>
        <View>
          {pdfData?.ratingCriterias?.map((item) => (
            <View key={item.id}>
              <View style={styles.sectionHeadingBanner} wrap={false}>
                <Text>{item.title}</Text>
              </View>
              {item.options.length > 0 && (
                <View
                  style={styles.ratingCriteriaOptionContainer}
                  // wrap={false}
                >
                  {item.options.length > 0 &&
                    item.options.map((option) => (
                      <View
                        style={styles.ratingCriteriaOptionMianContainer}
                        wrap={false}
                        key={option.id}
                      >
                        {option.type === "Option" && (
                          <View>
                            <View
                              style={styles.ratingCriteriaOptionsTypeHeading}
                              // wrap={false}
                            >
                              <Text style={styles.ratingCriteriaOptionHeading}>
                                {option.ratingTitle}
                              </Text>
                            </View>
                            <View style={styles.ratingCriteriaOptionBox}>
                              <Text
                                style={{
                                  fontSize: !option?.parentValue
                                    ? "18px"
                                    : "18px",
                                  fontFamily: "PoppinsSemi",
                                  fontWeight: "bold",
                                }}
                              >
                                {option?.parentValue
                                  ? `${option.value}/${option.parentValue}`
                                  : option.value}
                              </Text>
                            </View>
                          </View>
                        )}
                        {option.type === "DropDown" && (
                          <View>
                            <View
                              style={styles.ratingCriteriaOptionsTypeHeading}
                              // wrap={false}
                            >
                              <Text style={styles.ratingCriteriaOptionHeading}>
                                {option.ratingTitle}
                              </Text>
                            </View>
                            <View style={styles.ratingCriteriaOptionBox}>
                              <Text
                                style={{
                                  fontSize: !option?.parentValue
                                    ? "17px"
                                    : "16px",
                                }}
                              >
                                {option.value}
                              </Text>
                            </View>
                          </View>
                        )}
                        {(option.type === "Number" ||
                          option.type === "Text" ||
                          option.type === "Date" ||
                          option.type === "Time") && (
                          <View style={styles.ratingCriteriaUpperBox}>
                            <View style={styles.reviewJobRowIdPoNumberInnerBox}>
                              <Text style={styles.ratingCriteriaOptionHeading}>
                                {option.ratingTitle}
                              </Text>
                              <Text style={styles.ratingCriteriaOptionValue}>
                                {option.value.value}
                              </Text>
                            </View>
                          </View>
                        )}
                        {option.type === "Location" && (
                          <View style={styles.ratingCriteriaUpperBox}>
                            <View style={styles.reviewJobRowIdPoNumberInnerBox}>
                              <Text style={styles.ratingCriteriaOptionHeading}>
                                {option.ratingTitle}
                              </Text>
                              <Text style={styles.ratingCriteriaOptionValue}>
                                {option?.value?.value?.address}
                              </Text>
                            </View>
                          </View>
                        )}
                        {option.type === "Checkbox" && (
                          <View style={styles.ratingCriteriaUpperBox}>
                            <View style={styles.reviewJobRowIdPoNumberInnerBox}>
                              <Text style={styles.reviewJobSectionHeadingText}>
                                {option.value.value ? (
                                  <View style={styles.reviewJobRightMarginView}>
                                     <Checkbox defaultChecked disabled />
                                
                                  </View>
                                ) : (
                                  <View style={styles.reviewJobRightMarginView}>
                                    <Checkbox defaultChecked={false} disabled />
                                  </View>
                                )}

                                <Text
                                  style={styles.ratingCriteriaOptionHeading}
                                >
                                  {" "}
                                  {option.ratingTitle}
                                </Text>
                              </Text>
                            </View>
                          </View>
                        )}
                        {option.type === "Image" && (
                          <>
                            <View>
                              <View
                                style={styles.ratingCriteriaOptionImageTitleBox}
                              >
                                <Text
                                  style={styles.ratingCriteriaOptionHeading}
                                >
                                  {option.ratingTitle}
                                </Text>
                              </View>
                            </View>
                            <View style={styles.sectionContainerImages}>
                              {option.value.value.length > 0 &&
                                option.value.value.map((img) => {
                                  return (
                                    <View
                                      style={styles.sectionInnerContainerImage}
                                      key={img.id}
                                    >
                                      <View
                                        style={{
                                          border: "2px solid black",
                                        }}
                                        wrap={false}
                                      >
                                        <Image
                                          alt="img"
                                          // src={
                                          //     img?.url
                                          // }
                                          src={{
                                            uri: img?.url,
                                            method: "GET",
                                            headers: {
                                              "Access-Control-Allow-Origin":
                                                "*",
                                              "Cache-Control": "no-cache",
                                            },
                                            body: "",
                                          }}
                                          style={{
                                            width: "180px",
                                            height: "200px",
                                            border: "5px solid black",
                                            objectFit: "cover",
                                            boxShadow: "10px 10px 9px #F4AAB9",
                                          }}
                                        />
                                      </View>
                                    </View>
                                  );
                                })}
                            </View>
                          </>
                        )}
                      </View>
                    ))}
                </View>
              )}

              <View style={styles.ratingCriteriaOptionHeadingBox} wrap={false}>
                <Text style={styles.ratingCriteriaOptionHeading}>Damages</Text>
              </View>
              <View style={styles.r8trCommentsContainer} wrap={false}>
                {item.damages.length > 0 ? (
                  item.damages.map((item) => (
                    <Text
                      key={item.id}
                      style={styles.ratingCriteriaOptionValue}
                    >
                      {item.title}{" "}
                    </Text>
                  ))
                ) : (
                  <>
                    <Text
                      key={item.id}
                      style={styles.ratingCriteriaOptionValue}
                    >
                      No Damages
                    </Text>
                  </>
                )}
              </View>
              <View style={styles.ratingCriteriaOptionHeadingBox} wrap={false}>
                <Text style={styles.ratingCriteriaOptionHeading}>
                  R8TR COMMENTS
                </Text>
              </View>
              <View style={styles.r8trCommentsContainer} wrap={false}>
                <Text style={styles.ratingCriteriaOptionValue}>
                  {item.criteriaInfo
                    ? item.criteriaInfo
                    : " no comments available"}
                </Text>
              </View>
              {item?.images?.length > 0 && (
                <>
                  <View style={styles.ratingCriteriaOptionHeadingBox}>
                    <Text style={styles.ratingCriteriaOptionHeading}>
                      R8TR PHOTOS
                    </Text>
                  </View>
                  <View style={styles.sectionContainerVideo}>
                    {item?.images.map(
                      (image) =>
                        image?.url && (
                          <View style={styles.sectionTableVideo} key={image.id}>
                            <View
                              style={{
                                border: "2px solid black",
                              }}
                              wrap={false}
                            >
                              <Image
                                // src={image?.url}
                                src={{
                                  uri: image?.url,
                                  method: "GET",
                                  headers: {
                                    "Access-Control-Allow-Origin": "*",
                                    "Cache-Control": "no-cache",
                                  },
                                  body: "",
                                }}
                                style={{
                                  width: "180px",
                                  height: "200px",
                                  border: "10px solid black",
                                  objectFit: "cover",
                                  boxShadow: "10px 10px 9px black",
                                }}
                              />
                            </View>
                          </View>
                        )
                    )}
                  </View>
                </>
              )}
              <View style={styles.topMarginView} fixed></View>
            </View>
          ))}
        </View>
        {/* <View style={styles.reviewJobBottomMarginView} fixed></View> */}
      </Page>
      {setShowPdf(true)}
    </Document>
  );
  return (
    <div className="siglejob_Container mb-4">
      <Container className="siglejob_Container mt-4">
        <Row className="siglejob_tbody">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="siglejob_first_title_container mt-2 mb-4"
          >
            <span
              className="Font_Family theme_text_color h4 title_span w-100 text-uppercase"
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              {jobData?.make}
            </span>
            {showPdf && (
              <span className="Font_Family theme-color w-100 d-flex justify-content-end text-uppercase">
                <PDFDownloadLink
                  document={<MyDoc />}
                  fileName={jobData.description}
                  style={styles.sectionLink}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Generate Pdf"
                  }
                </PDFDownloadLink>
              </span>
            )}
          </Col>

          <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12} className="mt-2">
            <div className="siglejob_banner_container ">
              <div className="siglejob_banner ">
                <span
                  className="Font_Family siglejob_banner_span text-uppercase"
                  style={{ width: "200px" }}
                >
                  {jobData.description}
                  <br />
                  <span
                    style={{
                      fontWeight: "normal",
                      fontSize: "16px",
                    }}
                  >
                    {jobData.category?.name}- {jobData.subCategory?.name}
                  </span>
                </span>
                <div className="vertical"></div>

                <span
                  className="Font_Family siglejob_banner_span "
                  style={{ width: "300px", paddingLeft: "20px" }}
                >
                  <span
                    className="theme-color"
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    $
                    {jobData?.discountedBuyerCost
                      ? jobData?.discountedBuyerCost
                      : jobData.buyerCost}
                  </span>
                  <br />
                  <span style={{ fontWeight: "normal", fontSize: "15px" }}>
                    JOB VALUE
                  </span>
                </span>
              </div>
            </div>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="siglejob_tbody mt-4"
          >
            <span
              className="Font_Family h4 title_span theme_text_color text-uppercase w-100"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                paddingBottom: "10px",
                borderBottom: "1px solid #a7a9ac",
              }}
            >
              Info
              {/* <hr /> */}
            </span>
          </Col>

          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="siglejob_tbody"
          >
            <table className="siglejob_tbody">
              <tbody className="siglejob_tbody">
                <tr className="siglejob_tr1">
                  <th>ASSIGNED TO</th>
                  <td className="silver_Color">
                    {jobData?.userDetails?.firstName}{" "}
                    {jobData?.userDetails?.lastName}
                  </td>
                </tr>
                {jobData.ratedByBuyer != true && (
                  <tr className="siglejob_tr1">
                    <th>RATING</th>
                    <td
                      className="silver_Color"
                      onClick={() => {
                        navigate("/buyer-rating-r8tr", {
                          state: { jobData: jobData },
                        });
                      }}
                    >
                      <span
                        className="text-decoration-underline"
                        style={{ color: "#d40000", cursor: "pointer" }}
                      >
                        RATE R8TR
                      </span>
                    </td>
                  </tr>
                )}

                <tr className="siglejob_tr1">
                  <th>YEAR</th>
                  <td className="silver_Color">{jobData?.model}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>MAKE</th>
                  <td className="silver_Color ">{jobData?.make}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>MODEL</th>
                  <td className="silver_Color">{jobData?.model}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>CURRENT ASKING PRICE</th>
                  <td className="silver_Color">{jobData?.askingPrice}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>VIN/SERIAL NUMBER</th>
                  <td className="silver_Color">{jobData?.vinOrSerial}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>LINK TO LIST</th>
                  <td className="theme-color">
                    {/* <Link to={jobData?.link}>bgfhg</Link> */}
                    <a
                      href={`${
                        jobData?.link?.includes("https://")
                          ? jobData?.link
                          : "https://" + jobData?.link
                      }`}
                      style={{ textDecoration: "none", color: "#d40000" }}
                      target="blank"
                    >
                      VIEW LISTING
                    </a>
                  </td>
                </tr>
                {/* {jobData?.status == "Accepted" &&
                  (jobData?.onMyWay == null || jobData?.onMyWay == false) && (
                    <tr className="siglejob_tr1">
                      <th>ON MY WAY</th>
                      <td>
                        <Switch
                          checked={checked}
                          color="success"
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </td>
                    </tr>
                  )} */}
                <tr className="siglejob_tr1">
                  <th>DIRECTION</th>
                  <td className="theme-color direction_td">
                    <a
                      target="blank"
                      href={`https://maps.google.com?q=${jobData?.latitude},${jobData?.longitude}`}
                      style={{ textDecoration: "none", color: "#d40000" }}
                    >
                      VIEW DIRECTION
                    </a>
                  </td>
                </tr>
                <span
                  className="Font_Family h4 mt-5 title_span theme_text_color"
                  style={{ fontSize: "18px", fontWeight: "bold" }}
                >
                  LOCATION AND POINT OF CONTACT
                  <hr />
                </span>
                <tr className="siglejob_tr1">
                  <th>ADDRESS</th>
                  <td className="silver_Color">{jobData?.address}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>CONTACT NUMBER</th>
                  <td className="silver_Color">{jobData?.creatorNumber}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>EMAIL</th>
                  <td className="silver_Color">{jobData?.contactEmail}</td>
                </tr>
                <tr className="siglejob_tr1">
                  <th>POINT OF CONTACT</th>
                  <td className="silver_Color">{jobData?.contactName}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SingleJobDetail;
