import apiProtected, { api } from 'api/apiClient';
import { setLoginData } from 'redux/reducers/LogInReducer';

import { ApiUrl } from '../../ApiUrl';

export const LogInAction = (data, handleLoginFormData) => (dispatch) => {
  const deviceToken = localStorage.getItem('deviceToken');
  api
    .post(`${ApiUrl.server_url}v1/auth/login`, {
      phoneNumber: data.phoneNumber,
      password: data.password,
      deviceToken: deviceToken,
      deviceType: 'web',
    })
    .then((res) => {
      handleLoginFormData(res.data);
      dispatch(setLoginData(res.data));
    })
    .catch((error) => {
      handleLoginFormData(error.response.data);
    });
};

export const SwitchToBuyerOrR8trAction =
  (handleSwitchToBuyerOrR8tr) => (dispatch) => {
    apiProtected
      .patch(`${ApiUrl.server_url}v1/users/switch-to-buyer-or-r8tr`, {})
      .then((res) => {
        handleSwitchToBuyerOrR8tr(res.data);
        dispatch(setLoginData(res.data));
      })
      .catch((error) => {
        handleSwitchToBuyerOrR8tr(error.response.data);
      });
  };
