import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboardData: {},
};

const userDashboardStatSlice = createSlice({
  name: 'userDashboardStat',
  initialState,
  reducers: {
    setUserDashboardStat: (state, action) => {
      state.dashboardData = action.payload;
    },
  },
});

export const { setUserDashboardStat } = userDashboardStatSlice.actions;

export default userDashboardStatSlice.reducer;
