import React from "react";

import currentJob from "../../../../assets/icons/side-nav-icon/briefcase.webp";
import creditCard from "../../../../assets/icons/side-nav-icon/credit-card.webp";
import creditCardFilled from "../../../../assets/icons/side-nav-icon/credit-card-filled.webp";
import serviceArea from "../../../../assets/icons/side-nav-icon/fast.webp";
import serviceAreaFilled from "../../../../assets/icons/side-nav-icon/fast-delivery.webp";
import dashboard from "../../../../assets/icons/side-nav-icon/home.webp";
import dashboardFilled from "../../../../assets/icons/side-nav-icon/homepage.webp";
import profileFilled from "../../../../assets/icons/side-nav-icon/profile.webp";
import hireR8tr from "../../../../assets/icons/side-nav-icon/r8tr.webp";
import hireR8trFilled from "../../../../assets/icons/side-nav-icon/r8trFilled.webp";
import areaExpertise from "../../../../assets/icons/side-nav-icon/star.webp";
import areaExpertiseFilled from "../../../../assets/icons/side-nav-icon/starFilled.webp";
import currentJobFilled from "../../../../assets/icons/side-nav-icon/suitcase.webp";
import profile from "../../../../assets/icons/side-nav-icon/user.webp";
// import addUser from "../../../../assets/icons/web-app-R8tr/Area of expertise/add-user.png";
// import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
export const buyerItem = [
  {
    path: "/",
    name: "MY JOBS",
    iconFilled: <img src={dashboardFilled} alt="dashboard" />,
    icon: <img src={dashboard} alt="dashboard" />,
  },
  {
    path: "/hire-a-r8tr",
    name: "HIRE A R8TR",
    iconFilled: <img src={hireR8trFilled} alt="hireR8tr" />,
    icon: <img src={hireR8tr} alt="hireR8tr" />,
  },
  {
    path: "/personal-info",
    name: "PROFILE",
    icon: <img src={profile} alt="profile" />,
  },
  // {
  //   path: "/invite",
  //   name: "INVITE",
  //   iconFilled: <GroupAddIcon height="20px" width="20px" />,
  //   icon: <GroupAddOutlinedIcon height="20px" width="20px" />,
  // },
];
export const r8trItem = [
  {
    path: "/",
    name: "DASHBOARD",
    iconFilled: <img src={dashboardFilled} alt="dashboard" />,
    icon: <img src={dashboard} alt="dashboard" />,
  },
  {
    path: "/current-jobs",
    name: "CURRENT JOBS",
    iconFilled: <img src={currentJobFilled} alt="currentJob" />,
    icon: <img src={currentJob} alt="currentJob" />,
  },
  {
    path: "/personal-info",
    name: "PROFILE",
    icon: <img src={profile} alt="hireR8tr" />,
  },
];

export const buyerProfileData = [
  {
    path: "/personal-info",
    name: "PERSONAL INFORMATION",
    iconFilled: <img src={profileFilled} alt="dashboard" />,
    icon: <img src={profile} alt="dashboard" />,
  },
  {
    path: "/payment-methods",
    name: "PAYMENT METHODS",
    iconFilled: <img src={creditCardFilled} alt="hireR8tr" />,
    icon: <img src={creditCard} alt="hireR8tr" />,
  },
];
export const r8trProfileData = [
  {
    path: "/personal-info",
    name: "PERSONAL INFORMATION",
    iconFilled: <img src={profileFilled} alt="dashboard" />,
    icon: <img src={profile} alt="dashboard" />,
  },
  {
    path: "/service-area",
    name: "SERVICE AREAS",
    iconFilled: <img src={serviceAreaFilled} alt="profile" />,
    icon: <img src={serviceArea} alt="profile" />,
  },
  {
    path: "/area-expertise",
    name: "AREAS OF EXPERTISE ",
    iconFilled: <img src={areaExpertiseFilled} alt="hireR8tr" />,
    icon: <img src={areaExpertise} alt="hireR8tr" />,
  },
  {
    path: "/level-expertise",
    name: "RATE YOUR LEVEL OF EXPERTISE",
    iconFilled: <img src={areaExpertiseFilled} alt="hireR8tr" />,
    icon: <img src={areaExpertise} alt="hireR8tr" />,
  },
];
