import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  R8tingList: [],
};

const r8tingListSlice = createSlice({
  name: 'r8tingList',
  initialState,
  reducers: {
    r8tingList: (state, action) => {
      state.R8tingList = [...action.payload.data];
    },
  },
});

export const { r8tingList } = r8tingListSlice.actions;

export default r8tingListSlice.reducer;
