import "./criteriaoption.css";
import "react-medium-image-zoom/dist/styles.css";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
function CriteriaOption({
  ratingCriteriaOptions,
  damages,
  ratingCriteria,
  includeCommonImages,
  includeCriteriaNotes,
}) {
  const [r8tingCriteriaDamageId, setR8tingCriteriaDamageId] = useState([]);
  const [massage, setMassage] = useState("");
  const [images, setImages] = useState([]);
  const [typeOption, setTypeOption] = useState([]);
  const [typeDropdown, setTypeDropdown] = useState([]);
  const [typeText, setTypeText] = useState([]);
  const [typeNumber, setTypeNumber] = useState([]);
  const [typeCheckbox, setTypeCheckbox] = useState([]);
  const [typeLocation, setTypeLocation] = useState([]);
  const [typeDate, setTypeDate] = useState([]);
  const [typeTime, setTypeTime] = useState([]);
  const [typeImages, setTypeImages] = useState([]);
  const [selectedOptionsArr, setSelectedOptionsArr] = useState([]);
  const [selectedDropdownsArr, setSelectedDropdownArr] = useState([]);
  useEffect(() => {
    let typeOptionArray = [];
    let typeTextArray = [];
    let typeNumberArray = [];
    let typeDropdownArray = [];
    let typeTimeArray = [];
    let typeCheckboxArray = [];
    let typeLocationArray = [];
    let typeDateArray = [];
    let tempImageArray = [];
    let allOptionsArr = [];
    let allDropDownArr = [];
    if (ratingCriteria) {
      for (let i = 0; i < ratingCriteriaOptions.length; i++) {
        if (ratingCriteriaOptions[i].type === "Option") {
          typeOptionArray.push(ratingCriteriaOptions[i]);
        }
        if (ratingCriteriaOptions[i].type === "DropDown") {
          typeDropdownArray.push(ratingCriteriaOptions[i]);
        }
        if (ratingCriteriaOptions[i].type === "Text") {
          const data = {
            ratingTitle: ratingCriteriaOptions[i].ratingTitle,
            id: ratingCriteriaOptions[i].id,
            ratingCriteriaUserId:
              ratingCriteriaOptions[i].ratingCriteriaUser?.id,
            value: {
              value: ratingCriteriaOptions[i].ratingCriteriaUser?.value?.value,
            },
          };
          typeTextArray.push(data);
        }
        if (ratingCriteriaOptions[i].type === "Number") {
          const data = {
            ratingTitle: ratingCriteriaOptions[i].ratingTitle,
            id: ratingCriteriaOptions[i].id,
            ratingCriteriaUserId:
              ratingCriteriaOptions[i].ratingCriteriaUser?.id,
            value: {
              value: ratingCriteriaOptions[i].ratingCriteriaUser?.value?.value,
            },
          };
          typeNumberArray.push(data);
        }
        if (ratingCriteriaOptions[i].type === "Checkbox") {
          const data = {
            ratingTitle: ratingCriteriaOptions[i].ratingTitle,
            id: ratingCriteriaOptions[i].id,
            ratingCriteriaUserId:
              ratingCriteriaOptions[i].ratingCriteriaUser?.id,
            value: {
              value: ratingCriteriaOptions[i].ratingCriteriaUser?.value?.value,
            },
          };
          typeCheckboxArray.push(data);
        }
        if (ratingCriteriaOptions[i].type === "Date") {
          const data = {
            ratingTitle: ratingCriteriaOptions[i].ratingTitle,
            id: ratingCriteriaOptions[i].id,
            ratingCriteriaUserId:
              ratingCriteriaOptions[i].ratingCriteriaUser?.id,
            value: {
              value: ratingCriteriaOptions[i].ratingCriteriaUser?.value?.value,
            },
          };
          typeDateArray.push(data);
        }
        if (ratingCriteriaOptions[i].type === "Time") {
          const data = {
            ratingTitle: ratingCriteriaOptions[i].ratingTitle,
            id: ratingCriteriaOptions[i].id,
            ratingCriteriaUserId:
              ratingCriteriaOptions[i].ratingCriteriaUser?.id,
            value: {
              value: ratingCriteriaOptions[i].ratingCriteriaUser?.value?.value,
            },
          };
          typeTimeArray.push(data);
        }
        if (ratingCriteriaOptions[i].type === "Location") {
          const data = {
            ratingTitle: ratingCriteriaOptions[i].ratingTitle,
            id: ratingCriteriaOptions[i].id,
            ratingCriteriaUserId:
              ratingCriteriaOptions[i].ratingCriteriaUser?.id,
            value: {
              value: ratingCriteriaOptions[i].ratingCriteriaUser?.value?.value,
            },
          };
          typeLocationArray.push(data);
        }
        if (ratingCriteriaOptions[i].type === "Image") {
          // typeImageArray.push(ratingCriteriaOptions[i])
          const data = {
            ratingTitle: ratingCriteriaOptions[i].ratingTitle,
            id: ratingCriteriaOptions[i].id,
            ratingCriteriaUserId:
              ratingCriteriaOptions[i].ratingCriteriaUser?.id,
            value: {
              value: ratingCriteriaOptions[i].ratingCriteriaUser?.value?.value,
            },
          };
          tempImageArray.push(data);
        }
      }
      if (typeOptionArray?.length > 0) {
        let parentOptions = typeOptionArray.filter(
          (el) => el.headingId == null
        );
        let selectedOptionsArrTemp = [...selectedOptionsArr];
        for (let i = 0; i < parentOptions.length; i++) {
          let ratingCriteriaUserId = null;

          if (parentOptions[i].ratingCriteriaUser) {
            ratingCriteriaUserId = parentOptions[i].ratingCriteriaUser.id;

            selectedOptionsArrTemp.push({
              id: parentOptions[i].id,
              ratingCriteriaUserId: parentOptions[i].ratingCriteriaUser.id,
              value: { value: true },
            });
          }
          let childOptions = typeOptionArray.filter(
            (el) => el.headingId === parentOptions[i].id
          );
          if (!ratingCriteriaUserId) {
            let childOptionHavingRatingCriteriaUser = childOptions.find(
              (el) => el.ratingCriteriaUser?.value
            );
            if (childOptionHavingRatingCriteriaUser) {
              ratingCriteriaUserId =
                childOptionHavingRatingCriteriaUser?.ratingCriteriaUser?.id;
              selectedOptionsArrTemp.push({
                id: childOptionHavingRatingCriteriaUser.id,
                ratingCriteriaUserId:
                  childOptionHavingRatingCriteriaUser?.ratingCriteriaUser?.id,
                value: { value: true },
              });
            }
          }
          childOptions.unshift(parentOptions[i]);
          allOptionsArr.push({
            heading: parentOptions[i].heading,
            ratingCriteriaUserId: ratingCriteriaUserId,
            options: childOptions,
          });
        }
        setSelectedOptionsArr(selectedOptionsArrTemp);
      }

      if (typeDropdownArray?.length > 0) {
        let parentDropdown = typeDropdownArray.filter(
          (el) => el.headingId == null
        );
        let selectedDropdownArrTemp = [...selectedDropdownsArr];
        for (let i = 0; i < parentDropdown.length; i++) {
          let ratingCriteriaUserId = null;
          if (parentDropdown[i].ratingCriteriaUser) {
            ratingCriteriaUserId = parentDropdown[i].ratingCriteriaUser.id;

            selectedDropdownArrTemp.push({
              id: parentDropdown[i].id,
              ratingCriteriaUserId: parentDropdown[i].ratingCriteriaUser.id,
              ratingValue: parentDropdown[i].ratingValue,
              value: { value: true },
            });
          }
          let childDropdown = typeDropdownArray.filter(
            (el) => el.headingId === parentDropdown[i].id
          );
          if (!ratingCriteriaUserId) {
            let childOptionHavingRatingCriteriaUser = childDropdown.find(
              (el) => el.ratingCriteriaUser?.value
            );
            if (childOptionHavingRatingCriteriaUser) {
              ratingCriteriaUserId =
                childOptionHavingRatingCriteriaUser?.ratingCriteriaUser?.id;
              selectedDropdownArrTemp.push({
                id: childOptionHavingRatingCriteriaUser.id,
                ratingCriteriaUserId:
                  childOptionHavingRatingCriteriaUser?.ratingCriteriaUser?.id,
                ratingValue: childOptionHavingRatingCriteriaUser.ratingValue,
                value: { value: true },
              });
            }
          }
          childDropdown.unshift(parentDropdown[i]);
          allDropDownArr.push({
            heading: parentDropdown[i].heading,
            ratingCriteriaUserId: ratingCriteriaUserId,
            options: childDropdown,
          });
        }
        setSelectedDropdownArr(selectedDropdownArrTemp);
      }

      setTypeOption(allOptionsArr);
      setTypeDropdown(allDropDownArr);
      setTypeText(typeTextArray);
      setTypeNumber(typeNumberArray);
      setTypeCheckbox(typeCheckboxArray);
      setTypeDate(typeDateArray);
      setTypeTime(typeTimeArray);
      setTypeLocation(typeLocationArray);
      setTypeImages(tempImageArray);
      setImages(ratingCriteria.images);
      setMassage(ratingCriteria.criteriaInfo);
      setR8tingCriteriaDamageId(ratingCriteria.damageIds);
    }
  }, [ratingCriteria]);

  return (
    <div>
      {r8tingCriteriaDamageId?.length > 0 && (
        <div id="pills" className="criteria_option_section_div">
          <p className="Font_Family theme_text_color fw-bold text_field_div_title">
            DAMAGE
          </p>

          <ul className="nav nav-pills">
            {damages?.length > 0 &&
              damages
                .filter((item) => r8tingCriteriaDamageId.includes(item.id))
                .map((item) => (
                  <div key={item.id}>
                    <li
                      className={` rounded-pill CriteriaOption_titel_tabs fw-bold ${
                        r8tingCriteriaDamageId?.includes(item.id)
                          ? "CriteriaOption_active"
                          : ""
                      }`}
                      key={item.id}
                    >
                      <a data-toggle="pill">{item.title}</a>
                    </li>
                  </div>
                ))}
          </ul>
        </div>
      )}
      {ratingCriteria.hasOptions && (
        <>
          {typeOption.map((item, index) => (
            <>
              <div
                className={`pills_rating_value criteria_option_section_div`}
                key={item.id}
                style={{
                  backgroundColor: index % 2 == 0 ? "#f9fafa" : "",
                }}
              >
                <p className="Font_Family theme_text_color fw-bold text_field_div_title">
                  {item.heading}
                </p>
                <ul className="nav nav-pills">
                  {item.options?.length > 0 &&
                    item.options?.map(
                      (option) =>
                        selectedOptionsArr.find(
                          (el) => el.id === option?.id
                        ) && (
                          <li
                            className={` pill CriteriaOption_rating_value_tabs fw-bold ${
                              selectedOptionsArr.find(
                                (el) => el.id === option?.id
                              )
                                ? "CriteriaOption_ratingValue_active"
                                : ""
                            }`}
                            key={option.id}
                          >
                            <a data-toggle="pill">{option.ratingValue}</a>
                          </li>
                        )
                    )}
                </ul>
              </div>
            </>
          ))}
        </>
      )}
      <Grid container spacing={2} style={{ marginTop: "-10px" }}>
        {selectedDropdownsArr?.length > 0 &&
          typeDropdown?.length > 0 &&
          typeDropdown?.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
              <div className="text_field_div">
                <p className="Font_Family theme_text_color fw-bold text_field_div_title">
                  {item.heading}
                </p>
                {item.options?.map((option) => {
                  if (option.ratingCriteriaUser !== null) {
                    return (
                      <p className="text_field_div_text" key={option.id}>
                        {option.ratingValue}
                      </p>
                    );
                  }
                })}
              </div>
            </Grid>
          ))}
        {typeText.length > 0 &&
          typeText.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
              <div className="text_field_div">
                <p className="Font_Family theme_text_color fw-bold text_field_div_title">
                  {item.ratingTitle}
                </p>
                <p className="text_field_div_text">{item?.value?.value}</p>
              </div>
            </Grid>
          ))}

        {typeNumber.length > 0 &&
          typeNumber.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
              <div className="text_field_div">
                <p className="Font_Family theme_text_color fw-bold text_field_div_title">
                  {item.ratingTitle}
                </p>
                <p className="text_field_div_text">{item?.value?.value}</p>
              </div>
            </Grid>
          ))}

        {typeDate.length > 0 &&
          typeDate.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
              <div className="text_field_div">
                <p className="Font_Family theme_text_color fw-bold text_field_div_title">
                  {item.ratingTitle}
                </p>
                <p className="text_field_div_text">{item?.value?.value}</p>
              </div>
            </Grid>
          ))}
        {typeTime.length > 0 &&
          typeTime.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
              <div className="text_field_div">
                <p className="Font_Family theme_text_color fw-bold text_field_div_title">
                  {item.ratingTitle}
                </p>
                <p className="text_field_div_text">{item?.value?.value}</p>
              </div>
            </Grid>
          ))}
        {typeLocation.length > 0 &&
          typeLocation.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
              <div className="text_field_div">
                <p className="Font_Family theme_text_color fw-bold text_field_div_title">
                  {item.ratingTitle}
                </p>
                <p className="text_field_div_text">
                  {item?.value?.value?.address}
                </p>
              </div>
            </Grid>
          ))}
      </Grid>
      <div style={{ marginTop: "10px" }}>
        {typeCheckbox.length > 0 &&
          typeCheckbox.map((item, index) => (
            <div key={index}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox defaultChecked={item?.value?.value} disabled />
                  }
                  label={item.ratingTitle}
                />
              </FormGroup>
            </div>
          ))}
      </div>
      {/* {} */}
      {typeImages.length > 0 &&
        typeImages.map((item, index) => (
          <div
            style={{
              marginBottom: typeImages.length > 0 ? "60px" : "10px",
              marginTop: typeImages.length > 0 ? "30px" : "10px",
            }}
            key={index}
          >
            <>
              <p
                className="Font_Family theme_text_color fw-bold"
                style={{ fontSize: "14px" }}
              >
                {item.ratingTitle}
              </p>
              <Grid container spacing={1} style={{ width: "100%" }}>
                {item?.value?.value?.length > 0 ? (
                  item?.value?.value?.map((item) => (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      lg={3}
                      key={item.id}
                      className=" d-flex justify-content-center theme_text_color Font_Family"
                      style={{
                        marginBottom: "60px",
                      }}
                    >
                      <Zoom>
                        <div className="criteriaOption_image_upload_image_preview_div">
                          <img
                            src={item.url}
                            style={{
                              width: "100%",
                              height: "150px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </Zoom>
                    </Grid>
                  ))
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className=" d-flex justify-content-center theme_text_color Font_Family"
                  >
                    <div className=" d-flex justify-content-center theme_text_color Font_Family">
                      <p style={{ fontSize: "13px" }}>NO PHOTO AVAILABLE</p>
                    </div>
                  </Grid>
                )}
              </Grid>
            </>
          </div>
        ))}
      {includeCommonImages && (
        <div style={{ marginTop: "20px" }}>
          <p
            className="Font_Family theme_text_color fw-bold"
            style={{ fontSize: "14px" }}
          >
            PHOTOS
          </p>
          <Grid container spacing={1} style={{ width: "100%" }}>
            {images?.length > 0 ? (
              images.map((item) => (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={3}
                  key={item.id}
                  style={{
                    marginBottom: "60px",
                  }}
                >
                  <Zoom>
                    <div className="criteriaOption_image_upload_image_preview_buyer">
                      <img
                        src={item["data_url"]}
                        style={{
                          width: "100%",
                          height: "120px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </Zoom>
                </Grid>
              ))
            ) : (
              <div className="w-100 d-flex justify-content-center theme_text_color Font_Family">
                <p style={{ fontSize: "13px" }}>NO PHOTO AVAILABLE</p>
              </div>
            )}
          </Grid>
        </div>
      )}

      {includeCriteriaNotes && (
        <div className="criteriaOption_massage_div">
          <p className="Font_Family theme_text_color fw-bold">MESSAGE</p>
          <textarea
            required
            value={massage}
            style={{ border: "1px solid #ebebeb" }}
            placeholder="R8TING NOTES..."
            readOnly
            // onChange={(e) => setMassage(e.target.value)}
          ></textarea>
        </div>
      )}
    </div>
  );
}

export default CriteriaOption;
