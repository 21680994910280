import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  R8tingCriteriaRatings: [],
};

const r8tingCriteriaRatingsSlice = createSlice({
  name: 'r8tingCriteriaRatings',
  initialState,
  reducers: {
    setR8tingCriteriaRatings: (state, action) => {
      state.R8tingCriteriaRatings = [...action.payload];
    },
  },
});

export const { setR8tingCriteriaRatings } = r8tingCriteriaRatingsSlice.actions;

export default r8tingCriteriaRatingsSlice.reducer;
