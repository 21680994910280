import './CurrentJobDetailBuyer.css';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import apiProtected from 'api/apiClient';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../../ApiUrl';
import { getAllBuyerCurrentJob } from '../../../redux/actions/buyer/BuyerJobAction';
import * as SweetAlert from '../../SweetAlert';

function CurrentJobDetailBuyer() {
  const toastOptions = {
    position: 'top-right',
    autoClose: 4000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jobData = location.state.currentJobData;
  const handleCancelJobsConfirm = (reason) => {
    apiProtected
      .patch(
        `${ApiUrl.server_url}v1/jobs/cancel-job-by-buyer`,
        { deletionOrCancellationReason: reason },
        { params: { jobId: jobData?.id } }
      )
      .then((res) => {
        if (res) {
          dispatch(getAllBuyerCurrentJob());
          toast.success(res.data.message, toastOptions);
          setTimeout(() => {
            navigate('/');
          }, 2000);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, toastOptions);
      });
  };
  const handleJobsCancelled = () => {
    SweetAlert.CancelJobsAlert(handleCancelJobsConfirm);
  };

  const canCancelJob =
    jobData.status === 'Posted' ||
    (jobData.status === 'Accepted' &&
      !jobData.ratingStartedAt &&
      !jobData.onMyWay);

  return (
    <div className="current_job_detail_buyer_main_div">
      <Container>
        <ToastContainer />
        <Row className="current_job_detail_buyer_row">
          <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
            {/* Heading div */}
            <div
              style={{ fontSize: '20px' }}
              className="current_job_detail_buyer_heading_div Font_Family text-uppercase fw-bold theme_text_color pb-2 ps-2"
            >
              <span>{jobData.make}</span>
              {canCancelJob && (
                <span
                  onClick={handleJobsCancelled}
                  style={{
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    fontSize: '16px',
                  }}
                  className="Font_Family theme-color text-uppercase pt-2"
                >
                  Cancel Job
                </span>
              )}
            </div>

            {/* Instruction price div */}
            <div className="current_job_detail_buyer_price_div">
              <div className="current_job_detail_buyer_price_instruction_div">
                <span
                  className="text-uppercase fw-bold"
                  style={{ fontSize: '22px' }}
                >
                  {jobData.description}
                </span>
                <span className="text-uppercase" style={{ fontSize: '18px' }}>
                  {jobData.category.name} - {jobData.subCategory.name}
                </span>
              </div>
              <div className="current_job_detail_buyer_price_cost_div">
                <span
                  className="text-uppercase fw-bold"
                  style={{ fontSize: '25px', color: '#e61d2a' }}
                >
                  ${jobData.buyerCost}
                </span>
                <span className="text-uppercase" style={{ fontSize: '18px' }}>
                  JOB VALUE
                </span>
              </div>
            </div>

            {/* Table view data */}
            <TableContainer>
              <Table style={{ width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="text-uppercase fw-bold"
                      style={{
                        color: '#414042',
                        fontSize: '20px',
                        width: '50%',
                      }}
                    >
                      INFO
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="fw-bold text-uppercase theme_text_color"
                      style={{ fontSize: '15px' }}
                    >
                      YEAR
                    </TableCell>
                    <TableCell
                      className="fw-bold"
                      style={{ color: '#a7a9ac', width: '50%' }}
                    >
                      {jobData.year}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="fw-bold text-uppercase theme_text_color"
                      style={{ fontSize: '15px' }}
                    >
                      MAKE
                    </TableCell>
                    <TableCell
                      className="fw-bold"
                      style={{ color: '#a7a9ac', width: '50%' }}
                    >
                      {jobData.make}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="fw-bold text-uppercase theme_text_color"
                      style={{ fontSize: '15px' }}
                    >
                      MODEL
                    </TableCell>
                    <TableCell
                      className="fw-bold"
                      style={{ color: '#a7a9ac', width: '50%' }}
                    >
                      {jobData.model}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="fw-bold text-uppercase theme_text_color"
                      style={{ fontSize: '15px' }}
                    >
                      CURRENT ASKING PRICE
                    </TableCell>
                    <TableCell
                      className="fw-bold"
                      style={{ color: '#a7a9ac', width: '50%' }}
                    >
                      {jobData.askingPrice}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="fw-bold text-uppercase theme_text_color"
                      style={{ fontSize: '15px' }}
                    >
                      VIN/SERIAL NUMBER
                    </TableCell>
                    <TableCell
                      className="fw-bold"
                      style={{ color: '#a7a9ac', width: '50%' }}
                    >
                      {jobData.vinOrSerial}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="fw-bold text-uppercase theme_text_color"
                      style={{ fontSize: '15px' }}
                    >
                      LINK TO LISTING
                    </TableCell>
                    <TableCell className="fw-bold">
                      <a
                        target="blank"
                        style={{
                          color: '#d40000',
                          textDecoration: 'none',
                          width: '50%',
                        }}
                        href={jobData.link}
                      >
                        VIEW LISTING
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div className="current_job_detail_buyer_table_heading_div text-uppercase mt-4">
                <b>
                  <p className="ms-3">LOCATION AND POINT OF CONTACT</p>
                </b>
              </div>
              <Table style={{ width: '100%' }}>
                <TableHead></TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="fw-bold text-uppercase theme_text_color"
                      style={{ fontSize: '15px' }}
                    >
                      ADDRESS
                    </TableCell>
                    <TableCell
                      className="fw-bold"
                      style={{ color: '#a7a9ac', width: '50%' }}
                    >
                      {jobData.address}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="fw-bold text-uppercase theme_text_color"
                      style={{ fontSize: '15px' }}
                    >
                      POINT OF CONTACT
                    </TableCell>
                    <TableCell
                      className="fw-bold"
                      style={{ color: '#a7a9ac', width: '50%' }}
                    >
                      {jobData.contactName}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table style={{ width: '100%', marginTop: '10px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="fw-bold text-uppercase theme_text_color"
                      style={{
                        color: '#414042',
                        fontSize: '20px',
                        width: '70%',
                      }}
                    >
                      SPECIAL INSTRUCTIONS
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="Font_Family"
                      style={{
                        color: '#a7a9ac',
                        width: '50%',
                        fontSize: '15px',
                      }}
                    >
                      {jobData.specialInstructions}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CurrentJobDetailBuyer;
