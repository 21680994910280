import apiProtected from 'api/apiClient';
import { setUserDashboardStat } from 'redux/reducers/R8tr/UserDashboardStatReducer';

import { ApiUrl } from '../../../ApiUrl';

export const UserDashboardStatAction = () => (dispatch) => {
  apiProtected
    .get(`${ApiUrl.server_url}v1/dashboards/get-dashboard-stats`)
    .then((res) => {
      dispatch(setUserDashboardStat(res.data));
    });
};
