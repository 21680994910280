import apiProtected from 'api/apiClient';
import { setAvailableJobs } from 'redux/reducers/R8tr/AvailableJobsReducer';

import { ApiUrl } from '../../../ApiUrl';

export const AvailableJobsAction = () => (dispatch) => {
  apiProtected
    .get(`${ApiUrl.server_url}v1/jobs/get-available-jobs`)
    .then((res) => {
      dispatch(setAvailableJobs(res.data));
    });
};
