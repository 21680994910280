import { ApiUrl } from 'ApiUrl';
import axios from 'axios';
import { setIsLoggedIn } from 'redux/reducers/loginSlice';
import { store } from 'redux/store';

export const api = axios.create({
  baseURL: ApiUrl.server_url,
});

const apiProtected = axios.create({
  baseURL: ApiUrl.server_url,
});

let isRefreshing = false;
//@ts-ignore
let failedQueue = [];
//@ts-ignore
const processQueue = (error, token = null) => {
  //@ts-ignore
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const fetchRefreshToken = async (refreshToken: string) => {
  const { data } = await api.post('v1/auth/refresh-token', { refreshToken });

  localStorage.setItem(
    'tokens',
    JSON.stringify({
      accessToken: data?.token,
      refreshToken: data?.refreshToken,
    })
  );

  return data;
};

apiProtected.interceptors.request.use(
  (config) => {
    //@ts-ignore
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    if (tokens?.accessToken) {
      //@ts-ignore
      config.headers['Authorization'] = `Bearer ${tokens?.accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiProtected.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return apiProtected(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;
      //@ts-ignore
      const tokens = JSON.parse(localStorage.getItem('tokens'));
      if (!tokens?.refreshToken) {
        //@ts-ignore
        store.dispatch(setIsLoggedIn(false));
        return Promise.reject(error);
      }

      return new Promise((resolve, reject) => {
        fetchRefreshToken(tokens.refreshToken)
          .then((newTokens) => {
            apiProtected.defaults.headers.common['Authorization'] =
              `Bearer ${newTokens.token}`;
            originalRequest.headers['Authorization'] =
              `Bearer ${newTokens.token}`;
            processQueue(null, newTokens.token);
            resolve(apiProtected(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            //@ts-ignore
            store.dispatch(setIsLoggedIn(false));
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default apiProtected;
