import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
// import Loader from "../../Loader/Loader";
import { useLocation } from 'react-router-dom';
import S3FileUpload from 'react-s3';

import image_upload_icon from '../../../assets/images/image_upload_icon.png';
import './videoupload.css';
window.Buffer = window.Buffer || require('buffer').Buffer;

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import apiProtected from 'api/apiClient';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../../ApiUrl';
import * as SweetAlert from '../../SweetAlert';
const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};
// const percentage = 66;

function VideoUpload() {
  const location = useLocation();
  const navigate = useNavigate();
  const jobData = location.state.jobData;
  const [videos, setVideos] = useState([]);
  const [videosForUpload, setVideosForUpload] = useState([]);
  const [videosUploaded, setVideosUploaded] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(true);
  const handleSelectVideos = (e) => {
    if (e.target.files.length != 0) {
      setVideos([...videos, URL.createObjectURL(e.target.files[0])]);
    }
    setVideosForUpload([...videosForUpload, e.target.files[0]]);
  };

  const handleUploadVideos = async () => {
    const config = {
      bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
      dirName: `${process.env.REACT_APP_ENVIRONMENT}/job-ratings-media/videos`,
      region: process.env.REACT_APP_S3_REGION,
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    };

    try {
      let tempVideosArray = [];
      if (videos.length > 0) {
        setShowDeleteIcon(false);
        setLoading(true);
        for (let i = 0; i < videosForUpload.length; i++) {
          setVideosUploaded(i + 1);
          if (videosForUpload[i]) {
            let uploadRes = await S3FileUpload.uploadFile(
              videosForUpload[i],
              config
            );
            tempVideosArray.push(uploadRes.location);
          }
        }
      } else {
        toast.error('Please upload at least one video', toastOptions);
      }

      apiProtected
        .post(
          `${ApiUrl.server_url}v1/rating-criteria-users/post-rating-videos`,
          { links: tempVideosArray, jobId: jobData.id }
        )
        .then(() => {
          setLoading(false);
          toast.success('Videos uploaded successfully', toastOptions);
          setTimeout(() => {
            navigate('/special-notes', { state: { jobData: jobData } });
          }, 1000);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error('Error in uploading files', toastOptions);
    }
  };

  const handleDeleteVideosRes = (index) => {
    console.log(index);
    let videosArr = [...videos];
    let videosForUploadArr = [...videosForUpload];
    videosArr.splice(index, 1);
    videosForUploadArr.splice(index, 1);
    setVideos(videosArr);
    setVideosForUpload(videosForUploadArr);
  };
  const handleDeleteVideos = (index) => {
    SweetAlert.deleteVideoAlert(handleDeleteVideosRes, index);
  };
  return (
    <div style={{ width: '100%', marginTop: '30px' }}>
      <ToastContainer />
      <Container className="r8ting_main_container">
        <Row className="VideoUpload_main_row">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            // className="r8ting_main_col"
            style={{ borderBottom: '1px solid #a7a9ac' }}
          >
            <p className="theme_text_color Font_Family text-uppercase fw-bold">
              Upload Walk Around Videos
            </p>
          </Col>
          {/* <hr></hr> */}
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            // className="r8ting_main_col"
          >
            <Row style={{ marginTop: '10px' }}>
              {/* <Col xs={12} sm={12} md={12} lg={12}> */}
              {/* <div className="VideoUpload_main_row"> */}
              <Col xs={6} sm={4} md={3} lg={3}>
                <img
                  src={image_upload_icon}
                  alt="upload video"
                  style={{ width: '100%', height: '120px', cursor: 'pointer' }}
                  onClick={() => {
                    document.getElementById('videoField').click();
                  }}
                />

                <input
                  type="file"
                  accept="video/*"
                  // acceptFiles="video/*"
                  onChange={handleSelectVideos}
                  id="videoField"
                  // style={{ opacity: "0" }}
                  className="videoField"
                />
              </Col>

              {/* <div className="VideoUpload_map_col"> */}
              {videos.map((item, index) => (
                <Col xs={6} sm={4} md={3} lg={3} key={item}>
                  <div
                    style={{ borderRadius: '10px', width: '100%' }}
                    className="criteriaOption_video_upload_image_preview_div"
                  >
                    {showDeleteIcon && (
                      <span
                        className="criteriaOption_video_delete_icons"
                        onClick={() => handleDeleteVideos(index)}
                      >
                        <HighlightOffIcon
                          style={{
                            fontSize: '18px',
                            textAlign: 'center',
                            backgroundColor: 'white',
                            borderRadius: '25px',
                          }}
                        />
                      </span>
                    )}

                    <ReactPlayer
                      url={item}
                      width="100%"
                      height="120px"
                      playIcon={'play'}
                      controls={true}
                      style={{ borderRadius: '10px' }}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="r8ting_main_col my-4"
            style={{ justifyContent: 'center' }}
          >
            <button
              className="btn Font_Family"
              style={{
                height: '45px',
                borderRadius: '0px 15px 0px 15px',
                backgroundColor: '#e61d2a',
                color: 'white',
                width: '35%',
              }}
              disabled={videosForUpload.length < 1 || loading == true}
              onClick={() => {
                handleUploadVideos();
              }}
            >
              <span className="d-flex justify-content-center align-content-center w-100">
                {loading ? (
                  <span>
                    Uploading {videosUploaded}/{videosForUpload.length} videos
                  </span>
                ) : (
                  <span>UPLOAD</span>
                )}
              </span>
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default VideoUpload;
