import apiProtected from 'api/apiClient';
import { setSingleJobDetails } from 'redux/reducers/R8tr/SingleJobDetailReducer';

import { ApiUrl } from '../../../ApiUrl';

export const SingleJobDetailAction = (jobId) => (dispatch) => {
  apiProtected
    .get(`${ApiUrl.server_url}v1/jobs/get-job-details`, {
      params: {
        id: jobId,
      },
    })
    .then((res) => {
      dispatch(setSingleJobDetails(res.data));
    });
};
