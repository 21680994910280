import apiProtected from 'api/apiClient';
import { setR8tingCategoryDamages } from 'redux/reducers/R8tr/R8tingCategoryDamagesReducer';

import { ApiUrl } from '../../../ApiUrl';

export const R8tingCategoryDamagesAction =
  (criteriaId, jobId) => (dispatch) => {
    apiProtected
      .get(
        `${ApiUrl.server_url}v1/rating-criterias/get-rating-criteria-damages`,
        {
          params: {
            id: criteriaId,
            jobId,
          },
        }
      )
      .then((res) => {
        dispatch(setR8tingCategoryDamages(res.data));
      });
  };
