import React from "react";

// import LayoutProfile from "../../components/SideNavProfile/layoutProfile/LayoutProfile";
import PersonalInformations from "../../components/Profile/ProfileInformation/ProfileInformation";
// import CompleteProfile from "../../components/Profile/CompleteProfile/CompleteProfile";

const Profie = () => {
  return (
    <div style={{ width: "100%" }}>
      {/* <LayoutProfile> */}
      {/* <CompleteProfile /> */}
      <PersonalInformations />
      {/* </LayoutProfile> */}
    </div>
  );
};

export default Profie;
