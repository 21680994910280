import './signup.css';

import { CallOutlined } from '@mui/icons-material';
import { api } from 'api/apiClient';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../ApiUrl';
import logo from '../../assets/images/logo.webp';
import SubAccountContext from '../../utils/SubAccountContext';
import Loader from '../Loader/Loader';
import OtpVerification from '../OtpVerification/OtpVerification';

export default function Signup() {
  const toastOptions = {
    position: 'top-right',
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  };
  const navigator = useNavigate();
  const url = new URL(window.location).searchParams;
  const itemId = url.get('itemID');

  const location = useLocation();
  const subUserIdContext = useContext(SubAccountContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userRole, setUserRole] = useState('buyer');
  const [subAccount, setSubAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOtpVerificationModal, setShowOtpVerificationModal] =
    useState(false);
  const handleShowOtpVerificationModal = () => {
    setShowOtpVerificationModal(!showOtpVerificationModal);
  };

  useEffect(() => {
    if (location?.state?.userId) {
      setUserRole('Buyer');
      setSubAccount(true);
      subUserIdContext.subAccountIdHandler(location?.state?.userId);
    }
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    api
      .post(`${ApiUrl.server_url}v1/auth/send-otp`, {
        phoneNumber: phoneNumber,
        userRole: userRole,
        forgetPasswordOTP: false,
      })
      .then(() => {
        setLoading(false);
        localStorage.setItem('userRole', userRole);

        setShowOtpVerificationModal(!showOtpVerificationModal);
      })
      .catch((error) => {
        let errorMessage = error.response.data.message;

        toast.error(
          errorMessage ? errorMessage : 'Something went wrong',
          toastOptions
        );
        setLoading(false);
      });
  };
  return (
    <div className="signup_container">
      <Container className="signup_row_container">
        <ToastContainer />
        <Row className="signup_row_container">
          <Col xxl={4} xl={4} lg={4} md={5} sm={10} xs={10}>
            <div>
              <img
                src={logo}
                alt="logo"
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: '50px',
                  display: 'block',
                }}
              />
            </div>
            <form style={{ width: '100%' }}>
              <div className="input-container">
                <i className="fa fa-user icon">
                  <CallOutlined className="signup_input_icon" />
                </i>
                <input
                  className="input-field"
                  // defaultValue="+1"
                  type="text"
                  placeholder="PHONE NUMBER"
                  id="phoneNumber"
                  required
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  style={{ borderBottomLeftRadius: '0px' }}
                />
              </div>
              {url.has('itemID') ? (
                ''
              ) : (
                <>
                  <p className="text-center h3 signup_p_tage">Are You</p>
                  {!subAccount && (
                    <div className="user_type_div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          value="buyer"
                          id="userType"
                          checked={userRole == 'buyer'}
                          onChange={(e) => setUserRole(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          BUYER
                        </label>
                      </div>{' '}
                      &nbsp; &nbsp;
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          value="r8tr"
                          id="userType"
                          checked={userRole == 'r8tr'}
                          onChange={(e) => setUserRole(e.target.value)}
                          style={{
                            boxShadow: 'none',
                            MozBoxShadow: 'none',
                            outline: 'none',
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          R8TR
                        </label>
                      </div>
                    </div>
                  )}
                </>
              )}

              <button
                type="button"
                onClick={handleSubmit}
                className="btn  w-100 Font_Family mt-4 text-center"
                style={{
                  height: '45px',
                  borderRadius: '0px 25px 0px 25px',
                  backgroundColor: '#e61d2a',
                  color: 'white',
                }}
              >
                <span className="d-flex justify-content-center align-content-center w-100">
                  {loading ? <Loader /> : <span>SIGNUP</span>}
                </span>
              </button>

              <div className="sinup-container mt-4 ">
                <p className="Font_Family signup_p_tage">
                  ALREADY HAVE ACCOUNT &nbsp;
                  <span
                    className="theme-color"
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={
                      !itemId
                        ? () => navigator('/login')
                        : () => navigator(`/login?itemID=${itemId}`)
                    }
                  >
                    <u>SIGN IN</u>
                  </span>
                </p>
              </div>
            </form>
          </Col>
          <Col xxl={6} lg={6} md={6} sm={0} xs={0} className="column_two"></Col>
        </Row>
        {showOtpVerificationModal && (
          <OtpVerification
            handleShowOtpVerificationModal={handleShowOtpVerificationModal}
            forgetPassword={false}
            phoneNumber={phoneNumber}
            itemId={itemId}
          />
        )}
      </Container>
    </div>
  );
}
