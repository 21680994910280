import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allCurrentJobs: [],
  allPastJobs: [],
  scrappedData: {},
};

const buyerJobSlice = createSlice({
  name: 'buyerJob',
  initialState,
  reducers: {
    getBuyerCurrentJobs: (state, action) => {
      state.allCurrentJobs = [...action.payload.data];
    },
    getBuyerPastJobs: (state, action) => {
      state.allPastJobs = [...action.payload.data];
    },
    scrappedDetails: (state, action) => {
      state.scrappedData = action.payload;
    },
  },
});

export const { getBuyerCurrentJobs, getBuyerPastJobs, scrappedDetails } =
  buyerJobSlice.actions;

export default buyerJobSlice.reducer;
