import './specialNotes.css';

import apiProtected from 'api/apiClient';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { ApiUrl } from '../../../ApiUrl';

function SpecialNotes({ jobData }) {
  const [specialNotes, setSpecialNotes] = useState(null);
  useEffect(() => {
    apiProtected
      .get(
        `${ApiUrl.server_url}v1/rating-special-messages/?jobId=${jobData.id}`
      )
      .then((res) => {
        console.log(res);
        setSpecialNotes(res?.data.data?.specialMessage);
      });
  }, []);

  return (
    <Row className="R8trRating_text_row" style={{ marginTop: '20px' }}>
      <Row className="R8trRating_inner_row" style={{ marginTop: '30px' }}>
        <Col md={2} lg={2} className="R8trRating_text_col"></Col>
        <Col xs={12} sm={12} md={8} lg={8} className="R8trRating_text_col">
          <div style={{ width: '100%' }}>
            <p
              style={{ fontSize: '18px' }}
              className="fw-bold theme_text_color Font_Family"
            >
              SPECIAL NOTES ...
            </p>
            <textarea
              type="text"
              placeholder="TYPING..."
              readOnly
              value={specialNotes}
              style={{
                width: '100%',
                height: '100px',
                resize: 'none',
                padding: '5px',
              }}
            ></textarea>
          </div>
        </Col>
        <Col md={2} lg={2} className="R8trRating_text_col"></Col>
      </Row>
    </Row>
  );
}

export default SpecialNotes;
