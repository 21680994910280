import apiProtected from 'api/apiClient';
import { getPdfData } from 'redux/reducers/GeneratePdfReducer';

import { ApiUrl } from '../../ApiUrl';

export const GeneratePdfAction = (jobId) => (dispatch) => {
  apiProtected
    .get(
      ApiUrl.server_url +
        `v1/jobs/get-job-details-for-pdf-for-non-company-jobs`,
      { params: { id: jobId } }
    )
    .then((res) => {
      dispatch(getPdfData(res.data));
    });
};
