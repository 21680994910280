import { Grid } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import apiProtected from 'api/apiClient';
import DropIn from 'braintree-web-drop-in-react';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../../ApiUrl';

const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};
const TipPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tip = location.state.tip;
  const jobId = location.state.jobId;

  const [loading, setLoading] = useState(false);
  const [clientToken, setClientToken] = useState(null);
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await apiProtected.get(
        `${ApiUrl.server_url}v1/braintree/get-client-token`
      );
      setClientToken(res.data.data);
    })();
  }, []);

  const handleSubmitPayment = async () => {
    setLoading(true);
    const { nonce } = await instance.requestPaymentMethod();
    try {
      const createJobRes = await apiProtected.patch(
        `${ApiUrl.server_url}v1/jobs/add-tip`,
        {
          tip: tip,
          jobId: jobId,
          nonce: nonce,
        }
      );
      setLoading(false);
      toast.success(createJobRes.data.message, toastOptions);
      navigate('/');
    } catch (error) {
      setLoading(false);
      console.log('Got error while creating job');
      console.log(error);
      const errorMassage = error.response.data.message;
      toast.error(errorMassage, toastOptions);
    }
  };

  if (!clientToken) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!clientToken}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <>
        <ToastContainer />
        <br></br>
        <Grid container>
          <Grid item md={2} lg={2}></Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <br></br>
            <p
              style={{ fontSize: '18px' }}
              className="fw-bold theme_text_color Font_Family"
            >
              PAYMENT INFORMATION
            </p>
            <hr></hr>
          </Grid>
          <Grid item md={2} lg={2}></Grid>
        </Grid>
        <Grid container>
          <Grid item md={2} lg={2}></Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <div style={{ marginTop: '50px' }}>
              <DropIn
                options={{
                  authorization: clientToken,
                  vaultManager: true,
                }}
                onInstance={async (instance) => {
                  setInstance(instance);
                }}
              />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  className="w-25"
                  style={{
                    backgroundColor: '#d40000',
                    borderColor: '#d40000',
                    borderRadius: '0px 15px 0px 15px',
                    height: '50px',
                  }}
                  onClick={handleSubmitPayment}
                >
                  <span className="d-flex justify-content-center align-content-center w-100">
                    {/* {loading ? <Loader /> : <span>Update</span>} */}
                    SUBMIT PAYMENT
                  </span>
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item md={2} lg={2}></Grid>
        </Grid>
        <br></br>
        <br></br>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
};

export default TipPayment;
