import apiProtected from 'api/apiClient';
import { getAllStates, getUserDetail } from 'redux/reducers/UserReducer';

import { ApiUrl } from '../../ApiUrl';

export const getUserData = () => (dispatch) => {
  apiProtected.get(ApiUrl.server_url + 'v1/users/get-profile').then((res) => {
    dispatch(getUserDetail(res.data));
  });
};

export const getStates = () => (dispatch) => {
  apiProtected.get(ApiUrl.server_url + 'v1/states/').then((res) => {
    dispatch(getAllStates(res.data));
  });
};

export const updateAddresses = (serviceAreas, handleResponse) => (dispatch) => {
  apiProtected
    .patch(ApiUrl.server_url + 'v1/addresses', { serviceAreas })
    .then((res) => {
      dispatch(getUserData());
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error.response.data);
    });
};

export const updateAreaOfExperties =
  (selectedSubCategories, handleResponse) => (dispatch) => {
    apiProtected
      .patch(ApiUrl.server_url + 'v1/level-of-expertise/area-of-experties', {
        selectedSubCategories,
      })
      .then((res) => {
        dispatch(getUserData());
        handleResponse(res.data);
      })
      .catch((error) => {
        handleResponse(error.response.data);
      });
  };

export const updateLevelOfExperties =
  (levelOfExpertise, handleResponse) => (dispatch) => {
    apiProtected
      .patch(ApiUrl.server_url + 'v1/level-of-expertise/', { levelOfExpertise })
      .then((res) => {
        dispatch(getUserData());
        handleResponse(res.data);
      })
      .catch((error) => {
        handleResponse(error.response.data);
      });
  };

export const completeProfileUser = (data, handleResponse) => (dispatch) => {
  apiProtected
    .patch(ApiUrl.server_url + 'v1/users/update-profile', {
      firstName: data.firstName,
      email: data.email,
      companyName: data.companyName,
      bio: data.bio,
      avatar: data.avatar,
    })
    .then((res) => {
      dispatch(getUserData());
      handleResponse(res.data);
    })
    .catch((error) => {
      handleResponse(error.response.data);
    });
};

export const getAllCategories = () => (dispatch) => {
  apiProtected
    .get(ApiUrl.server_url + 'v1/sub-categories/categories-and-sub-categories')
    .then((res) => {
      dispatch(getAllCategories(res.data));
    });
};
