import { Grid } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import apiProtected from 'api/apiClient';
import DropIn from 'braintree-web-drop-in-react';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ApiUrl } from '../../ApiUrl';
// import Loader from "./../../components/Loader/Loader";
import { getUserData } from '../../redux/actions/UserAction';

const Braintree = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [clientToken, setClientToken] = useState(null);
  const [instance, setInstance] = useState(null);
  const [showAddCardButton, setShowAddCardButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  useEffect(() => {
    (async () => {
      const res = await apiProtected.get(
        `${ApiUrl.server_url}v1/braintree/get-client-token`
      );
      setClientToken(res.data.data);
    })();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('userRole') == 'buyer') {
      if (userDetails?.data?.user?.phoneNumber) {
        if (
          userDetails.data?.user?.firstName == null ||
          userDetails.data?.user?.firstName == '' ||
          userDetails.data?.user?.email == null ||
          userDetails.data?.user?.email == ''
        ) {
          navigator('/personal-info', {
            state: { massage: 'Please Complete your profile' },
          });
        }
      }
    }
  }, [userDetails]);

  const buy = async () => {
    setLoading(true);
    // Send the nonce to your server
    const { nonce } = await instance.requestPaymentMethod();
    const body = { nonce: nonce };
    try {
      await apiProtected.post(
        `${ApiUrl.server_url}v1/braintree/add-payment-method`,
        body
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  if (!clientToken) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!clientToken}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <>
        <Grid container>
          <Grid item md={1} lg={1}></Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <div style={{ marginTop: '50px' }}>
              <DropIn
                options={{
                  authorization: clientToken,
                  vaultManager: true,
                  // paypal: {
                  //   flow: "checkout" | "vault",
                  //   amount: 300,
                  //   currency: "USD",
                  //   commit: false,
                  // },
                  // applePay: {
                  //   buttonStyle: "black" | "white" | "white-outline",
                  //   displayName: string,
                  //   applePaySessionVersion: number | undefined,
                  //   paymentRequest: ApplePayPaymentRequest,
                  // },
                  // venmo: {
                  //   allowNewBrowserTab: true,
                  // },
                }}
                onInstance={async (instance) => {
                  try {
                    await instance.requestPaymentMethod();
                  } catch (error) {
                    if (error.message.includes('No payment method')) {
                      setShowAddCardButton(true);
                    }
                  }
                  setInstance(instance);
                }}
              />
              {showAddCardButton && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    className="w-25"
                    style={{
                      backgroundColor: '#d40000',
                      borderColor: '#d40000',
                      borderRadius: '0px 15px 0px 15px',
                      height: '50px',
                    }}
                    onClick={buy}
                  >
                    <span className="d-flex justify-content-center align-content-center w-100">
                      {/* {loading ? <Loader /> : <span>Update</span>} */}
                      Add Card
                    </span>
                  </Button>
                </div>
              )}
            </div>
          </Grid>
          <Grid item md={1} lg={1}></Grid>
        </Grid>
        <br></br>
        <br></br>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
};

export default Braintree;
