import { makeStyles } from "@material-ui/core";

export const LeftBarStyle = makeStyles((theme) => ({
  container: {
    position: "relative",
    fontFamily: "Roboto",
    // backgroundImage:
    //   "url(" +
    //   "../../../../assets/images/left_bar/left_bar_background.webp" +
    //   ")",
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: "250px",
    },
  },
  drawerPaper: {
    width: "250px",
    backgroundColor: "white",
  },
  logoDiv: {
    padding: "10px 30px",
    height: "65px",
    width: "100%",
    // position: "fixed",
  },
  bottomSideNav: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    color: "#414042",
  },
  spanSideNav: {
    display: "flex",
    marginBottom: "10px",
    fontSize: "10px",
  },
  supportSpan: {
    cursor: "pointer",
    marginLeft: "25px",
    gap: "10px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
  switchSpan: {
    cursor: "pointer",
    gap: "10px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
  buttonSideNav: {
    width: "100%",
    borderRadius: "none",
  },
  RatingContent: {
    width: "100%",
    color: "black",
    display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  rating_avatar: {
    width: "54px",
    height: "40px",
  },
}));
