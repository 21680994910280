import "./r8ting.css";

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast,ToastContainer } from "react-toastify";

import downarrow from "../../../assets/icons/downarrow.webp";
import uparrow from "../../../assets/icons/uparrow.webp";
import { R8tingCategoryDamagesAction } from "../../../redux/actions/R8tr/R8tingCategoryDamagesAction";
import { R8tingCategoryDataAction } from "../../../redux/actions/R8tr/R8tingCategoryDataAction";
import { R8tingCategoryListAction } from "../../../redux/actions/R8tr/R8tingCategoryListAction";
import * as SweetAlert from "../../SweetAlert";
import CriteriaOption from "../CriteriaOption/CriteriaOption";
const toastOptions = {
  position: "top-right",
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
};
function R8ting() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openedCriteriaId, setOpenedCriteriaId] = useState(null);
  const [criteriaList, setCriteriaList] = useState([]);
  const [criteriaListTitle, setCriteriaListTitle] = useState("");
  // const [criteriaDataShow, setCriteriaDataShow] = useState(false);

  const { R8tingCategoryList } = useSelector(
    (state) => state.R8tingCategoryListReducer
  );
  const { R8tingCategoryData } = useSelector(
    (state) => state.R8tingCategoryDataReducer
  );

  const { R8tingCategoryDamages } = useSelector(
    (state) => state.R8tingCategoryDamagesReducer
  );
  const location = useLocation();
  const jobData = location.state.jobData;
  useEffect(() => {
    dispatch(R8tingCategoryListAction(jobData.id));
    dispatch(R8tingCategoryDamagesAction());
  }, []);
  useEffect(() => {
    if (R8tingCategoryList?.ratingCriterias?.length > 0) {
      setCriteriaList(R8tingCategoryList?.ratingCriterias);
      // setOpenedCriteriaId(R8tingCategoryList[0].id);
      // dispatch(R8tingCategoryDataAction(R8tingCategoryList[0].optionId));
    }
  }, [R8tingCategoryList]);
  const handleR8tingCategoryListUpdates = () => {
    dispatch(R8tingCategoryListAction(jobData.id));
  };
  const handleR8tingOptionsList = (id, optionId, title) => {
    setCriteriaListTitle(title);
    if (openedCriteriaId == id) {
      setOpenedCriteriaId(null);
    } else {
      setOpenedCriteriaId(id);
    }
    dispatch(R8tingCategoryDataAction(optionId));
    dispatch(R8tingCategoryDamagesAction(id));
  };

  const handleContinue = () => {
    navigate("/upload-videos", { state: { jobData: jobData } });
  };

  const handlePressNext = () => {
    if (
      R8tingCategoryList?.ratingsCompleted &&
      R8tingCategoryList?.ratingsCompleted == true
    ) {
      SweetAlert.ratingsCompletedAlert(handleContinue);
    } else {
      toast.error(
        "Please complete ratings for all criterias before proceeding",
        toastOptions
      );
    }
  };

  return (
    <div>
      <ToastContainer />
      <Container className="r8ting_main_container">
        <Row className="r8ting_main_row">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="r8ting_main_col"
          >
            <span
              className="Font_Family theme_text_color h4 r8ting_title_span w-100 text-uppercase"
              style={{ fontWeight: "bold" }}
            >
              {jobData.make} {jobData.name}-{jobData.model}
            </span>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="r8ting_main_col"
          >
            <div className="vehical_sep_div">
              {/* <img
                src={jobData.category.icon}
                alt="icon"
                style={{ width: "30px", height: "30px" }}
              /> */}
              <svg
                viewBox="0 0 100 100"
                version="1.1"
                xmlns={jobData.category.icon}
                style={{ marginRight: "10px" }}
                className="siglejob_svg"
              >
                <defs>
                  <pattern
                    id="img"
                    patternUnits="userSpaceOnUse"
                    width="100"
                    height="100"
                  >
                    <image
                      xlinkHref={jobData.category.icon}
                      x="-25"
                      width="250"
                      height="100"
                    />
                  </pattern>
                </defs>
                <polygon
                  points="50 1 95 25 95 75 50 99 5 75 5 25"
                  fill="url(#img)"
                />
              </svg>

              <p className="theme_text_color Font_Family text-uppercase font-weight-bold">
                <span
                  className="theme_text_color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  PRICE
                </span>
                <br />
                <span
                  className="theme-color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  ${jobData.askingPrice}
                </span>
              </p>

              <p className="theme_text_color Font_Family text-uppercase">
                <span
                  className="theme_text_color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  MILES
                </span>
                <br />
                <span
                  className="silver_Color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  {jobData.zip}
                </span>
              </p>

              <p className="theme_text_color Font_Family text-uppercase">
                <span
                  className="theme_text_color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  CUSTOMER MICHAEL
                </span>
                <br />
                <span
                  className="silver_Color fw-bold"
                  style={{ fontSize: "14px" }}
                >
                  {jobData.contactNumber}
                </span>
              </p>
            </div>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="r8ting_main_col"
          >
            <div className="R8tingCategoryList_tabs_container">
              {criteriaList.map((item) => (
                <div key={item.id}>
                  <ul
                    className={` R8tingCategoryList_tabs Font_Family ${
                      openedCriteriaId == item.id
                        ? "R8tingCategoryList_tabs_Active "
                        : "R8tingCategoryList_tabs_color"
                    }`}
                    onClick={() => {
                      handleR8tingOptionsList(
                        item.id,
                        item.optionId,
                        item.title
                      );
                    }}
                  >
                    <li
                      className="R8tingCategoryList_tabs_li text-uppercase theme_text_color fw-bold"
                      style={{ paddingLeft: "10px", fontSize: "14px" }}
                    >
                      {item.title}
                    </li>
                    {/* <span className="R8tingCategoryList_tabs_li_span"> */}

                    <li className="R8tingCategoryList_tabs_li2">
                      {/* <li> */}
                      <p
                        style={{
                          display: "inline",
                          // marginRight: "50px",
                          justifyContent: "start",
                          alignItems: "self-start",
                          fontSize: "14px",
                        }}
                        className="theme_text_color fw-bold"
                      >
                        {item.ratingCriteriaUser != null
                          ? `RATED ${item.ratingCriteriaUser.ratingValue}`
                          : "NOT RATED"}
                        {/* </li> */}
                      </p>
                      {openedCriteriaId == item.id ? (
                        <img
                          src={uparrow}
                          alt="uparrow"
                          className="theme_text_color fw-bold"
                          style={{ width: "15px", height: "10px" }}
                        />
                      ) : (
                        <img
                          src={downarrow}
                          alt="downarrow"
                          style={{ width: "15px", height: "10px" }}
                        />
                      )}
                    </li>
                    {/* </span> */}
                  </ul>
                  {/* <div
                    className={`${
                      openedCriteriaId == item.id
                        ? "r8ting_criteria_div"
                        : "r8ting_criteria_div2"
                    }`}
                  > */}
                  {openedCriteriaId && openedCriteriaId == item.id && (
                    <CriteriaOption
                      ratingCriteriaOptions={R8tingCategoryData}
                      damages={R8tingCategoryDamages[0]?.damages}
                      ratingCriteria={item}
                      jobId={jobData.id}
                      ratingCriteriaId={item.id}
                      criteriaListTitle={criteriaListTitle}
                      handleR8tingCategoryListUpdates={
                        handleR8tingCategoryListUpdates
                      }
                    />
                  )}
                  {/* // </div> */}
                </div>
              ))}
            </div>
          </Col>
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            className="r8ting_main_col my-4"
            style={{ justifyContent: "center" }}
          >
            <button
              className="btn  w-25 Font_Family"
              style={{
                height: "45px",
                borderRadius: "0px 15px 0px 15px",
                backgroundColor: "#e61d2a",
                color: "white",
              }}
              onClick={handlePressNext}
            >
              <span className="d-flex justify-content-center align-content-center w-100">
                {/* {loading ? <Loader /> : <span>LOGIN</span>} */}
                NEXT
              </span>
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default R8ting;
