import './topbar.css';

import { AppBar, Avatar, IconButton, Toolbar } from '@material-ui/core';
import { Menu, PowerSettingsNew } from '@material-ui/icons';
import { Box, Button, Typography } from '@mui/material';
import { api } from 'api/apiClient';
// import { ApiUrl } from "../../../../ApiUrl";
import { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { BsChatDots } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useLogout from 'redux/actions/useLogout';

import { ApiUrl } from '../../../../ApiUrl';
import logoProfile from '../../../../assets/images/logo.webp';
import notification from '../../../../assets/images/topbar_icons/notification.webp';
import money from '../../../../assets/images/topbar_icons/save_money.webp';
import star from '../../../../assets/images/topbar_icons/star.webp';
import { R8trNotificationAction } from '../../../../redux/actions/R8tr/R8trNotificationAction';
import { UserDashboardStatAction } from '../../../../redux/actions/R8tr/UserDashboardStatAction';
import { getUserData } from '../../../../redux/actions/UserAction';
import ChatDialogs from '../../../ChatContainer/ChatDialogs';
import { TopBarStyle } from './TopBarStyle';
const TopBar = ({ funcSetIsMobile, socket }) => {
  const ebayUrl = new URL(window.location).searchParams;

  const storedItemId = ebayUrl.get('itemID');
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const logout = useLogout();
  const userRole = localStorage.getItem('userRole');
  const { limitedNotificationsData } = useSelector(
    (state) => state.R8trNotificationReducer
  );
  const { dashboardData } = useSelector(
    (state) => state.UserDashboardStatReducer
  );
  const [showPopover, setShowPopover] = useState(false);
  const [userProfilePic, setUserProfilePic] = useState();
  const [messageCounter, setMessageCounter] = useState(null);
  const [showChatBox, setShowChatBox] = useState(false);
  const classes = TopBarStyle();
  const handlePopoverView = () => {
    setShowPopover(!showPopover);
    dispatch(UserDashboardStatAction());

    navigator('/notification');
  };

  const urlOne = location.pathname.split('/');

  const url = urlOne[1]?.split('-');
  const { userDetails } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    setUserProfilePic(userDetails?.data?.user?.avatar);
  }, [userDetails]);

  const popover = (
    <Popover
      id="popover-basic"
      style={{
        width: '230px',
        position: 'absolute',
        right: '300px',
        // marginRight: "50px",
      }}
      rootClose
      // onClose={() => setShowPopover(false)}
    >
      <Popover.Header as="h3" style={{ backgroundColor: 'white' }}>
        <p
          className="Font_Family text-uppercase"
          style={{ fontWeight: 'bold' }}
        >
          Notification <br />
          <span style={{ fontSize: '10px' }} className="silver_Color">
            you have {dashboardData?.data?.unSeenNotificationsCount}{' '}
            notification
          </span>
        </p>
      </Popover.Header>
      <Popover.Body style={{ padding: 'none', margin: 'none' }}>
        {limitedNotificationsData.map((item) => (
          <div key={item.id} className="notification_container">
            <p className="Font_Family text-uppercase">
              {item.title}
              <br />
              <span
                style={{ fontSize: '10px' }}
                className="silver_Color text-uppercase"
              >
                {item.body}
              </span>
            </p>
          </div>
        ))}
        <button
          type="submit"
          className=" rounded-0 w-100 Font_Family"
          // trigger="click"
          style={{
            height: '40px',
            borderRadius: 'none',
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'none',
            // color: "white",
          }}
          onClick={handlePopoverView}
        >
          <span
            className="text-decoration-underline "
            style={{ height: '50px', color: '#d40000', cursor: 'pointer' }}
          >
            SEE MORE
          </span>
        </button>
      </Popover.Body>
    </Popover>
  );
  const handlePopoverData = () => {
    dispatch(R8trNotificationAction(3));
    setShowPopover(!showPopover);
  };
  useEffect(() => {
    dispatch(UserDashboardStatAction());
    dispatch(getUserData());
  }, []);

  const handleToken = async () => {
    api.post(`${ApiUrl.server_url}v1/auth/logout`, {
      deviceType: 'web',
      userId: userDetails?.data?.user?.id,
    });

    logout();
  };

  useEffect(() => {
    setMessageCounter(dashboardData?.data?.unSeenMessagesCount);
  }, [dashboardData]);
  useEffect(() => {
    if (location.pathname == '/hire-a-r8tr') {
      return;
    } else {
      socket.on('unseen messages count', (data) => {
        if (!showChatBox) {
          setMessageCounter(data);
        }
      });
    }
  }, [socket]);
  const handleChatDialogClose = () => {
    socket.emit('room leave', userDetails?.data?.user?.id + '_' + 'admin');
    setShowChatBox(false);
  };
  const handleChatDialog = () => {
    const data = {
      roomId: userDetails?.data?.user?.id + '_' + 'admin',
      userId: userDetails?.data?.user?.id,
    };
    socket.emit('join user chat', data);
    setMessageCounter(null);
    setShowChatBox(true);
  };
  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        style={{ backgroundColor: 'white', borderBottom: '1px solid #E0E0E0' }}
      >
        {!storedItemId ? (
          <>
            {location.pathname == '/hire-a-r8tr' ? (
              <Toolbar className={classes.topBarR8tr}>
                <div>
                  <img
                    alt="logoProfile"
                    src={logoProfile}
                    height="35"
                    width="70"
                    onClick={() => {
                      navigator('/');
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </Toolbar>
            ) : (
              <Toolbar className={classes.topBar}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginLeft: '-15px',
                  }}
                >
                  <span>
                    <IconButton className={classes.topBarContent}>
                      <Menu onClick={funcSetIsMobile} />
                    </IconButton>
                  </span>
                  <span
                    className="text-uppercase fw-bold"
                    style={{ fontSize: '20px' }}
                  >
                    {location.pathname == '/'
                      ? `${userRole} Dashboard`
                      : `${url[0] ? url[0] : ''} ${url[1] ? url[1] : ''}`}
                  </span>
                </div>
                <div className={classes.navlink_list}>
                  <div className="navlink_avatar ">
                    <img src={money} alt="money" style={{ width: '22px' }} />
                  </div>
                  {userRole == 'r8tr' ? (
                    <span>
                      <p className="navlink_list_icons_p Font_Family theme_text_color">
                        <b>
                          TOTAL EARNED
                          <br />{' '}
                          <span className="theme-color">
                            $
                            {dashboardData?.data?.totalEarned
                              ? dashboardData?.data?.totalEarned.toFixed(2)
                              : 0}
                          </span>
                        </b>
                      </p>
                    </span>
                  ) : (
                    <span>
                      <p className="navlink_list_icons_p Font_Family theme_text_color">
                        <b>
                          COMPLETED RATINGS
                          <br />{' '}
                          <span className="theme-color">
                            {dashboardData?.data?.ratingsCompleted
                              ? dashboardData?.data?.ratingsCompleted.toFixed(1)
                              : 0}
                          </span>
                        </b>
                      </p>
                    </span>
                  )}
                  <div className="navlink_avatar ">
                    <img src={star} alt="star" className="navlink_list_icons" />
                  </div>
                  {userRole == 'r8tr' ? (
                    <span>
                      <p className="navlink_list_icons_p Font_Family theme_text_color">
                        <b>
                          MY RATING
                          <br />
                          <span className="theme-color">
                            {dashboardData?.data?.currentR8trRating
                              ? dashboardData?.data?.currentR8trRating.toFixed(
                                  1
                                )
                              : '0'}
                          </span>
                        </b>
                      </p>
                    </span>
                  ) : (
                    <span>
                      <p className="navlink_list_icons_p Font_Family theme_text_color">
                        <b>
                          CURRENT RATING
                          <br />
                          <span className="theme-color">
                            {dashboardData?.data?.currentBuyerRating
                              ? dashboardData?.data?.currentBuyerRating.toFixed(
                                  1
                                )
                              : '0'}
                          </span>
                        </b>
                      </p>
                    </span>
                  )}
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popover}
                    rootClose={true}
                  >
                    <span
                      className="notification_container_span"
                      onClick={handlePopoverData}
                    >
                      {dashboardData?.data?.unSeenNotificationsCount > 0 && (
                        <span className={classes.notification_alert}>
                          <p style={{ fontSize: '10px', color: 'white' }}>
                            {dashboardData?.data?.unSeenNotificationsCount}
                          </p>
                        </span>
                      )}

                      <div className="navlink_avatar ">
                        <img
                          src={notification}
                          alt="notification"
                          className="navlink_list_icons"
                        />
                      </div>
                    </span>
                  </OverlayTrigger>
                  <span
                    className="notification_container_span"
                    onClick={() => handleChatDialog()}
                  >
                    {messageCounter > 0 && (
                      <span className={classes.notification_alert}>
                        <p style={{ fontSize: '10px', color: 'white' }}>
                          {messageCounter}
                        </p>
                      </span>
                    )}

                    <div className="navlink_avatar ">
                      <BsChatDots
                        style={{
                          fontSize: '24px',
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  </span>
                  <span>
                    <Avatar
                      alt="Semy Sharp"
                      src={userProfilePic}
                      className="navlink_avatar_profile"
                      style={{ backgroungColor: '#edeeee', cursor: 'pointer' }}
                      onClick={() => {
                        navigator('/personal-info');
                      }}
                    ></Avatar>
                  </span>
                </div>
                <div className={classes.topBarContent}>
                  <span
                    className="notification_container_span"
                    onClick={() => navigator('/notification')}
                  >
                    <span className={classes.notification_alert}>
                      <p style={{ fontSize: '10px', color: 'white' }}>
                        {dashboardData?.data?.unSeenNotificationsCount}
                      </p>
                    </span>
                    <div className="navlink_avatar ">
                      <img
                        src={notification}
                        alt="notification"
                        className="navlink_list_icons"
                      />
                    </div>
                  </span>
                  <Avatar
                    alt="Semy Sharp"
                    src={userProfilePic}
                    className="navlink_avatar_profile"
                    style={{ backgroungColor: '#edeeee', cursor: 'pointer' }}
                    onClick={() => {
                      navigator('/personal-info');
                    }}
                  ></Avatar>
                </div>
              </Toolbar>
            )}
          </>
        ) : (
          <>
            <Toolbar>
              <Box
                component="div"
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <img
                  alt="logoProfile"
                  src={logoProfile}
                  height="35"
                  width="70"
                  style={{ cursor: 'pointer' }}
                />
                <Box component="div" sx={{ color: 'black' }}>
                  <Typography variant="h4" className="text-uppercase fw-bold">
                    Get your vehicle rated
                  </Typography>
                </Box>

                <Box component="div">
                  <Button
                    onClick={handleToken}
                    style={{
                      backgroundColor: '#d40000',
                      height: '50px',
                      color: 'white',
                    }}
                    className="rounded-0 w-100"
                  >
                    <PowerSettingsNew /> &nbsp; SIGN OUT
                  </Button>
                </Box>
              </Box>
            </Toolbar>
          </>
        )}
      </AppBar>
      {showChatBox && (
        <ChatDialogs
          handleChatDialogClose={handleChatDialogClose}
          userData={userDetails?.data?.user}
          showChatBox={showChatBox}
          socket={socket}
        />
      )}
    </>
  );
};

export default TopBar;
