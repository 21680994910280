import 'react-toastify/dist/ReactToastify.css';
import './login.css';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { CallOutlined, LockOutlined } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
// import { scrappedDetails } from "../../redux/actions/buyer/BuyerJobAction";
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import { ApiUrl } from "../../ApiUrl";
// import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { setIsLoading, setIsLoggedIn } from 'redux/reducers/loginSlice';

import logo from '../../assets/images/logo.webp';
import { LogInAction } from '../../redux/actions/LogInAction';
import Loader from '../Loader/Loader';
// import NavBar from "../NavBar/NavBar";
export default function LogIn() {
  const url = new URL(window.location).searchParams;

  const itemId = url.get('itemID');

  const dispatch = useDispatch();
  const navigator = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { isLoading: loginLoading } = useSelector((state) => state.loginSlice);
  const [userRole, setUserRole] = useState('buyer');
  const toastOptions = {
    position: 'top-right',
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  };

  useEffect(() => {
    if (location?.state?.userId) {
      navigator('/signup', { state: { userId: location?.state?.userId } });
    }
  }, []);

  const handleLoginFormData = (data) => {
    dispatch(setIsLoading(true));
    if (data.status) {
      localStorage.setItem(
        'tokens',
        JSON.stringify({
          accessToken: data.data.token,
          refreshToken: data.data.refreshToken,
        })
      );
      localStorage.setItem('user', JSON.stringify(data.data));
      localStorage.setItem('userRole', userRole);
      dispatch(setIsLoading(false));
      dispatch(setIsLoggedIn(true));

      if (itemId) {
        navigator(`/ebay-hire-a-r8tr?itemID=${itemId}`);
      } else {
        navigator('/');
      }
    } else {
      dispatch(setIsLoading(false));
      toast.error(data.message, toastOptions);
    }
  };
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      password: '',
    },
    onSubmit: (value) => {
      setLoading(true);
      dispatch(LogInAction(value, handleLoginFormData));
    },
  });
  return (
    <div className="login_container">
      <Container className="login_row_container">
        <ToastContainer />

        <Row className="login_row_container">
          <Col xxl={4} xl={4} lg={4} md={5} sm={10} xs={10}>
            <div>
              <img
                src={logo}
                alt="logo"
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: '50px',
                  display: 'block',
                }}
              />
            </div>
            <form className="w-100 " onSubmit={formik.handleSubmit}>
              <div className="login-input-container">
                <i className="fa fa-user login_icon">
                  <CallOutlined className="signup_input_icon" />
                </i>
                <input
                  className="login-input-field Font_Family"
                  type="text"
                  defaultValue="+1"
                  placeholder="PHONE NUMBER"
                  id="phoneNumber"
                  {...formik.getFieldProps('phoneNumber')}
                  style={{ borderBottomLeftRadius: '0px' }}
                />
              </div>
              <div className="login-input-container">
                <i className="fa fa-user login_icon">
                  <LockOutlined className="signup_input_icon" />
                </i>
                <input
                  className="login-input-field-password Font_Family"
                  // type="password"
                  placeholder="PASSWORD"
                  type={values.showPassword ? 'text' : 'password'}
                  onChange={handlePasswordChange('password')}
                  value={values.password}
                  id="password"
                  {...formik.getFieldProps('password')}
                  style={{ borderBottomLeftRadius: '0px' }}
                />
                <i
                  className="fa fa-user login_icon_password"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {/* <LockOutlined style={{ color: "gray" }} /> */}
                  {values.showPassword ? (
                    <Visibility style={{ color: 'gray' }} />
                  ) : (
                    <VisibilityOff style={{ color: 'gray' }} />
                  )}
                </i>
              </div>
              {itemId ? (
                ''
              ) : (
                <div className="login_type_div">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      value="buyer"
                      id="userType"
                      checked={userRole == 'buyer'}
                      onChange={(e) => setUserRole(e.target.value)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      BUYER
                    </label>
                  </div>{' '}
                  &nbsp; &nbsp;
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      value="r8tr"
                      id="userType"
                      checked={userRole == 'r8tr'}
                      onChange={(e) => setUserRole(e.target.value)}
                      style={{
                        boxShadow: 'none',
                        MozBoxShadow: 'none',
                        outline: 'none',
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      R8TR
                    </label>
                  </div>
                </div>
              )}

              <button
                type="submit"
                className="btn fw-bold w-100 Font_Family"
                style={{
                  height: '45px',
                  borderRadius: '0px 25px 0px 25px',
                  backgroundColor: '#e51d29',
                  color: 'white',
                }}
              >
                <span className="d-flex justify-content-center align-content-center w-100">
                  {loading || loginLoading ? <Loader /> : <span>LOGIN</span>}
                </span>
              </button>
              <div className="forget-password-container my-4">
                <p
                  className="Font_Family text-uppercase fw-bold login_p_tage"
                  onClick={
                    !itemId
                      ? () => navigator('/get-otp')
                      : () => navigator(`/get-otp?itemID=${itemId}`)
                  }
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  <u>Forgot Password?</u>
                </p>
              </div>
              <div className="sinup-container text-center text-uppercase">
                <p className="Font_Family login_p_tage">
                  Don`t have account &nbsp;
                  <span
                    className="Font_Family theme-color text-uppercase cursor-pointer"
                    onClick={
                      !itemId
                        ? () => navigator('/signup')
                        : () => navigator(`/signup?itemID=${itemId}`)
                    }
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    <u>Sign up</u>
                  </span>
                </p>
              </div>
            </form>
          </Col>
          <Col
            xxl={6}
            lg={6}
            md={6}
            sm={0}
            xs={0}
            className="login_column_two"
          ></Col>
        </Row>
      </Container>
    </div>
  );
}
