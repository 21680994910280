import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  couponResData: {},
};

const couponVerifySlice = createSlice({
  name: 'couponVerify',
  initialState,
  reducers: {
    couponVerify: (state, action) => {
      state.couponResData = action.payload;
    },
  },
});

export const { couponVerify } = couponVerifySlice.actions;

export default couponVerifySlice.reducer;
