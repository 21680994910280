import './ServiceArea.css';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import {
  getUserData,
  updateAddresses,
} from '../../../redux/actions/UserAction';
import ProfileClickedContext from './../../../utils/context';
import Loader from './../../Loader/Loader';

const ServiceArea = () => {
  const radiusArray = [
    {
      key: '10 Miles',
      value: 10,
    },
    {
      key: '20 Miles',
      value: 20,
    },
    {
      key: '50 Miles',
      value: 50,
    },
  ];
  const location = useLocation();
  const massage = location?.state?.massage;
  const dispatch = useDispatch();
  const profileClickedContext = useContext(ProfileClickedContext);
  profileClickedContext.profileClickedHandler(true);
  const { userDetails } = useSelector((state) => state.UserReducer);
  const [serviceAreas, setServiceAreas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateDisabled, setUpdateDisabled] = useState(true);

  const toastOptions = {
    position: 'top-right',
    autoClose: 4000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  };

  useEffect(() => {
    dispatch(getUserData());
  }, []);
  useEffect(() => {
    if (massage) {
      toast.error(massage, toastOptions);
    }
  }, [massage]);

  useEffect(() => {
    if (userDetails?.data?.areas.length > 0) {
      const areas = userDetails.data.areas.map((area) => {
        return { ...area, isReadonly: true }; // Add the isReadonly property to each object
      });

      setServiceAreas(areas);
    }
  }, [userDetails]);

  const handleResponse = (data) => {
    if (data.status) {
      setLoading(false);
      toast.success(data.message, toastOptions);
    } else {
      setLoading(false);
      toast.error(data.message, toastOptions);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    let addresses = [...serviceAreas];
    let defaultAddressIndex = 0;
    for (let i = 0; i < addresses.length; i++) {
      if (!addresses[i].address1) {
        toast.error(`Empty Addresses are not allowed`, toastOptions);
        setLoading(false);
        return;
      }
      if (!addresses[i].latitude || !addresses[i].longitude) {
        try {
          let results = await geocodeByAddress(addresses[i].address1);
          let latLng = await getLatLng(results[0]);
          addresses[i].latitude = latLng.lat;
          addresses[i].longitude = latLng.lng;
        } catch (error) {
          toast.error(
            `${addresses[i].address1} is an invalid address`,
            toastOptions
          );
          setLoading(false);
          return;
        }
      }
      if (addresses[i].isDefault && addresses[i].isDefault == true) {
        defaultAddressIndex = i;
      }
    }
    addresses[defaultAddressIndex].isDefault = true;

    dispatch(updateAddresses(addresses, handleResponse));
    setUpdateDisabled(true);
  };

  const handleAddAnotherAddress = () => {
    setUpdateDisabled(false);
    let existingServiceAreas = [...serviceAreas];
    existingServiceAreas.push({
      address1: '',
      city: '',
      state: '',
      zip: '',
      radius: 10,
      isReadonly: false,
      latitude: null,
      longitude: null,
    });
    setServiceAreas(existingServiceAreas);
  };

  const handleDeleteAddress = (index) => {
    setUpdateDisabled(false);
    let existingServiceAreas = [...serviceAreas];
    existingServiceAreas.splice(index, 1);
    setServiceAreas(existingServiceAreas);
  };

  const handleEnableInput = (index) => {
    let existingServiceAreas = [...serviceAreas];
    existingServiceAreas[index].isReadonly =
      !existingServiceAreas[index].isReadonly;
    setServiceAreas(existingServiceAreas);
    let disableInput = true;
    for (let i = 0; i < existingServiceAreas.length; i++) {
      if (!existingServiceAreas[i].isReadonly) {
        disableInput = false;
        break;
      }
    }
    setUpdateDisabled(disableInput);
  };

  const handleChangeAddress = async (event, index, streetAddress) => {
    let existingServiceAreas = [...serviceAreas];
    if (streetAddress) {
      try {
        let results = await geocodeByAddress(streetAddress);
        let latLng = await getLatLng(results[0]);

        existingServiceAreas[index]['address1'] = streetAddress;
        existingServiceAreas[index]['latitude'] = latLng.lat;
        existingServiceAreas[index]['longitude'] = latLng.lng;

        for (let i = 0; i < results[0].address_components.length; i++) {
          if (
            results[0].address_components[i].types.includes(
              'administrative_area_level_1'
            )
          ) {
            existingServiceAreas[index]['state'] =
              results[0].address_components[i].long_name;
          }

          if (
            results[0].address_components[i].types.includes(
              'administrative_area_level_2'
            )
          ) {
            existingServiceAreas[index]['city'] =
              results[0].address_components[i].long_name;
          }
        }
        setServiceAreas(existingServiceAreas);
      } catch (error) {
        console.error('Error', error);
      }
    } else {
      existingServiceAreas[index][event.target.name] = event.target.value;
      setServiceAreas(existingServiceAreas);
    }
  };

  const handleChangeStreetAddress = (address, index) => {
    let existingServiceAreas = [...serviceAreas];
    existingServiceAreas[index]['address1'] = address;
    existingServiceAreas[index]['latitude'] = null;
    existingServiceAreas[index]['longitude'] = null;
    setServiceAreas(existingServiceAreas);
  };

  return (
    <div className="Font_Family theme_text_color service_Area_main_div">
      <Container>
        <ToastContainer />
        <Row className="service_Area_row_container">
          <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
            <div className="heading_service_area_div text-uppercase">
              <Typography variant="h6" component="div">
                SERVICE AREAS
              </Typography>
            </div>

            <form>
              {serviceAreas?.map((area, index) => {
                return (
                  <>
                    <div className="address_edit_div text-uppercase">
                      <span>Address # {index + 1}</span>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {area.id && (
                          <span>
                            <Button
                              className="text-uppercase"
                              style={{
                                color: '#d40000',
                                backgroundColor: '#f5f6f7',
                                borderColor: '#f5f6f7',
                                boxShadow: 'none',
                                outline: 'none',
                                fontWeight: 'bold',
                              }}
                              onClick={() => {
                                handleEnableInput(index);
                              }}
                            >
                              {area.isReadonly ? (
                                <span className="pl-2 capitalize">Edit</span>
                              ) : (
                                <span className="pl-2 capitalize">Cancel</span>
                              )}
                            </Button>
                          </span>
                        )}
                        {serviceAreas.length > 1 && (
                          <span>
                            <Button
                              className="text-uppercase"
                              style={{
                                color: '#d40000',
                                backgroundColor: '#f5f6f7',
                                borderColor: '#f5f6f7',
                                boxShadow: 'none',
                                outline: 'none',
                                fontWeight: 'bold',
                              }}
                              onClick={() => {
                                handleDeleteAddress(index);
                              }}
                            >
                              <span className="pl-2 capitalize">Delete</span>
                            </Button>
                          </span>
                        )}
                      </div>
                    </div>
                    <TableContainer key={index}>
                      <Table
                        style={{ width: '100%' }}
                        aria-label="simple table"
                      >
                        <TableHead></TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              className="text-uppercase"
                              style={{
                                fontWeight: 'bold',
                                color: '#414042',
                                borderBottom: area.isReadonly
                                  ? '1px solid #E0E0E0'
                                  : 'none',
                              }}
                            >
                              STREET ADDRESS
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: area.isReadonly
                                  ? '1px solid #E0E0E0'
                                  : 'none',
                              }}
                            >
                              <PlacesAutocomplete
                                value={area.address1}
                                onChange={(address) => {
                                  handleChangeStreetAddress(address, index);
                                }}
                                onSelect={(address) => {
                                  handleChangeAddress(null, index, address);
                                }}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <input
                                      {...getInputProps({
                                        placeholder: 'address',
                                        className:
                                          'silver_Color location-search-input input_field_service_area_information',
                                        style: {
                                          border: area.isReadonly
                                            ? 'none'
                                            : ' 1px solid #E0E0E0',
                                        },
                                      })}
                                      disabled={area.isReadonly}
                                    />
                                    <div className="silver_Color autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map(
                                        (suggestion, suggestionIndex) => {
                                          const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: '#fafafa',
                                                cursor: 'pointer',
                                              }
                                            : {
                                                backgroundColor: '#ffffff',
                                                cursor: 'pointer',
                                              };
                                          return (
                                            <div
                                              key={suggestionIndex}
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className="text-uppercase"
                              style={{
                                fontWeight: 'bold',
                                color: '#414042',
                                borderBottom: area.isReadonly
                                  ? '1px solid #E0E0E0'
                                  : 'none',
                              }}
                            >
                              CITY
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: area.isReadonly
                                  ? '1px solid #E0E0E0'
                                  : 'none',
                              }}
                            >
                              <input
                                style={{
                                  border: area.isReadonly
                                    ? 'none'
                                    : '1px solid #E0E0E0',
                                }}
                                className="silver_Color w-full input_field_service_area_information"
                                defaultValue={area.city}
                                name="city"
                                onChange={(event) => {
                                  handleChangeAddress(event, index);
                                }}
                                disabled={area.isReadonly}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className="text-uppercase"
                              style={{
                                fontWeight: 'bold',
                                color: '#414042',
                                borderBottom: area.isReadonly
                                  ? '1px solid #E0E0E0'
                                  : 'none',
                              }}
                            >
                              STATE
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: area.isReadonly
                                  ? '1px solid #E0E0E0'
                                  : 'none',
                              }}
                            >
                              <input
                                style={{
                                  border: area.isReadonly
                                    ? 'none'
                                    : '1px solid #E0E0E0',
                                }}
                                className="silver_Color w-full input_field_service_area_information"
                                defaultValue={area.state}
                                name="state"
                                onChange={(event) => {
                                  handleChangeAddress(event, index);
                                }}
                                disabled={area.isReadonly}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className="text-uppercase"
                              style={{
                                fontWeight: 'bold',
                                color: '#414042',
                                borderBottom: area.isReadonly
                                  ? '1px solid #E0E0E0'
                                  : 'none',
                              }}
                            >
                              ZIP CODE
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: area.isReadonly
                                  ? '1px solid #E0E0E0'
                                  : 'none',
                              }}
                            >
                              <input
                                style={{
                                  border: area.isReadonly
                                    ? 'none'
                                    : '1px solid #E0E0E0',
                                }}
                                className="silver_Color w-full input_field_service_area_information"
                                defaultValue={area.zip}
                                name="zip"
                                onChange={(event) => {
                                  handleChangeAddress(event, index);
                                }}
                                disabled={area.isReadonly}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className="text-uppercase"
                              style={{
                                fontWeight: 'bold',
                                color: '#414042',
                                borderBottom: area.isReadonly
                                  ? '1px solid #E0E0E0'
                                  : 'none',
                              }}
                            >
                              Service Area
                            </TableCell>
                            <TableCell
                              style={{
                                borderBottom: area.isReadonly
                                  ? '1px solid #E0E0E0'
                                  : 'none',
                              }}
                            >
                              <select
                                name="radius"
                                id="radius"
                                className="silver_Color w-100 fw-bold"
                                style={{
                                  border: area.isReadonly
                                    ? 'none'
                                    : '1px solid #E0E0E0',
                                  height: '30px',
                                }}
                                onChange={(event) => {
                                  handleChangeAddress(event, index);
                                }}
                                disabled={area.isReadonly}
                              >
                                {radiusArray.map((radius, radiusArrayIndex) => {
                                  return (
                                    <option
                                      key={radiusArrayIndex}
                                      selected={
                                        area.radius == radius.value
                                          ? true
                                          : false
                                      }
                                      value={radius.value}
                                    >
                                      {radius.key}
                                    </option>
                                  );
                                })}
                              </select>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <br></br>
                  </>
                );
              })}
              <div
                className=" w-100 text-uppercase"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  className="rounded-0 text-uppercase"
                  style={{
                    color: '#d40000',
                    backgroundColor: 'white',
                    borderColor: 'white',
                    fontWeight: 'bold',
                  }}
                  onClick={handleAddAnotherAddress}
                >
                  {serviceAreas.length > 0 ? (
                    <span className="pl-2 capitalize">ADD ANOTHER ADDRESS</span>
                  ) : (
                    <span className="pl-2 capitalize">ADD NEW ADDRESS</span>
                  )}
                </Button>
              </div>
              {serviceAreas.length > 0 && (
                <div className="my-5 text-center">
                  <Button
                    className="service_area_button text-uppercase"
                    style={{
                      backgroundColor: '#d40000',
                      borderColor: '#d40000',
                      borderRadius: '0px 15px 0px 15px',
                      height: '50px',
                    }}
                    disabled={updateDisabled}
                    onClick={handleSubmit}
                  >
                    <span className="d-flex justify-content-center align-content-center w-100 fw-bold">
                      {loading ? <Loader /> : <span>Update</span>}
                    </span>
                  </Button>
                </div>
              )}
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceArea;
