import apiProtected from 'api/apiClient';
import {
  getAllCosts as getAllCostsDispatch,
  getAllExpertiseCategories,
} from 'redux/reducers/CategoriesExpertiseReducer.js';

import { ApiUrl } from '../../ApiUrl';

export const getAllCategories = () => (dispatch) => {
  apiProtected
    .get(ApiUrl.server_url + 'v1/sub-categories/categories-and-sub-categories')
    .then((res) => {
      dispatch(getAllExpertiseCategories(res.data));
    });
};

export const getAllCosts = () => (dispatch) => {
  apiProtected.get(ApiUrl.server_url + 'v1/costs/').then((res) => {
    dispatch(getAllCostsDispatch(res.data));
  });
};
