import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  acceptedJobs: {},
};

const acceptedJobsSlice = createSlice({
  name: 'acceptedJobs',
  initialState,
  reducers: {
    setJobAccepted: (state, action) => {
      state.acceptedJobs = action.payload;
    },
  },
});

export const { setJobAccepted } = acceptedJobsSlice.actions;

export default acceptedJobsSlice.reducer;
