import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  R8tingCategoryData: [],
};

const r8tingCategoryDataSlice = createSlice({
  name: 'r8tingCategoryData',
  initialState,
  reducers: {
    setR8tingCategoryData: (state, action) => {
      state.R8tingCategoryData = [...action.payload.data];
    },
  },
});

export const { setR8tingCategoryData } = r8tingCategoryDataSlice.actions;

export default r8tingCategoryDataSlice.reducer;
