import './r8trrating.css';

import apiProtected from 'api/apiClient';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../../ApiUrl';
import { UserDashboardStatAction } from '../../../redux/actions/R8tr/UserDashboardStatAction';
import Loader from '../../Loader/Loader';
const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};
function R8trRating() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const TabsArray = [8, 7, 6, 5, 4, 3, 2, 1];
  const location = useLocation();
  const [tabsValue, setTabsValue] = useState(8);
  const [buyerRating, setBuyerRating] = useState(8);
  const jobData = location.state.jobData;
  const [loading, setLoading] = useState(false);
  const [commentOne, setCommentOne] = useState('');
  const [commentTwo, setCommentTwo] = useState('');
  const handler8tingOptionTab = (value) => {
    TabsArray.map((item) => {
      if (value == item) {
        setTabsValue(item);
      }
    });
  };
  const handleBuyerR8tingTab = (value) => {
    TabsArray.map((item) => {
      if (value == item) {
        setBuyerRating(item);
      }
    });
  };

  const handleUploadReview = () => {
    setLoading(true);
    apiProtected
      .post(`${ApiUrl.server_url}v1/user-ratings`, {
        rating: buyerRating ? buyerRating : 0,
        ratingType: 'buyer',
        appRating: tabsValue ? tabsValue : 0,
        betterAsACompanyComment: commentOne,
        improvementComment: commentTwo,
        jobId: jobData.id,
      })
      .then(() => {
        apiProtected
          .patch(
            `${ApiUrl.server_url}v1/jobs/job-completed-by-r8tr`,
            {},
            { params: { jobId: jobData.id } }
          )
          .then(() => {
            setLoading(false);
            dispatch(UserDashboardStatAction());
            toast.success('Ratings Submitted Successfully', toastOptions);
            setTimeout(() => {
              navigate('/');
            }, 2000);
          })
          .catch((error) => {
            setLoading(false);
            console.log('Error occured', error);
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log('Error occured', error);
      });
  };
  // } catch (error) {
  //   setLoading(false);
  //   console.log(error);
  //   toast.error("Error in uploading files", toastOptions);
  // }
  // setShowButton(false);

  return (
    // <div className="R8trRating_text_Row">
    <Row className="R8trRating_text_row" style={{ marginTop: '30px' }}>
      <ToastContainer />
      <Col xs={10} sm={10} md={8} lg={8} className="R8trRating_text_col">
        <div className="R8trRating_text_div">
          <p
            style={{ fontSize: '18px' }}
            className="fw-bold theme_text_color Font_Family"
          >
            YOU JUST EARNED
          </p>
          <p
            style={{ fontSize: '50px' }}
            className="fw-bold theme-color Font_Family"
          >
            ${jobData.buyerCost / 2}
          </p>
          <p
            style={{ fontSize: '16px' }}
            className=" theme_text_color Font_Family"
          >
            FOR THE FIRST FEW WEEK DURING BETA, R8TR WILL BE PAID BY CHEAK
            MAILED TO THE ADDRESS ON FILE
          </p>
        </div>
        <div className="R8trRating_tabs_div">
          <p
            style={{ fontSize: '18px' }}
            className="fw-bold theme_text_color Font_Family text-uppercase"
          >
            How Would you Rate this Buyer
          </p>
          <ul className="nav nav-pills">
            {TabsArray.length > 0 &&
              TabsArray.map((item) => (
                <li
                  className={` Font_Family  R8trRating__titeltabs fw-bold ${
                    buyerRating == item ? 'R8trRating__active' : ''
                  }`}
                  style={{ fontSize: '18px' }}
                  key={item.id}
                  onClick={() => {
                    handleBuyerR8tingTab(item);
                  }}
                >
                  <a data-toggle="pill">{item}</a>
                </li>
              ))}
          </ul>
        </div>
        <div className="R8trRating_tabs_div mt-4">
          <p
            style={{ fontSize: '18px' }}
            className="fw-bold theme_text_color Font_Family text-uppercase"
          >
            How Would you Rate R8tr App
          </p>
          <ul className="nav nav-pills">
            {TabsArray.length > 0 &&
              TabsArray.map((item) => (
                <li
                  className={` Font_Family  R8trRating__titeltabs fw-bold ${
                    tabsValue == item ? 'R8trRating__active' : ''
                  }`}
                  style={{ fontSize: '18px' }}
                  key={item.id}
                  onClick={() => {
                    handler8tingOptionTab(item);
                  }}
                >
                  <a data-toggle="pill">{item}</a>
                </li>
              ))}
          </ul>
        </div>
      </Col>
      <Row className="R8trRating_inner_row" style={{ marginTop: '30px' }}>
        <Col xs={10} sm={10} md={4} lg={4} className="R8trRating_text_col">
          <div style={{ width: '100%' }}>
            <p
              style={{ fontSize: '16px' }}
              className="fw-bold theme_text_color Font_Family"
            >
              WHAT CAN WE DO BETTER AS COMPANY
            </p>
            <textarea
              type="text"
              className="textArea"
              placeholder="TYPE YOUR MESSAGE HERE..."
              value={commentOne}
              style={{ width: '100%', height: '100px', resize: 'none' }}
              onChange={(e) => setCommentOne(e.target.value)}
            ></textarea>
          </div>
        </Col>
        <Col xs={10} sm={10} md={4} lg={4} className="R8trRating_text_col">
          <div style={{ width: '100%' }}>
            <p
              style={{ fontSize: '16px' }}
              className="fw-bold theme_text_color Font_Family"
            >
              ANY SUGGESTION TO IMPROVE R8TR
            </p>
            <textarea
              type="text"
              className="textArea"
              value={commentTwo}
              placeholder="TYPE YOUR MESSAGE HERE..."
              style={{ width: '100%', height: '100px', resize: 'none' }}
              onChange={(e) => setCommentTwo(e.target.value)}
            ></textarea>
          </div>
        </Col>
      </Row>

      <button
        className="btn Font_Family"
        style={{
          height: '45px',
          borderRadius: '0px 15px 0px 15px',
          backgroundColor: '#e61d2a',
          color: 'white',
          width: '20%',
        }}
        onClick={() => {
          handleUploadReview();
        }}
      >
        <span className="d-flex justify-content-center align-content-center w-100">
          {loading ? <Loader /> : <span> SUBMIT</span>}
        </span>
      </button>
    </Row>
  );
}

export default R8trRating;
