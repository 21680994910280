import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  acceptedJobs: {},
};

const cancelledJobsSlice = createSlice({
  name: 'cancelledJobs',
  initialState,
  reducers: {
    singleJobCancelled: (state, action) => {
      state.acceptedJobs = action.payload;
    },
  },
});

export const { singleJobCancelled } = cancelledJobsSlice.actions;

export default cancelledJobsSlice.reducer;
