import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  NotificationData: [],
  limitedNotificationsData: [],
};

const r8trNotificationSlice = createSlice({
  name: 'r8trNotification',
  initialState,
  reducers: {
    setNotificationData: (state, action) => {
      state.NotificationData = [...action.payload.data];
    },
    setLimitedNotificationData: (state, action) => {
      state.limitedNotificationsData = [...action.payload.data];
    },
  },
});

export const { setNotificationData, setLimitedNotificationData } =
  r8trNotificationSlice.actions;

export default r8trNotificationSlice.reducer;
