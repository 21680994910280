import apiProtected from 'api/apiClient';
import { singleJobCancelled } from 'redux/reducers/R8tr/CancelledJobsReducer';

import { ApiUrl } from '../../../ApiUrl';

export const CancelledJobsAction =
  (id, handleJobsCancelledRes) => (dispatch) => {
    apiProtected
      .patch(
        `${ApiUrl.server_url}v1/jobs/job-cancelled-by-r8tr`,
        {},
        { params: { jobId: id } }
      )
      .then((res) => {
        handleJobsCancelledRes(res.data.message);
        dispatch(singleJobCancelled(res.data));
      });
  };
