import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import LeftBar from "../bars/leftBar/LeftBar";
import TopBar from "../bars/topBar/TopBar";
import { LayoutStyle } from "./LayoutStyle";

const Layout = (props) => {
  const ebayUrl = new URL(window.location).searchParams;

  const storedItemId = ebayUrl.get("itemID");

  const location = useLocation();
  const classes = LayoutStyle();
  const [isMobile, setIsMobile] = useState(false);
  const funcSetIsMobile = () => {
    setIsMobile(!isMobile);
  };
  return (
    <div className={classes.root}>
      {!storedItemId ? (
        <>
          {location.pathname == "/hire-a-r8tr" ? (
            <TopBar funcSetIsMobile={funcSetIsMobile} />
          ) : (
            <LeftBar isMobile={isMobile} funcSetIsMobile={funcSetIsMobile} />
          )}
          <TopBar funcSetIsMobile={funcSetIsMobile} socket={props?.socket} />
          <main className={classes.main}>
            <div className={classes.topBarWidth} />
            {props.children}
          </main>
        </>
      ) : (
        <>
          {console.log("console in stored item condition")}
          <TopBar funcSetIsMobile={funcSetIsMobile} socket={props?.socket} />
          <main className={classes.main}>
            <div className={classes.topBarWidth} />
            {props.children}
          </main>
        </>
      )}
    </div>
  );
};

export default Layout;
