import "./termsandconditions.css";

import React from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo.webp";
function Navbar() {
  const navigate = useNavigate();
  return (
    <div>
      <nav
        className="navbar navbar-light bg-light"
        style={{ position: "sticky" }}
      >
        <img
          src={logo}
          alt="logo"
          height="35"
          width="100"
          style={{
            paddingLeft: "30px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
        {/* <button
          type="submit"
          className="btn fw-bold Font_Family terms_condition_nav_button"
          style={{
            height: "40px",
            // borderRadius: "0px 25px 0px 25px",
            backgroundColor: "#e51d29",
            color: "white",
            // width: "20%",
            marginRight: "30px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/login")}
        >
          <span className="d-flex justify-content-center align-content-center w-100">
            LOGIN
          </span>
        </button> */}
      </nav>
    </div>
  );
}

export default Navbar;
