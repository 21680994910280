import apiProtected from 'api/apiClient';
import { setDefaultAddress } from 'redux/reducers/R8tr/DefaultAddressReducer';

import { ApiUrl } from '../../../ApiUrl';
import { getUserData } from '../UserAction';

export const DefaultAddressAction =
  (id, handleDefaultAddressUpdate) => (dispatch) => {
    apiProtected
      .patch(
        `${ApiUrl.server_url}v1/addresses/make-address-default`,
        {},
        {
          params: { id },
        }
      )
      .then((res) => {
        dispatch(getUserData());
        handleDefaultAddressUpdate();
        dispatch(setDefaultAddress(res));
      });
  };
