import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  LoginData: {},
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginData: (state, action) => {
      state.LoginData = action.payload;
    },
  },
});

export const { setLoginData } = loginSlice.actions;

export default loginSlice.reducer;
