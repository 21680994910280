import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentJobsData: [],
};

const currentJobsSlice = createSlice({
  name: 'currentJobs',
  initialState,
  reducers: {
    setCurrentJobs: (state, action) => {
      state.currentJobsData = [...action.payload.data];
    },
  },
});

export const { setCurrentJobs } = currentJobsSlice.actions;

export default currentJobsSlice.reducer;
