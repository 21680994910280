import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  availableJobsData: [],
};

const availableJobsSlice = createSlice({
  name: 'availableJobs',
  initialState,
  reducers: {
    setAvailableJobs: (state, action) => {
      state.availableJobsData = [...action.payload.data];
    },
  },
});

export const { setAvailableJobs } = availableJobsSlice.actions;

export default availableJobsSlice.reducer;
