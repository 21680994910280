import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'en',
  profileClicked: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateLanguage: (state, action) => {
      state.language = action.payload;
    },
    clickProfile: (state, action) => {
      state.profileClicked = action.payload;
    },
  },
});

export const { updateLanguage, clickProfile } = settingsSlice.actions;

export default settingsSlice.reducer;
