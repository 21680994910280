import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import Navbar from "./Navbar";
function TermsAndConditions() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      background: "#d40000",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      border: "none !important",
      fontSize: 14,
    },
    [`&.${tableCellClasses.TableCell}`]: {
      width: "500px",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F9FAFB ",
    },
  }));
  return (
    <div style={{ width: "100%" }}>
      <Navbar />
      <Container style={{ width: "100%" }}>
        <Row className="d-flex justify-content-center align-items-center mt-5">
          <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12}>
            <p
              className="text-uppercase Font_Family theme_text_color fw-bold"
              style={{ fontSize: "20px" }}
            >
              Terms of Service
            </p>
            These Terms and Conditions (“<b>Terms and Conditions</b>”)1, as set
            forth herein and which may be amended from time to time, shall
            govern the relationship and become a part of any and all
            transactions hereafter undertaken by, between, and among the person
            s or entities using the Auction’s services (“
            <b>Dealer</b>”), and LEGACYAA.COM, its U.S. auction subsidiaries
            (collectively, “<b>LAA</b>”) (LEGACYAA.COM, collectively “
            <b>LegacyAA.com</b>” or the “<b>Auction</b>”). These Terms and
            Conditions and any amendments to them will be effective immediately
            upon the Effective Date. Dealer’s access or use of Auction’s
            services following the Effective Date will constitute full
            acceptance of the then current Terms and Conditions. LegacyAA.com
            complies with National Auto Auction Association’s (“NAAA”) policies,
            which are hereby incorporated herein by reference, except as set
            forth in Section 15 below.
          </Col>
          <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12}>
            <p
              className="text-uppercase Font_Family theme_text_color fw-bold mt-4"
              style={{ fontSize: "20px" }}
            >
              1. GENERAL TERMS
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                1. Scope and Purpose.
              </span>
              LegacyAA.com conducts periodic in-person and/or internet-based
              remote auction sales, both in real time and virtually
              (collectively “<b>Sales</b>”). LegacyAA.com’s Sales offer
              principally, but not exclusively, transportation-related consigned
              goods, including automobiles, motorcycles, recreational vehicles,
              power equipment, vessels, and recreational watercraft
              (collectively, “<b>Vehicles</b>”). Dealer wishes to attend and/or
              otherwise participate in the Sales (via its “
              <b>Authorized Representative(s)</b>”, as defined below), and
              voluntarily agrees to these Terms and Conditions in consideration
              of the LegacyAA.com permitting such participation. Breach of these
              Terms and Conditions may result in the assessment of penalties,
              and/or temporary or permanent suspension of auction privileges.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                2. Customer’ s Eligibility.
              </span>{" "}
              Dealer warrants that Dealer is a licensed retail or wholesale
              dealer of one or more types of Vehicle(s) (collectively, “
              <b>Dealer</b>”) and/or a corporation or other legal entity that is
              otherwise legally eligible to purchase or sell Vehicles at
              wholesale auto auctions. Dealer agrees that these Terms and
              Conditions shall apply to all transactions by which Dealer sells
              consigned Vehicles as “Seller”, or in which Dealer purchases
              consigned Vehicles as “Buyer”. Dealer acknowledges that its
              participation as Buyer or Seller, or to participate in certain
              Sales events, may be limited by applicable law, the scope of the
              Dealer’s license, or other rules of eligibility, as may be
              determined in LegacyAA.com’s sole discretion.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                3. Seller’s Registration.
              </span>{" "}
              In order to participate in LegacyAA.com’s auctions, Seller shall
              complete a registration package and/or consignment agreement. For
              internet sales, Seller may also need to register through the
              LegacyAA.com’s online system in order to be authorized to sell
              Vehicles at all LegacyAA.com’s internet sales and to receive a
              username and password.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                4. Sharing of Auction Username/Password
              </span>{" "}
              Sharing or lending username and password is strictly prohibited.
              Dealer is responsible for maintaining the confidentiality and
              security of its username and password. Dealer will be liable and
              responsible for all actions, omissions, failures to act, and/or
              transactions conducted in Dealer’s name with any Auction Card
              and/or username or password issued to Dealer or any of its
              Authorized Representatives, regardless of whether such action,
              omission, failure to act, and/or transaction was actually approved
              or authorized by Dealer. Please immediately notify LegacyAA.com in
              writing upon the discovery of any unauthorized use of username and
              password.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                5. Authorized Representatives/Authorized Users.
              </span>{" "}
              Dealer authorizes the person(s) designated in the Application and
              other document as “<b>Authorized Representative(s)</b>” or “
              <b>Authorized User(s)</b>” to take or perform the following
              actions with respect to Vehicles: (a) purchase and/or sell
              Vehicles on behalf of Dealer including complete and execute papers
              conveying title, including endorsement of the certificate of
              title, odometer statements, bills of sale and other similar
              documents in connecti on with the sale of all Vehicles, and/or (b)
              complete and execute on behalf of Dealer’s checks and/or drafts in
              connection with the purchase or sale of Vehicles. The authority of
              the Authorized Representatives shall continue in full force and
              effect until terminated by Dealer. Attempted oral revocation,
              alteration, or naming of additional Authorized Representatives
              shall be of no effect whatsoever. Dealer guarantees, as principal,
              all transactions made at the Auction by its Authorized
              Representatives, irrespective of any dispute regarding the actual
              authority to do the specific transaction. LegacyAA.com, at its
              sole discretion, reserves the right not to do business with any
              Dealer and/or Authorized Representative.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                6. Changes to Terms and Conditions.
              </span>{" "}
              LegacyAA.com, at its sole discretion, may amend these Terms and
              Conditions and other Auction Rules (“
              <b>Auction Policies</b>”) without sending notice to Dealers. The
              amended Auction Policies will be effective immediately upon
              posting to the LegacyAA.com’s website, username and password
              following such amendment will constitute full acceptance of any
              such changes. Notwithstanding the date executed by Dealer, these
              Terms and Conditions are effective on any transaction that occurs
              on or after the Effective Date.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                7. Electronic Signature.
              </span>{" "}
              Dealer will permit LegacyAA.com to capture or otherwise acquire
              Dealer’s signature in digital or electronic form, and specifically
              authorizes LegacyAA.com to apply the Dealer’s digital or
              electronic signature to Auction Sale documents.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                8. LegacyAA.com as Consignee.
              </span>{" "}
              LegacyAA.com is a service company and generally does not take
              title to, purchase or sell Vehicles. Rather, LegacyAA.com
              facilitates the exchange of Vehicles between Seller and Buyer at
              the Auction. All Vehicles offered for sale at Auction by Seller
              are deemed consigned and are offered for sale by LegacyAA.com on
              Seller’s behalf. LegacyAA.com, at its sole discretion, reserves
              the right to refuse to accept or to remove from an Auction any
              Vehicles to be offered for sale. In any Vehicle purchase
              transaction, LegacyAA.com shall not be deemed or considered the
              Vehicle’s Seller under any circumstances, except where
              LegacyAA.com has specifically identified itself as Seller on the
              sale contract. LegacyAA.com otherwise is not a party to any sale
              contract, but shall be deemed a third party beneficiary of such
              contract, entitled to the parties’ performance and to seek legal
              remedies for the parties’ breach.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                9. Service Fees.
              </span>{" "}
              Dealer agrees to pay all service fees and charges of Auction for
              services rendered by Auction, including but not limited to,
              penalties for failure to comply with various provisions, or
              certain convenience features, each as applicable (“
              <b>Fees</b>”). LegacyAA.com, in its sole discretion and at any
              time, may add, delete, or change some or all of the Fees, as
              applicable. Also, LegacyAA.com reserves the right to and may have
              arrangements with certain Dealers that result in reduced Fees, the
              payment of discounts/rebates associated with the sales of
              Vehicles, and/or marketing assessments in the form of premium
              buyer fees. LegacyAA.com’s fee is $499 regardless of the sell
              price of the Vehicle and payable by Dealer in connection with the
              transaction is the “<b>Total Payment Amount.</b>”
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                10. Payment Terms
              </span>{" "}
              Total Payment Amount will be deducted from the 10% customer’s
              deposit and remaining balance will be send to Dealer within 7
              business days of sale, the “<b>Payment Due Date</b>”.
            </p>
            <p
              className="text-uppercase Font_Family theme_text_color fw-bold"
              style={{ fontSize: "20px" }}
            >
              2. Arbitration.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                1. a. Policies.
              </span>{" "}
              com offers an arbitration service which is governed by the
              policies and procedures in this section (the “Auction Arbitration
              Policy”) to resolve disputes between Buyers and Sellers. Our
              process is designed to be fair, impartial, quick, and economical.
              The Auction Arbitration Policy generally follows the National Auto
              Auction Association’s Arbitration
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                b. Definitions.
              </span>{" "}
              <ul>
                <li>
                  “<b>Arbitrator</b>” means the LegacyAA.com employee(s)
                  responsible for adjudicating disputes and managing the dispute
                  resolution process contemplated in this Policy.
                </li>
                <li>
                  “<b>Claim</b>” means a claim submitted by Buyer for
                  arbitration.
                </li>
                <li>
                  “<b>Online</b>” has the same meaning as set out in the NAAA
                  Arbitration Policy.
                </li>
                <li>
                  “<b>Off-site Sale</b>” means an Online sale.
                </li>
                <li>
                  <b>“Policy” or “Policies”</b> mean the Auction Arbitration
                  Policy, NAAA Arbitration Policy and any applicable Seller
                  Specific
                </li>
                <li>
                  “<b>Vehicle Listing Category</b>” means the category under
                  which an Online Vehicle (excluding LiveBlock®) is listed for
                  sale as set out below.
                </li>
              </ul>
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Seller Obligations.
              </span>{" "}
              It is Seller’s obligation to fairly represent its Vehicle and to
              correct any errors made regarding disclosed conditions. Seller is
              responsible for the accuracy and completeness of all disclosures
              regardless of whether Seller has relied on third party resources
              (e.g. inspection company, vehicle listing service, electronic data
              vehicle history report, etc).
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Disclosure Standards by Vehicle Listing Category for Online
                Sales.
              </span>{" "}
              Seller is responsible for designating the appropriate Vehicle
              Listing Category and ensuring that the Vehicle satisfies the
              requirements of the category selected. If a seller fails to
              designate a Vehicle Listing Category, it will be offered for sale
              under the Standard Category.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Online Photographs.
              </span>{" "}
              Photographs must accurately and fairly represent the condition of
              the Vehicle. Photographs of damage or other conditions that could
              materially affect the value of a Vehicle should be included.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Arbitration Period.
              </span>{" "}
              The arbitration periods established under the NAAA Arbitration
              Policy apply to all Vehicles sold through an LegacyAA.com channel
              unless otherwise stated.
            </p>
            <p
              className="text-uppercase Font_Family theme_text_color fw-bold"
              style={{ fontSize: "20px" }}
            >
              Arbitration Process for All Transactions.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Payment.
              </span>{" "}
              Initiating the arbitration process does not relieve Buyer of its
              payment obligations. Failure to pay may result in loss of
              arbitration rights and/or temporary or permanent suspension of
              Auction privileges.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Start Claim
              </span>{" "}
              Start Claim in manner indicated for type of sale.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Decision
              </span>{" "}
              After reviewing the Claim, Arbitrator will decide whether the
              Claim is valid. Before deciding what the remedy should be,
              Arbitrator may attempt to facilitate a resolution between Buyer
              and Selle If agreement cannot be reached, LegacyAA.com will decide
              the appropriate remedy, if any.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Arbitration Award.
              </span>{" "}
              It is understood that, as Arbitrator, com will grant any remedy or
              relief that it considers fair and reasonable in the circumstances,
              including but not limited to: cancellation of sale and full refund
              of purchase price, partial refund of purchase price, repair of
              defect at Seller’s expense, repair of defect with the costs
              apportioned between Buyer and Seller, partial or full
              reimbursement of transportation, reconditioning and/or repair
              costs. All amounts that the Arbitrator determines should be paid
              as a result of the Claim are referred to as the “ Award Amount”.
              Seller must pay the Award Amount within the time limits imposed
              under this Policy
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Payment of Award Amount by Seller.
              </span>{" "}
              Seller agrees to pay Award Amount on or before three (3) business
              days after LegacyAA.com notifies Buyer and Seller of Arbitrator’s
              final decision. If Seller fails to pay the Award Amount within the
              time limit, LegacyAA.com reserves the right to charge Seller a
              late payment charge and/or to offset and deduct the Award Amount
              from any amounts payable by LegacyAA.com to Seller.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Payment of Award Amount to Buyer
              </span>{" "}
              LegacyAA.com may withhold (or offset) all or part of the Award
              Amount from Dealer if (i) transferable title is not returned (when
              applicable), (ii) Dealer has unresolved past due amount payable to
              LegacyAA.com.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">Returns</span>{" "}
              If the Arbitrator has determined that the sale should be cancelled
              and the Vehicle returned, Buyer will follow the return procedure
              indicated for the type of sale.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Finality.{" "}
              </span>{" "}
              The decision of the Arbitrator is final and binding on Buyer and
              Seller. By doing business with LegacyAA.com, Buyer and Seller
              hereby appoint LegacyAA.com to serve as Arbitrator and empower it
              to render a final, binding decision in settlement of all Claims
              submitted for arbitration. Upon payment of the Award Amount and/or
              otherwise implementing the Arbitrator’s decision, Buyer and Seller
              are deemed to release each other from all claims and demands
              regarding the matters referred to Arbitration.
            </p>
            <p
              className="text-uppercase Font_Family theme_text_color fw-bold"
              style={{ fontSize: "20px" }}
            >
              2. Arbitration Process for Online Sales.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Start Arbitration Claim.{" "}
              </span>{" "}
              Before starting a Claim, Buyer must pay LegacyAA.com all amounts
              owing including any transportation costs. Buyer starts arbitration
              Claim by submitting appropriate information and documentation
              online to LegacyAA.com arbitration department before the expiry of
              the arbitration period. Buyer submits the following information:
              Buyer’s name and telephone number; contact person and telephone
              number; Vehicle VIN, model and year; Delivery Time; and a
              description of the issue(s) to be arbitrated.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Submit Supporting Documentation
              </span>{" "}
              No later than 2 business days after starting the Claim, Buyer is
              required to submit supporting documentation, including but not
              limited to: (i) the original Online description of the Vehicle
              (condition report, Vehicle detail page, etc.) (ii) digital
              photographs of the defect(s) underlying the Claim, (iii) wholesale
              (not retail) repair estimates from an independent and reputable
              source; and (iv) Bill of Lading, transport release form, or other
              form of delivery receipt from transportation company, if
              applicable.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Buyer’s Obligations{" "}
              </span>{" "}
              Buyer will not use any Vehicle that is subject to an arbitration
              Claim. Other than for test-driving purposes (not to exceed 20
              miles) Buyer may lose right to make or continue a Claim if Buyer
              drives Vehicle. Pending a final decision on the Claim, Buyer must,
              at its own expense, take reasonable steps to care for, preserve,
              secure and store the Vehicle until Vehicle is properly returned.
              Buyer is not entitled to charge any parking, marshalling or other
              fees in connection with its preservation of the Vehicle. Buyer
              will be charged the cost of damage repair and/or assessed a
              penalty fee for any breach of this provision.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">Returns</span>{" "}
              If the sale is cancelled, Buyer and Seller will follow the
              instructions provided by the Arbitrator regarding arrangements for
              pick-up and delivery of the Vehicle. Seller will be responsible
              for all Vehicle return transportation costs and, if so determined
              by Arbitrator, Buyer’s transportation costs.
            </p>
            <p
              className="text-uppercase Font_Family theme_text_color fw-bold"
              style={{ fontSize: "20px" }}
            >
              Additional Terms and Conditions.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              LegacyAA.com reserves the right to interpret, waive or vary any
              provision of these Policies if, in its sole discretion,
              LegacyAA.com considers it fair and reasonable to do so in the
              circumstances.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              If the sale is cancelled due to Seller’s failure to disclose one
              of the conditions that permits extended arbitration under the NAAA
              Arbitration Policy, Seller will re-pay to Buyer the full purchase
              price, all Buyer fees, plus any other amount LegacyAA.com, in its
              discretion, determines to be fair and reasonable compensation to
              Buyer. Auction reserves the right to assess additional fees
              against Seller or Buyer that LegacyAA.com, in its discretion,
              determines to be fair and reasonable in the circumstances.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              LegacyAA.com reserves the right to deny or limit arbitration
              rights if Vehicle has been transported to a country different than
              the country of the Grounding Location.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Warranties Regarding Title.{" "}
              </span>{" "}
              Dealer warrants, represents and guarantees possession and
              conveyance of a certificate of title, properly executed, valid in
              the state where the transaction is occurring and clear of all
              liens and encumbrances (except current year DMV fees in
              California), and Dealer warrants and will defend the title against
              the claims and demands of all person whatsoever. Dealer further
              acknowledges that with respect to purchased Vehicles, LegacyAA.com
              has assumed no responsibility to investigate Seller’s title or to
              otherwise identify defects in Seller’s title or title documents,
              and makes no warranty whatsoever regarding title or title
              documents.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                {" "}
                Vehicle Identification Numbers.{" "}
              </span>{" "}
              No Vehicles will be offered for sale without a proper Vehicle
              identification number plate. LegacyAA.com reserves the right to
              refuse to list/sell any Vehicle on which the Vehicle
              identification number plate ap pears marred or in any way altered
              or any Vehicle not originally manufactured for sale in the United
              States (European or Canadian conversions). Those vehicles having a
              reassigned VIN plate by the State in place of the original VIN
              plate must be announced or will be subject to sale cancellation or
              Buyer return.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                {" "}
                No Warranties by LegacyAA.com
              </span>{" "}
              LegacyAA.com is not responsible for, nor does it represent or
              warrant (a) the accuracy of odometer mileage, (b) the information
              contained in the odometer mileage statement, (c) warranties of
              title, merchantabilit y or fitness for a particular purpose, (d)
              license plates, (e) Vehicle service or maintenance history, (f) in
              formation contained in Vehicle history reports, (g) Vehicle
              condition, (h) year the Vehicle was manufactured, (i) the accuracy
              of any market informa tion, (j) mileage and other information
              printed on the windows, (k) the description of the Vehicle, (l)
              equi pment and options for the Vehicle, (m) availability or
              validity of factory warranty, (n) fitness of Vehicle for export,
              and (o) any and all representat ions and warranties made by or on
              behalf of the Seller. Dealer is responsible to disclose, announce
              all information relative to a Vehicle’s condition prior to making
              a list/sell.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                {" "}
                Routed Export Transactions.{" "}
              </span>{" "}
              Any and all vehicle purchases that become export transactions
              shall be “routed export transactions” under section 30.3(e) of the
              Foreign Trade Regulations (15 C.F.R. § 30.3(e)), in which the
              Buyer is acting as the Foreign Principal Party in Interest
              (“FPPI“). As the FPPI, the Buyer will authorize a U.S. forwarding
              agent to facilitate the export of such vehicles from the United
              States and prepare and file any required Electronic Export
              Information (“EEI”). The Buyer or its forwarding agent shall
              provide LegacyAA.com with a copy of this authorization if
              requested by LegacyAA.com pursuant to section 30.3(e)(2) (15
              C.F.R. § 30.3(e)(2)).FPPI agrees that neither LegacyAA.com nor the
              providers who sell vehicles through the LegacyAA.com ’s auction
              facilities will be the exporter under the Export Administration
              Regulations (“EAR”) in such routed export transactions. The FPPI
              also acknowledges that vehicles purchased through the
              LegacyAA.com’s auction facilities could, potentially, be s ubject
              to export licensing requirements. The FPPI agrees to undertake,
              with respect to vehicles purchased through the LegacyAA.com’s
              auction facilities, the following responsibilities: (1) to
              determine any applicable export license requirements; (2) t o
              obtain any required export license and/or any other required
              export authorization; (3) to carry out any customs formalities for
              the expo rt of the vehicles; and (4) to authorize the U.S.
              Forwarding Agent, who will be the exporter for EAR purposes, to
              act on behalf of the FPPI in connection with the exportation of
              such vehicles and to prepare and file the EEI and any required
              export license applications.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Claim Indemnity.{" "}
              </span>{" "}
              Dealer shall indemnify, defend and hold harmless LegacyAA.com, its
              parent company, subsidiaries, affiliates, directors, officers,
              agents, employees, representatives, and as necessary, other
              Dealers, from and against any liability, loss, damage, cost,
              expense, claim, suit or demand, including, without limitation,
              attorneys’ fees and ot her legal costs, resulting from, arising
              out of or connected, directly or indirectly, with any claim of
              breach of warranty or by a breach by Dealer of any of these Terms
              and Conditions, including, but not limited to, all claims,
              allegations, and demands whatsoever challenging the validity of
              Seller’s title or title documents.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Records/Compliance with Laws.
              </span>{" "}
              Dealer will maintain complete and accurate records concerning all
              aspects of any Transaction. All business practices, acts and
              operations of Dealer in connection with the Auction will be in
              compliance with all applicable federal, state and local laws and
              regulations.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">Returns</span>{" "}
              Dealer certifies that it holds the appropriate documentation
              issued by the sales tax authority of the state or province, or
              locality of its business, if necessary, which exempts Dealer from
              the payment of sales tax. In the event that the property is used
              for any purpose other than for resale, Dealer will pay directly to
              the proper taxing authorities such sale or use tax as may then
              accrue and be due and payable.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Representations Regarding Taxes.{" "}
              </span>{" "}
              If the Arbitrator has determined that the sale should be cancelled
              and the Vehicle returned, Buyer will follow the return procedure
              indicated for the type of sale.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Transfer of Legal Ownership from Seller.
              </span>{" "}
              Notwithstanding any language in these Terms and Conditions and
              subject to Seller’s obligations with respect to any arbitrated
              Vehicle, both parties have entered into an irrevocable contract of
              sale of a vehicle at the conclusion of the Auction sale, and
              Seller relinquishes legal ownership of the Vehicle at the earlier
              of Buyer taking possession of the Vehicle, regardless of whether
              title documents are provided to Buyer at that time.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">Returns</span>{" "}
              If Dealer has consigned a Vehicle for sale at the Auction and it
              sells outside of LegacyAA.com, it is Dealer’s sole responsibility
              to notify Auction promptly of the Vehicle’s prior sale and to
              request that the Vehicle be pulled from any Auction sale and
              on-line listing. If Seller fails to timely notify Auction,
              resulting in the Vehicle’s sale by LegacyAA.com (a “double sale”),
              Seller shall be solely responsible for a double sale fee along
              with any and all resulting losses from the double sale, including
              but not limited to all fees owing to LegacyAA.com arising from
              LegacyAA.com’s sale of the Vehicle (including buyer fees, off-site
              Vehicle sale proceeds if already paid and reasonable attorney
              fees), and all other incidental or consequential damages suffered
              by LegacyAA.com, Buyer.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Outside of Auction Sales
              </span>{" "}
              If the Arbitrator has determined that the sale should be cancelled
              and the Vehicle returned, Buyer will follow the return procedure
              indicated for the type of sale.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Buyer Vehicle Condition Obligations.
              </span>{" "}
              Based upon the delivery method of the Vehicle, the Buyer’s Vehicle
              condition obligations
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center align-items-center mt-5">
          <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12}>
            <TableContainer
              component={Paper}
              sx={{ borderRadius: "15px !important" }}
            >
              <Table
                sx={{
                  minWidth: "100%",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      align="left"
                      sx={{ borderTopLeftRadius: "15px", minWidth: "250px" }}
                    >
                      Delivery Method
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        minWidth: "250px",
                      }}
                    >
                      Inspection to be Conducted by the Buyer or Buyer Agent
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        minWidth: "250px",
                      }}
                    >
                      Time Inspection
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        minWidth: "250px",
                      }}
                    >
                      Reporting Discrepancies
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Dealer Arranged Transportantion
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Vistual Inspection for exterior damage and missing
                      equipment
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {" "}
                      When Buyer Agent take divery of vehicle from Grounding
                      location
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Buyer agent must note discrepancies on Bill of Lading
                      which signed by an authorize representation the Grounding
                      location and must reported on or before the Arbitration
                      Timefram
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left">
                      {" "}
                      When Buyer Agent take divery of vehicle from carrier
                      Delivery Time
                    </StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left">
                      Buyer agent must note discrepancies on Bill of Lading
                      which signed by carrier Such discrepancies must reported
                      LegacyAA.com on or before Transportation Damage Claim
                      Deadline defined below
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Vehicle Driven or Towed by Buyer (Vehicle transported 50
                      miles or less )or vehicle not Driven
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Vistual Inspection for exterior damage and missing
                      equipment
                      <br />
                      Inspection of dash lights <br />
                      Inspect to confirm odometer reading
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Vehicle Pick Up Time
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Buyer agent must note discrepancies on Bill of Lading
                      which signed by an agent and Ground location
                      representative
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      Vehicle Drive or Towed by Buyer (Vehicle transported more
                      than 50 miles)
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Visual inspection for exterior damage and missing
                      equipment
                      <br />
                      Inspection for mechanical defects
                      <br />
                      Inspection of dash lights
                      <br />
                      Inspect to confirm odometer reading
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Vehicle Pick Up Time <br />
                      Inspection of dash lights <br />
                      Inspect to confirm odometer reading
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Buyer agent must note discrepancies on Bill of Lading
                      which signed by Transporter and Ground location
                      representative
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center align-items-center mt-5">
          <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12}>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Price Boosting.
              </span>{" "}
              Price boosting (aka “shilling”), or any other activity in which a
              Dealer on its own or in concert with others attempts to
              artificially inflate or decrease the selling price of a Vehicle,
              is strictly prohibited. Neither Dealer nor any of its Authorized
              Users, or agents may directly or indirectly bid on any Vehicles
              consigned by Dealer to the Auction for the purposes of attempting
              to increase the sale price. “Self dealing” at the auction is also
              strictly prohibited. For purpose of this Agreement, “self-dealing”
              means activities by any person or entity selling or purchasing at
              auction in which both the seller and buyer are the same or
              substantially-related entities, and/or share Authorized
              Representatives or principals, where the Auction determines in its
              sole discretion that the intent and/or the result of such dealing
              is an artificial manipulation of the auction process to create an
              unfair advantage or disadvantage or to impose undue risk upon the
              LegacyAA.com or others. Notwithstanding the prohibitions set forth
              in this section, Dealer understands that such activity by others
              is beyond the control of Auction, and Auction assumes no duty to
              prevent or discover such activity, and is not responsible to Buyer
              or Seller for such activity by third parties. In the event Dealer
              and/or its representatives are found to be engaged in such
              activities, LegacyAA.com, in its sole discretion, may suspend or
              permanently revoke Dealer’s purchasing/selling privileges in the
              Auction regardless of whether Dealer benefited from such
              activities.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Market Guide.
              </span>{" "}
              Any and all materials contained in the LegacyAA.com’s market guide
              are provided to Dealer and buyer as a courtesy for informational
              purposes only and are not intended to convey a warranty of any
              kind, express or implied, including warranty of merchantability or
              fitness for a particular purpose. Clerical errors by the Auction
              in such market guides shall not form the basis of any price
              adjustment or rescission of any sale unless the error is material,
              and subject to all other applicable arbitration guidelines. A
              clerical error, even if material, will not permit Buyer’s
              arbitration or rescission on a vehicle sold “As Is”, unless the
              error resulted in the exclusion or incorrect disclosure of
              information otherwise required to be made under applicable NAAA
              policy, and then only if brought to Auction’s attention during the
              applicable arbitration period. Buyer acknowledges that any
              statement in a market guide or condition report prepared by
              Auction or any third-party describing the mechanical, structural,
              or cosmetic condition of any vehicle as “fair”, “rough”,
              “average”, or similar description shall be deemed a matter of
              subjective opinion, offered as a guideline only, and not as an
              objective statement of fact.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Conducting Business.{" "}
              </span>{" "}
              LegacyAA.com: (i) may rely and act upon any purported signature
              whether oral, written, or electronic and other communication in
              connection with the Auction purportedly sent by Dealer and/or any
              Authorized User or person purporting to be an agent or employee of
              Dealer, and (ii) has no obligation to scrutinize, inquire, or
              confirm any signature or communication with Dealer, any Authorized
              User or other person purporting to be an agent or employee of
              Dealer. LegacyAA.com may conduct business with Dealer through the
              (nonexclusive) use of electronic, computer, digital, or other
              paperless means, including the good faith reliance on electronic
              mail, facsimile transmittal, telephonic or other usual and regular
              forms of communication without confirmation or authentication of
              the communication by receipt of an original signature, document,
              paper or otherwise.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Governing Law, Jurisdiction and Venue
              </span>{" "}
              These Terms and Conditions, and any and all agreements or
              authorizations executed by Dealer, Authorized Representative, or
              LegacyAA.com in connection herewith shall be governed by and
              interpreted in accordance with the substantive laws of the State
              of California without resort to principles of conflicts of laws.
              By execution of these Terms and Conditions, Dealer submits to the
              personal exclusive jurisdiction of the courts of the State of
              California and to venue in the Circuit and Superior Courts of
              Sacramento County, California and the federal courts of the United
              States, sitting in California for the adjudication of any matters
              arising under or in connection with these Terms and Conditions and
              Auction Rules. Any action initiated by Dealer against LegacyAA.com
              relating to these Terms and Conditions shall be filed and
              conducted in said Courts. LegacyAA.com may bring any suit against
              Dealer under or related to these Terms and Conditions in any Court
              of competent jurisdiction.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Miscellaneous.{" "}
              </span>{" "}
              No waiver of the provisions hereof shall be effective unless in
              writing and signed by LegacyAA.com. If any term or section of
              these Terms and Conditions is held invalid or unenforceable, under
              any statute or court decision, or any governmental rule or
              regulation, the remainder of these Terms and Conditions shall
              remain effective. These Terms and Conditions shall bind the
              respective heirs, executors, administrators, successors and
              assigns of Dealer and inure to the benefit of LegacyAA.com and its
              successors, assigns and subrogees.
            </p>
            <p
              className="text-uppercase Font_Family theme_text_color fw-bold"
              style={{ fontSize: "20px" }}
            >
              OTHER ONLINE AUCTION TRANSACTIONS
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Completion of Sale Transaction.{" "}
              </span>{" "}
              Dealer will complete on-line sales of Vehicles, in accordance with
              these Terms and Conditions,
              <span className="text-decoration-underline fw-bold">
                including the following:
              </span>
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Buy Now.
              </span>{" "}
              At Seller’s option, Seller may assign a “fixed price” to any
              consigned Vehicle by which a potential Buyer will have the option
              to “Buy Now” at the stated price, without further competitive
              biddin Seller’s designation of a fixed price shall be deemed a
              binding offer to sell at that price, which a Buyer may accept by
              clicking on the appropriate on -screen button. Buyer’s clicking on
              the button shall be deemed a binding acceptance of the sale at the
              designated fixed price. The fixed price designation may be
              withdrawn or modified by Seller at any time before a Buyer has
              indicated agreement to purchase the Vehicle at the fixed price.
              Once Seller has withdrawn or modified a fixed price, it is not
              required to accept any subsequent offer at the previously fixed
              price
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">Returns</span>{" "}
              At Seller’s option, it may designate the minimum price Seller will
              accept for the Vehicle (the “Reserve”), and/or may designate a
              minimum opening bid amount (the “Minimum Bid”). Reserve may be
              disclosed or undisclosed, at Seller’s option. The winning bid
              shall be the highest bid at the time that the auction cycle for
              that Vehicle closes which meets or exceeds any Reserve. Seller may
              decrease or remove Reserve or Minimum Bids at any time before an
              auction cycle begins in the manner that is established in these
              Terms and Conditions. A Reserve or Minimum Bid cannot be increased
              once bidding on the Vehicle has started. Errors in Reserve or
              Minimum Bid may be corrected by withdrawing the Vehicle from sale
              and relistin If the auction cycle closes and the Vehicle fails to
              sell because no bids were received (a “No Sale”), Seller may
              remove or alter Reserves or Minimum Bids for subsequent Sales.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Place Bid Format.{" "}
              </span>{" "}
              If the Arbitrator has determined that the sale should be cancelled
              and the Vehicle returned, Buyer will follow the return procedure
              indicated for the type of sale.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                If Bid Process.{" "}
              </span>{" "}
              From the opening of a given Sale until up to three hours after the
              close of such Sale (the “Resolve Time”), a
              <span className="text-decoration-underline fw-bold">
                Seller may either:
              </span>{" "}
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              lower the hidden Reserve for the Vehicle. If the hidden Reserve
              price is lowered to a price that is equal to or less than the
              amount bid as its Maximum Bid Amount (defined below) for that
              Vehicle, then the Seller will sell and such bidder will buy the
              Vehicle at such lowered Reserve price and such bidder is the
              highest bidder at the end of the auction, if the auction has
              closed. If the auction has not closed, the competitive bidding
              will continue and the Vehicle will be sold to the highest bidding
              dealer at the end of the auction.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              1. submit, or direct LegacyAA.com in writing (including by e-mail)
              to communicate on Seller’s behalf a counteroffer (the “Seller’s
              Counteroffer Price”) to the bidder with the highest bid showing
              for the Vehicle. Buyer may accept the Seller’s Counteroffer Price
              (through the LegacyAA.com’s auction system or e-mail). Buyer and
              Seller agree that they will be bound by all acceptances
              communicated to the Auction during the Resolve Time. All
              unaccepted offers are deemed rescinded at the close of the Resolve
              Time. Buyer and Seller assume all risk of loss for any transaction
              not completed because of any failure in communication of offers
              and acceptance within the Resolve Time, regardless of cause.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Proxy Bidding
              </span>{" "}
              In the event of an error in the Reserve, Vehicle must be withdrawn
              from the sale and relisted in a new sale.
            </p>
            <p
              className="text-uppercase Font_Family theme_text_color fw-bold"
              style={{ fontSize: "20px" }}
            >
              Off-Site Vehicle Sales:
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Approval
              </span>{" "}
              Pre-approved Dealers may offer off-site Vehicles for sale on the
              appropriate selling platform. The off-site Vehicle must at all
              times be under the lawful possession, custody and control of the
              Selle When an off-site Vehicle is sold, Seller or Seller’s agent
              is required to retain the off-site Vehicle and all ownership
              documents in its custody and control until LegacyAA.com has
              confirmed it has received payment from the Buyer and the funds
              have cleared the Buyer’s bank.
            </p>

            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Seller’s Responsibility for Accuracy of Disclosures and Release
                of Vehicle Listing to Auction.{" "}
              </span>{" "}
              A Seller may use a third party to prepare the vehicle detail page
              and list the Vehicle on Seller’s behalf. The Seller remains
              responsible for the completeness and accuracy of the required
              disclosures regarding the Vehicle on the vehicle detail page.
            </p>
            <p
              className="text-uppercase Font_Family theme_text_color fw-bold"
              style={{ fontSize: "20px" }}
            >
              OTHER LEGACYAA.COM SERVICES.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Dealer-Arranged Transportation.{" "}
              </span>{" "}
              A Buyer may request that Dealer arrange transportation of a
              particular Vehicle on Buyer’s behalf. Dealer provides this service
              for a fee and as a convenience for certain Buyers; however, Dealer
              does not warrant or otherwise endorse the services provided by the
              third party transportation company.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Authorization to Arrange Transportation.{" "}
              </span>{" "}
              By selecting “Dealer-Arranged Transportation” for a specific
              Vehicle, Buyer authorizes and directs Dealer to arrange the
              transportation of such Vehicle on Buyer’s behalf with a third
              party transportation company.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Payment of Fees Prior to Arrangement of Transportation
              </span>{" "}
              Buyer will pay Dealer the fee as applicable for the transportation
              of the Vehicle on or before the Payment Due Da Transportation will
              not be arranged until Buyer has paid the Total Payment Amount,
              including all Fees, including transportation Fees to Dealer
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Risk of Loss.{" "}
              </span>{" "}
              Risk of loss for a Vehicle remains with the Seller until the
              transportation company, arranged on Buyer’s behalf or by Dealer as
              a convenience to Buyer, picks up the Vehicle from the grounding
              location (the “Dealer’s Car Lot”), at which point the risk of loss
              for the Vehicle is transferred to the Buyer
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Transportation Damage Claim Initiation
              </span>{" "}
              Buyer is fully responsible for any damages and may initiate a
              claim for transportation damage on a Vehicle transported with the
              third party Transportation Company.
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              <span className="text-decoration-underline fw-bold">
                Indemnification.
              </span>{" "}
              Buyer agrees to indemnify and hold harmless the com, its
              subsidiaries and other affiliates, and each of their officers,
              employees, and agents from any and all claims, expenses, losses
              and costs associated with any personal injury, property damage or
              delay, that may occur and that relates in any way to the
              transportation of the Vehicle from the Dealer’s Car Lot to the
              designated location by Buyer.
            </p>
            <p
              className="text-uppercase Font_Family theme_text_color fw-bold"
              style={{ fontSize: "20px" }}
            >
              ACCEPTANCE
            </p>
            <p
              className=" Font_Family theme_text_color"
              style={{ fontSize: "14px" }}
            >
              By accessing or using the LegacyAA.com, Dealer agrees to abide by
              all the terms and conditions set forth in these Terms and
              Conditions, including those available by hyperlink to other
              LegacyAA.com’s web pages. These provisions include, among other
              things, payment deadlines, deadlines for delivery of title,
              listing requirements and purchase dispute guidelines. Please read
              these Terms and Conditions carefully before signing or clicking “I
              Accept”, as applicable. By signing or clicking on “I Accept,”
              Dealer agrees to these Terms of Conditions.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TermsAndConditions;
