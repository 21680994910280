import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  item: {
    display: "flex",
  },
  container: {
    width: "100%",
    // height: "100vh",
    // position: "fixed",
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
  container_grid: {
    width: "100%",
    // height: "100vh",
    // position: "fixed",
    display: "flex",
    alignItems: "center",
    // position: "absolute",
    // flexDirection: "row",
    justifyContent: "space-between",
  },
}));
