import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserDashboardStatAction } from '../../redux/actions/R8tr/UserDashboardStatAction';
import BuyerDashboard from '../BuyerDashboard';
import R8trDashboard from '../R8trDashboard';
import ProfileClickedContext from './../../utils/context';
import { useStyles } from './styles';
function Dashboard({ showCurrentJobs }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole'));
  const profileClickedContext = useContext(ProfileClickedContext);
  profileClickedContext.profileClickedHandler(false);
  const { LoginData } = useSelector((state) => state.LogInReducer);

  useEffect(() => {
    dispatch(UserDashboardStatAction());
  }, []);

  useEffect(() => {
    if (LoginData.status) {
      setUserRole(localStorage.getItem('userRole'));
    }
  }, [LoginData]);

  return (
    <div className={classes.container}>
      {/* <Container> */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          // style={{ width: "100%", height: "50vh" }}
          // className={classes.container_grid}
        >
          {userRole == 'buyer' ? (
            <BuyerDashboard />
          ) : (
            <R8trDashboard showCurrentJobs={showCurrentJobs} />
          )}
          {/* <img src={dashboardpic} style={{ width: "100%", height: "100%" }} /> */}
        </Grid>
        {/* <DashboardContainer /> */}
      </Grid>
      {/* </Container> */}
    </div>
  );
}

export default Dashboard;
