import './ContactDetails.css';

import { Container } from '@mui/material';
import { useState } from 'react';
import { Button, Nav, Tab } from 'react-bootstrap';
import { validate } from 'react-email-validator';
import { BsAsterisk } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { completeCreateJob } from 'redux/reducers/buyer/HireR8trReducer';
import swalWithBootstrapButtons from 'sweetalert2';

import Loader from './../../Loader/Loader';

const PRIVATE_KEY = 'PRIVATE';
const DEALER_KEY = 'DEALER';

const ContactDetails = ({ handleTosterFire }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(PRIVATE_KEY);
  const showTextArea = activeKey === PRIVATE_KEY;
  const isDealer = activeKey === DEALER_KEY;
  const [loading, setLoading] = useState(false);
  const [contactName, setContacttName] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [instruction, setInstruction] = useState('');

  const handleContactNameChange = (event) => {
    setContacttName(event.target.value);
  };

  const { createJob } = useSelector((state) => state.HireR8trReducer);

  const handleContactPhoneChange = (event) => {
    setContactPhone(event.target.value);
  };

  const handleContactEmailChange = (event) => {
    setContactEmail(event.target.value);
  };

  const handleInstructionChange = (event) => {
    setInstruction(event.target.value);
  };
  const handleJobsCancelled = () => {
    swalWithBootstrapButtons
      .fire({
        title:
          'Do you really want to go back to dashboard without creating job ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel it!',
        cancelButtonText: "No, don't cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success',
        },
        buttonsStyling: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // Swal.fire("Canceled Job!", "Your job has been canceled.", "success");
          navigate('/');
        }
        // status = 1;
      });
  };
  const handleSubmit = () => {
    let emailValid = validate(contactEmail);
    if (!emailValid) {
      handleTosterFire('Please Add valid Email');
      return;
    }
    if (!contactName || !contactPhone || contactPhone < 11) {
      handleTosterFire('Please fill the required fields');

      return;
    }

    setLoading(true);
    let data = {
      categoryId: createJob.categoryId,
      subCategoryId: createJob.subCategoryId,
      askingPrice: createJob.askingPrice,
      buyerCost: createJob.buyerCost,
      description: createJob.description,
      vinOrSerial: createJob.vinOrSerial,
      year: createJob.year,
      make: createJob.make,
      model: createJob.model,
      link: createJob.link,
      address: createJob.address,
      city: createJob.city,
      state: createJob.state,
      zip: createJob.zip,
      latitude: createJob.jobLatitude,
      longitude: createJob.jobLongitude,
      contactName: contactName,
      contactNumber: contactPhone,
      contactEmail: contactEmail,
      isDealer: isDealer,
      specialInstructions: instruction,
    };
    dispatch(completeCreateJob(data));
    navigate('/submit-payment');
  };

  return (
    <div className="theme_text_color Font_Family contact_detail_main_div">
      <Container>
        <div className="w-100 fw-bold mt-3 ps-2 div_title_contact_details theme_text_color">
          HIRE A R8TR STEP 4
        </div>

        {/* input field div */}
        <div className="ps-2 div_style_contact_details justify-content-between">
          <input
            className="input_job_calculator  w-100 "
            type="text"
            name="contactName"
            placeholder="POINT OF CONTACT NAME"
            required
            value={contactName}
            onChange={handleContactNameChange}
            style={{ border: 'none' }}
          />
          <BsAsterisk style={{ color: '#a7a9ac', fontSize: '10px' }} />
        </div>

        <div className="ps-2 div_style_contact_details justify-content-between">
          <input
            className="input_job_calculator  w-100 "
            type="text"
            name="contactPhone"
            placeholder="POINT OF CONTACT PHONE"
            required
            value={contactPhone}
            onChange={handleContactPhoneChange}
            style={{ border: 'none' }}
          />
          <BsAsterisk style={{ color: '#a7a9ac', fontSize: '10px' }} />
        </div>
        <div className="ps-2 div_style_contact_details justify-content-between">
          <input
            className="input_job_calculator  w-100"
            type="email"
            name="contactEmail"
            placeholder="POINT OF CONTACT EMAIL"
            required
            value={contactEmail}
            onChange={handleContactEmailChange}
            style={{ border: 'none' }}
          />
        </div>
        <div className="div_style_contact_details_tab justify-content-between">
          <Tab.Container activeKey={activeKey} onSelect={setActiveKey}>
            <Nav variant="tabs" className="d-flex align-items-center w-100">
              <Nav.Item>
                <Nav.Link
                  eventKey={PRIVATE_KEY}
                  // onChange={() => setActiveKey(PRIVATE_KEY)}
                  className="d-flex justify-content-center align-items-center w-100 fw-bold"
                  style={{ color: isDealer ? '#5C647C' : '#414042' }}
                >
                  PRIVATE
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={DEALER_KEY}
                  // onChange={() => setActiveKey(PRIVATE_KEY)}
                  className="d-flex justify-content-center align-items-center w-100 fw-bold"
                  style={{ color: isDealer ? '#414042' : '#5C647C' }}
                >
                  DEALER
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
        <div className="ps-2">
          <Tab.Content className="mt-3">
            {showTextArea ? (
              <textarea
                className="textarea_contact_detail p-2"
                placeholder="SPECIAL INSTRUCTIONS (I.E PLEASE VERIFY THERE IS NO SMOKE DOOR, SPECIAL OPTIONS, DOES IT HAVE A SUNROOF, ETC"
                name="instruction"
                value={instruction}
                onChange={handleInstructionChange}
              />
            ) : (
              <textarea
                className="textarea_contact_detail p-2"
                placeholder="SPECIAL INSTRUCTIONS (I.E PLEASE VERIFY THERE IS NO SMOKE DOOR, SPECIAL OPTIONS, DOES IT HAVE A SUNROOF, ETC"
                name="instruction"
                value={instruction}
                onChange={handleInstructionChange}
              />
            )}
          </Tab.Content>
        </div>

        <div className="my-2 text-center">
          <Button
            className="job_price_button theme_text_color"
            style={{
              backgroundColor: '#d40000',
              borderColor: '#e61d2a',
              borderRadius: '0px 15px 0px 15px',
              height: '40px',
            }}
            // disabled={!contactName || !contactPhone || contactPhone < 11}
            type="button"
            onClick={handleSubmit}
          >
            <span className="d-flex justify-content-center align-content-center w-100">
              {loading ? <Loader /> : <span>NEXT</span>}
            </span>
          </Button>
          <Button
            className="job_price_button theme_text_color mx-3"
            style={{
              backgroundColor: 'black',
              borderColor: 'black',
              borderRadius: '0px 15px 0px 15px',
              height: '40px',
            }}
            type="button"
            onClick={handleJobsCancelled}
          >
            <span className="d-flex justify-content-center align-content-center w-100 text-uppercase">
              Cancel
            </span>
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ContactDetails;
