import './addcoupon.css';

import { Grid } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import apiProtected from 'api/apiClient';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../../ApiUrl';
import Loader from '../../Loader/Loader';
const toastOptions = {
  position: 'top-right',
  autoClose: 2000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function AddCoupon({
  handleAddCouponModal,
  open,
  cost,
  handleCouponDiscountAmount,
}) {
  const [loading, setLoading] = useState(false);
  const [couponCode, setCouponCode] = useState();
  const handleClose = () => {
    handleAddCouponModal();
  };
  const handleCouponRes = (message) => {
    if (!message) {
      setLoading(false);
      handleAddCouponModal();
    } else {
      setLoading(false);
      toast.error(message, toastOptions);
    }
  };
  const handleSubmit = () => {
    setLoading(true);
    apiProtected
      .get(ApiUrl.server_url + `v1/coupons/calculate-coupon-discount`, {
        params: { buyerCost: cost, code: couponCode },
      })
      .then((res) => {
        handleCouponDiscountAmount(res);
        handleCouponRes();
      })
      .catch((error) => {
        const message = error.response.data.message;
        handleCouponRes(message);
      });
  };
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <ToastContainer />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ width: '100% !important' }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className="theme_text_color Font_Family"
        >
          APPLY COUPON
        </BootstrapDialogTitle>
        <ValidatorForm
          style={{ width: '100% !important' }}
          onSubmit={() => handleSubmit()}
          // onError={(errors) => console.log(errors)}
        >
          <hr
            style={{
              marginTop: '-3px',
            }}
          />
          <DialogContent sx={{ width: '100% !important' }}>
            <Grid
              container
              spacing={2}
              className="d-flex justify-content-center align-Items-center"
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="d-flex justify-content-center align-Items-center theme_text_color Font_Family fw-bold"
              >
                COUPON CODE
              </Grid>
              <Grid
                item
                lg={10}
                md={10}
                sm={12}
                xs={12}
                className=" justify-content-center align-Items-center"
              >
                <TextValidator
                  //   className="w-full"
                  style={{
                    width: '50% !important',
                  }}
                  label="Enter voucher Code"
                  onChange={(event) => setCouponCode(event.target.value)}
                  type="text"
                  name="discount"
                  value={couponCode}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid
                item
                lg={10}
                md={10}
                sm={12}
                xs={12}
                className=" justify-content-center align-Items-center"
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{
                    backgroundColor: '#d40000',
                    borderColor: '#d40000',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px',
                    width: '100%',
                  }}
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <span className="pl-2 capitalize">Submit</span>
                    </>
                  )}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </ValidatorForm>
      </BootstrapDialog>
    </div>
  );
}

export default AddCoupon;
