import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  buyerRatinR8tr: [],
  buyerRatinResponce: [],
};

const buyerRatingR8trSlice = createSlice({
  name: 'buyerRatingR8tr',
  initialState,
  reducers: {
    getBuyerRatingR8tr: (state, action) => {
      state.buyerRatinR8tr = [...action.payload.data];
    },
    getBuyerRatingResponce: (state, action) => {
      state.buyerRatinResponce = [...action.payload.data];
    },
  },
});

export const { getBuyerRatingR8tr, getBuyerRatingResponce } =
  buyerRatingR8trSlice.actions;

export default buyerRatingR8trSlice.reducer;
