import './chatdialog.css';

import {
  Avatar,
  ChatContainer,
  ConversationHeader,
  Loader,
  Message,
  MessageInput,
  MessageList,
} from '@chatscope/chat-ui-kit-react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
// import socket from "../../socketService";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Menu from '@mui/material/Menu';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { styled } from '@mui/material/styles';
import dateFormat from 'dateformat';
import MicRecorder from 'mic-recorder-to-mp3';
import * as React from 'react';
import { useEffect } from 'react';
import {
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineFilePpt,
  AiOutlineFileText,
  AiOutlineFileWord,
  AiOutlineFileZip,
} from 'react-icons/ai';
import { GrDocumentCsv } from 'react-icons/gr';
import { RiCloseFill } from 'react-icons/ri';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import S3FileUpload from 'react-s3';
import { useStopwatch } from 'react-timer-hook';
import uuid from 'react-uuid';
window.Buffer = window.Buffer || require('buffer').Buffer;

import profilePic from '../../assets/images/adminProfile.png';
const StyledSpeedDial = styled(SpeedDial)(() => ({
  fontSize: '16px',
  // position: 'absolute',
  // '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
  //     bottom: theme.spacing(2),
  //     right: theme.spacing(2),
  // },
  // '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
  //     // top: theme.spacing(2),
  //     left: theme.spacing(2),
  // },
}));
const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  dirName: `${process.env.REACT_APP_ENVIRONMENT}/chat`,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
};
export default function ChatDialogs({
  handleChatDialogClose,
  userData,
  socket,
  showChatBox,
}) {
  // const [open, setOpen] = React.useState();
  const location = useLocation();
  const [loading, setLoading] = React.useState(null);
  const userRole = localStorage.getItem('userRole');
  const [mic, setMic] = React.useState({
    isRecording: false,
    isBlocked: true,
  });
  const [Mp3Recorder, setMp3Recorder] = React.useState(
    new MicRecorder({ bitRate: 128 })
  );
  const [speedDailOpen, setSpeedDialOpen] = React.useState(true);
  const [speedDailDirection, setSpeedDialDirection] = React.useState('right');
  const [msgInputValue, setMgInputValue] = React.useState('');
  const [messageArray, setMessageArray] = React.useState([]);
  const [socketId, setSocketId] = React.useState(null);
  const anchorRef = React.useRef(null);
  const { seconds, minutes, hours, start, reset } = useStopwatch({
    autoStart: false,
  });
  let time = `${hours}:${minutes}:${seconds}`;
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    handleChatDialogClose();
    // setOpen(false);
  };
  useEffect(() => {
    if (mic.isRecording) {
      for (let i = 1; i < 30; i++) {
        const left = i * 2 + 1;
        const anim = Math.floor(Math.random() * 75 + 400);
        const height = Math.floor(Math.random() * 25 + 3);
        let temp = document.getElementsByClassName(`bar${i}`)[0];
        temp.style.left = `${left}px`;
        temp.style.height = `${height}px`;
        temp.style.animationDuration = `${anim}ms`;
      }
    }
  }, [mic.isRecording]);
  useEffect(() => {
    setMp3Recorder(new MicRecorder({ bitRate: 128 }));
    navigator.getUserMedia(
      { audio: true },
      () => {
        let micSet = { ...mic };
        micSet.isBlocked = false;
        setMic(micSet);
      },
      () => {
        let micSet = { ...mic };
        micSet.isBlocked = true;
        setMic(micSet);
      }
    );
  }, []);
  useEffect(() => {
    socket.on('message received', (data) => {
      if (data?.user?._id !== userData?.id) {
        setMessageArray((list) => [...list, data]);
      } else {
        setTimeout(() => {
          handleIsSeen(data);
        }, 2000);
      }
    });
    socket.on('user chat joined', (data) => {
      if (data) {
        setSocketId(data.socketId);
        setMessageArray(data?.chat);
      }
    });
  }, [socket]);
  const handleIsSeen = (data) => {
    const array = JSON.parse(localStorage.getItem('messgesArray'));
    let existingMessageIndex = array.findIndex((el) => {
      return el.uuid === data.uuid;
    });
    if (existingMessageIndex > -1) {
      if (data.isSeen) {
        array[existingMessageIndex].isSeen = true;
        setMessageArray(array);
      }
    }
  };
  const recorderStart = () => {
    let micSet = { ...mic };
    micSet.isRecording = true;
    setMic(micSet);
    if (!mic.isBlocked) {
      return;
    }

    start();
    Mp3Recorder.start()
      .then(() => {
        setMic({ isRecording: true });
      })
      .catch((e) => console.error(e));
  };

  const stop = async () => {
    let messageuuid = uuid();
    reset();
    let micSet = { ...mic };
    micSet.isRecording = false;
    setMic(micSet);
    Mp3Recorder.stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        let data = {
          type: 'audio',
          audio: {
            duration: time,
            uri: blobURL,
          },
          user: { _id: userData.id },
          roomId: userData.id + '_' + 'admin',
          socketId: socketId,
          isSeen: false,
          uuid: messageuuid,
          createdAt: new Date(),
        };
        let newMessageArray = [...messageArray];
        newMessageArray.push(data);
        setMessageArray(newMessageArray);
        localStorage.setItem('messgesArray', JSON.stringify(newMessageArray));

        try {
          if (blob) {
            let uploadRes = await S3FileUpload.uploadFile(blob, config);
            data.audio.uri = uploadRes.location;
          }
          socket.emit('new user message', data);
        } catch (error) {
          console.log(error);
          return;
        }
      })
      .catch((e) => console.log(e));
  };
  const handleDeleteVoice = () => {
    reset();
    let micSet = { ...mic };
    micSet.isRecording = false;
    setMic(micSet);
  };
  const handleSend = () => {
    if (msgInputValue) {
      const newMessage = msgInputValue.replace(/&nbsp;/g, '');
      let messageuuid = uuid();
      const data = {
        type: 'text',
        text: newMessage,
        user: { _id: userData.id },
        roomId: userData.id + '_' + 'admin',
        socketId: socketId,
        isSeen: false,
        uuid: messageuuid,
        createdAt: new Date(),
      };
      let newMessageArray = [...messageArray];
      newMessageArray.push(data);
      let newMessage_Array = [...newMessageArray];
      setMessageArray(newMessage_Array);
      localStorage.setItem('messgesArray', JSON.stringify(newMessage_Array));
      setMgInputValue('');
      socket.emit('new user message', data);
    }
  };
  const handleFileTypeField = async (event) => {
    if (event.target.files[0]) {
      let data;
      let mediaType = event.target.files[0].type.split('/')[0];
      let fileType = event.target.files[0].name.split('.')[1];
      let messageuuid = uuid();
      if (mediaType === 'application' || mediaType === 'text') {
        mediaType = 'file';
      }
      if (mediaType === 'video') {
        data = {
          type: mediaType,
          video: URL.createObjectURL(event.target.files[0]),
          user: { _id: userData.id },
          roomId: userData.id + '_' + 'admin',
          socketId: socketId,
          isSeen: false,
          uuid: messageuuid,
          createdAt: new Date(),
        };
      } else if (mediaType === 'image') {
        setLoading(messageuuid);
        data = {
          type: mediaType,
          image: URL.createObjectURL(event.target.files[0]),
          user: { _id: userData.id },
          roomId: userData.id + '_' + 'admin',
          imageName: event.target.files[0].name,
          socketId: socketId,
          isSeen: false,
          uuid: messageuuid,
          createdAt: new Date(),
        };
      } else if (mediaType === 'audio') {
        data = {
          type: mediaType,
          // audio: URL.createObjectURL(event.target.files[0]),
          audio: {
            duration: time,
            uri: URL.createObjectURL(event.target.files[0]),
          },
          user: { _id: userData.id },
          roomId: userData.id + '_' + 'admin',
          socketId: socketId,
          isSeen: false,
          uuid: messageuuid,
          createdAt: new Date(),
        };
      } else if (mediaType === 'file') {
        setLoading(messageuuid);
        data = {
          type: mediaType,
          file: {
            url: URL.createObjectURL(event.target.files[0]),
            fileName: event.target.files[0].name,
            fileType: fileType,
          },
          user: { _id: userData.id },
          roomId: userData.id + '_' + 'admin',
          socketId: socketId,
          isSeen: false,
          uuid: messageuuid,
          createdAt: new Date(),
        };
      }

      let newMessageArray = [...messageArray];
      newMessageArray.push(data);
      setMessageArray(newMessageArray);
      localStorage.setItem('messgesArray', JSON.stringify(newMessageArray));
      try {
        if (event.target.files[0]) {
          let uploadRes = await S3FileUpload.uploadFile(
            event.target.files[0],
            config
          );
          if (mediaType === 'file') {
            setLoading(null);
            data.file.url = uploadRes.location;
          } else if (mediaType === 'audio') {
            setLoading(null);
            data.audio.uri = uploadRes.location;
          } else {
            setLoading(null);
            data[mediaType] = uploadRes.location;
          }
        }
        socket.emit('new user message', data);
      } catch (error) {
        console.log(error);
        return;
      }
    }
  };
  const handlInputText = (msg) => {
    if (msg) {
      setSpeedDialOpen(false);
      setMgInputValue(msg);
      setSpeedDialDirection('up');
    } else {
      setSpeedDialOpen(true);
      setMgInputValue('');
      setSpeedDialDirection('right');
    }
  };
  const handleSpeedDailDirection = () => {
    if (!msgInputValue) {
      setSpeedDialOpen(!speedDailOpen);
      if (speedDailDirection === 'up') {
        setSpeedDialDirection('right');
      } else {
        setSpeedDialDirection('up');
      }
    } else if (msgInputValue) {
      setSpeedDialDirection('up');
      setSpeedDialOpen(!speedDailOpen);
    }
  };
  let admin = 'dhggayuged';
  return (
    <div>
      <Menu
        id="basic-menu"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        // transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showChatBox}
        sx={{
          position: 'fixed',
          width: '600px !important',
          top: userRole === 'buyer' && location.pathname === '/' && '60px',
          bottom: '0px',
        }}
        // hideBackdrop={true}
        disableScrollLock={true}
        onClose={() => handleChatDialogClose()}
      >
        <ChatContainer
          style={{
            width: '350px',
            height: '550px',
          }}
        >
          <ConversationHeader
            style={{
              // position: "fixed",
              zIndex: '9',
              width: '350px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: 'black',
            }}
          >
            <Avatar
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid gray',
              }}
              src={profilePic}
              name="A"
            />
            <ConversationHeader.Content
              // userName={`${admin}`}
              style={{
                background: 'black',
                color: 'white',
              }}
            >
              <div style={{ display: 'flex' }}>
                <span
                  style={{
                    color: 'black',
                  }}
                >
                  ~
                </span>
                <span
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    alignSelf: 'flex-center',
                  }}
                >
                  Admin
                </span>
              </div>
            </ConversationHeader.Content>
            <ConversationHeader.Actions>
              <RiCloseFill
                style={{
                  fontSize: '26px',
                  cursor: 'pointer',
                  color: 'white',
                }}
                onClick={handleClose}
              />
            </ConversationHeader.Actions>
          </ConversationHeader>
          <MessageList
            style={{
              position: 'absolute',
              top: '65px',
              height: '435px',
            }}
          >
            {messageArray.map((message) => (
              <>
                {message.type === 'text' ? (
                  <Message
                    model={{
                      direction:
                        message?.user?._id === userData.id
                          ? 'outgoing'
                          : 'incoming',
                      position: 'single',
                      type: 'custome',
                    }}
                  >
                    <Message.CustomContent className="message_container">
                      <span>
                        {message.text}
                        <br></br>
                        <span
                          style={{
                            fontSize: '10px',
                          }}
                        >
                          {dateFormat(message.createdAt, 'dd/mm/yy, h:MM TT')}
                        </span>
                      </span>
                      <span>
                        {message.isSeen &&
                          message?.user?._id === userData.id && (
                            <DoneAllIcon
                              style={{
                                fontSize: '14px',
                                color: '#1b60ea',
                              }}
                            />
                          )}
                      </span>
                    </Message.CustomContent>
                  </Message>
                ) : message.type === 'video' ? (
                  <Message
                    model={{
                      direction:
                        message?.user?._id === userData.id
                          ? 'outgoing'
                          : 'incoming',
                      type: 'custom',
                      position: 'single',
                    }}
                  >
                    <Message.CustomContent className="message_container">
                      <span>
                        <ReactPlayer
                          url={message?.video}
                          width="170px"
                          height="120px"
                          playIcon={'play'}
                          controls={true}
                          style={{ borderRadius: '10px' }}
                        />
                        <br></br>
                        <span
                          style={{
                            fontSize: '10px',
                          }}
                        >
                          {dateFormat(message.createdAt, 'dd/mm/yy, h:MM TT')}
                        </span>
                      </span>
                      &nbsp;
                      <span>
                        {message.isSeen &&
                          message?.user?._id === userData.id && (
                            <DoneAllIcon
                              style={{
                                fontSize: '14px',
                                color: '#1b60ea',
                                zIndex: '999',
                              }}
                            />
                          )}
                      </span>
                    </Message.CustomContent>
                  </Message>
                ) : message.type === 'image' ? (
                  <Message
                    type="custom"
                    style={{
                      cursor: 'pointer',
                    }}
                    model={{
                      direction:
                        message?.user?._id === userData.id
                          ? 'outgoing'
                          : 'incoming',
                      position: 'single',
                    }}
                  >
                    <Message.CustomContent className="message_container">
                      <span>
                        <a
                          href={message?.image}
                          target="_blank"
                          rel="noreferrer"
                          download={message?.imageName}
                          className="a_tage"
                        >
                          <img
                            src={message.image}
                            style={{
                              width: '170px',
                              height: '150px',
                              objectFit: 'contain',
                            }}
                          />
                        </a>
                        <br></br>
                        <span
                          style={{
                            fontSize: '10px',
                          }}
                        >
                          {dateFormat(message.createdAt, 'dd/mm/yy, h:MM TT')}
                        </span>
                      </span>
                      &nbsp;
                      <span>
                        {loading === message?.uuid && !message.isSeen && (
                          <Loader />
                        )}
                        {message.isSeen &&
                          message?.user?._id === userData.id && (
                            <DoneAllIcon
                              style={{
                                fontSize: '14px',
                                color: '#1b60ea',
                                zIndex: '999',
                              }}
                            />
                          )}
                      </span>
                    </Message.CustomContent>
                  </Message>
                ) : message.type === 'audio' ? (
                  <Message
                    model={{
                      direction:
                        message?.user?._id === userData.id
                          ? 'outgoing'
                          : 'incoming',
                      type: 'custom',
                      position: 'single',
                    }}
                  >
                    <Message.CustomContent
                      className="message_container"
                      style={{ minWidth: '270px !important' }}
                    >
                      <span>
                        <audio
                          controls
                          className="audio_player"
                          src={message.audio.uri}
                        ></audio>
                        <br></br>
                        <span
                          style={{
                            fontSize: '10px',
                          }}
                        >
                          {dateFormat(message.createdAt, 'dd/mm/yy, h:MM TT')}
                        </span>
                      </span>
                      &nbsp;
                      <span>
                        {message.isSeen &&
                          message?.user?._id === userData.id && (
                            <DoneAllIcon
                              style={{
                                fontSize: '14px',
                                color: '#1b60ea',
                                zIndex: '999',
                              }}
                            />
                          )}
                      </span>
                    </Message.CustomContent>
                  </Message>
                ) : message?.type === 'file' ? (
                  <Message
                    model={{
                      direction:
                        message?.user?._id === userData.id
                          ? 'outgoing'
                          : 'incoming',
                      type: 'custom',
                      position: 'single',
                    }}
                  >
                    <Message.CustomContent className="message_container">
                      <span>
                        <a
                          href={message?.file?.url}
                          target="_blank"
                          rel="noreferrer"
                          download={message?.file?.fileName}
                          className="a_tage"
                        >
                          <span
                            style={{
                              cursor: 'pointer',
                              fontFamily: 'Roboto, sans-serif',
                            }}
                          >
                            {message?.file?.fileType === 'docx' ? (
                              <AiOutlineFileWord className="file_icons" />
                            ) : message?.file?.fileType === 'pdf' ? (
                              <AiOutlineFilePdf className="file_icons" />
                            ) : message?.file?.fileType === 'xlsx' ||
                              message?.file?.fileType === 'pub' ? (
                              <AiOutlineFileExcel className="file_icons" />
                            ) : message?.file?.fileType === 'csv' ? (
                              <GrDocumentCsv
                                style={{ fontSize: '18px', marginRight: '6px' }}
                              />
                            ) : message?.file?.fileType === 'zip' ||
                              message?.file?.fileType === 'exe' ? (
                              <AiOutlineFileZip className="file_icons" />
                            ) : message?.file?.fileType === 'pptx' ? (
                              <AiOutlineFilePpt className="file_icons" />
                            ) : (
                              <AiOutlineFileText className="file_icons" />
                            )}
                            <span>{message?.file?.fileName}</span>
                          </span>
                        </a>
                        <br></br>
                        <span
                          style={{
                            fontSize: '10px',
                          }}
                        >
                          {dateFormat(message.createdAt, 'dd/mm/yy, h:MM TT')}
                        </span>
                      </span>
                      &nbsp;
                      <span>
                        {loading === message?.uuid && !message.isSeen && (
                          <Loader />
                        )}
                        {message.isSeen &&
                          message?.user?._id === userData.id && (
                            <DoneAllIcon
                              style={{
                                fontSize: '14px',
                                color: '#1b60ea',
                                zIndex: '999',
                              }}
                            />
                          )}
                      </span>
                    </Message.CustomContent>
                  </Message>
                ) : (
                  ''
                )}
              </>
            ))}
          </MessageList>
          <div
            as="MessageInput"
            style={{
              position: 'relative',
              // zIndex: '9',
              zIndex: '1500',
              marginTop: 'auto',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <StyledSpeedDial
              ariaLabel="SpeedDial playground example"
              open={speedDailOpen}
              style={{
                marginBottom: speedDailDirection === 'up' ? '8px' : '',
                marginTop: speedDailDirection === 'right' ? '6px' : '',
                width: speedDailDirection === 'up' ? '55px' : '',
              }}
              icon={<AddOutlinedIcon onClick={handleSpeedDailDirection} />}
              direction={speedDailDirection}
            >
              <SpeedDialAction
                icon={
                  <AttachFileIcon
                    style={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                    onClick={() => {
                      document.getElementById('fileInput').click();
                    }}
                  />
                }
              />
              {!mic.isRecording ? (
                <SpeedDialAction
                  icon={<KeyboardVoiceOutlinedIcon onClick={recorderStart} />}
                />
              ) : (
                <SpeedDialAction
                  icon={
                    <SendOutlinedIcon
                      onClick={stop}
                      style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    />
                  }
                />
              )}
            </StyledSpeedDial>
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleFileTypeField}
            />
            {!mic.isRecording ? (
              <MessageInput
                onChange={(msg) => {
                  handlInputText(msg);
                }}
                onPaste={(evt) => {
                  evt.preventDefault();
                  setMgInputValue(evt.clipboardData.getData('text'));
                }}
                value={msgInputValue}
                attachButton={false}
                onSend={handleSend}
                style={{
                  flexGrow: 1,
                  borderTop: 0,
                  flexShrink: 'initial',
                }}
              />
            ) : (
              <>
                <div
                  style={{
                    fontSize: '14px',
                    flexGrow: 1,
                    borderTop: 0,
                    flexShrink: 'initial',
                  }}
                >
                  <div id={speedDailDirection === 'up' ? 'bars_two' : 'bars'}>
                    <div className="bar bar1"></div>
                    <div className="bar bar2"></div>
                    <div className="bar bar3"></div>
                    <div className="bar bar4"></div>
                    <div className="bar bar5"></div>
                    <div className="bar bar6"></div>
                    <div className="bar bar7"></div>
                    <div className="bar bar8"></div>
                    <div className="bar bar9"></div>
                    <div className="bar bar10"></div>
                    <div className="bar bar11"></div>
                    <div className="bar bar12"></div>
                    <div className="bar bar13"></div>
                    <div className="bar bar14"></div>
                    <div className="bar bar15"></div>
                    <div className="bar bar16"></div>
                    <div className="bar bar17"></div>
                    <div className="bar bar18"></div>
                    <div className="bar bar19"></div>
                    <div className="bar bar20"></div>
                    <div className="bar bar21"></div>
                    <div className="bar bar22"></div>
                    <div className="bar bar23"></div>
                    <div className="bar bar24"></div>
                    <div className="bar bar25"></div>
                    <div className="bar bar26"></div>
                    <div className="bar bar27"></div>
                    <div className="bar bar28"></div>
                    <div className="bar bar29"></div>
                    <div className="bar bar30"></div>
                  </div>
                  <span
                    className={
                      speedDailDirection === 'up'
                        ? 'timer_container_Two'
                        : 'timer_container'
                    }
                  >
                    <span>{minutes}</span>:<span>{seconds}</span>
                  </span>
                </div>
                <DeleteOutlinedIcon
                  style={{
                    color: '#d40000',
                    marginTop: speedDailDirection === 'up' ? '100px' : '10px',
                    marginRight: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={handleDeleteVoice}
                />
              </>
            )}
          </div>
        </ChatContainer>
      </Menu>
    </div>
  );
}
