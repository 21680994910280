import { Drawer, Hidden } from "@material-ui/core";
import React from "react";

import LeftBarDesign from "./LeftBarDesign";
import { LeftBarStyle } from "./LeftBarStyle";

const LeftBar = ({ isMobile, funcSetIsMobile }) => {
  const classes = LeftBarStyle();

  return (
    <div className={classes.container}>
      <nav className={classes.drawer}>
        <Hidden xsDown implementation="css">
          <Drawer
            variant="permanent"
            open
            anchor="left"
            classes={{ paper: classes.drawerPaper }}
          >
            <LeftBarDesign />
          </Drawer>
        </Hidden>
        <Drawer
          variant="temporary"
          open={isMobile}
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          onClick={funcSetIsMobile}
        >
          <LeftBarDesign />
        </Drawer>
      </nav>
    </div>
  );
};

export default LeftBar;
