import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deleteUser: {},
};

const deleteUserAccountSlice = createSlice({
  name: 'deleteUserAccount',
  initialState,
  reducers: {
    deleteUserAccount: (state, action) => {
      state.deleteUser = action.payload;
    },
  },
});

export const { deleteUserAccount } = deleteUserAccountSlice.actions;

export default deleteUserAccountSlice.reducer;
