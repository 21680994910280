import "./googlemap.css";

import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import pin from "../../../assets/images/google_map/pin.webp";
import { AvailableJobsAction } from "../../../redux/actions/R8tr/AvailableJobsAction";
import { CurrentJobsAction } from "../../../redux/actions/R8tr/CurrentJobsAction";
import { DefaultAddressAction } from "../../../redux/actions/R8tr/DefaultAddressAction";
import { getUserData } from "../../../redux/actions/UserAction";
const markerStyle = {
  position: "absolute",
  top: "100%",
  left: "50%",
  color: "red",
  height: "30px",
  transform: "translate(-50%, -100%)",
};

export default function GoogleMaps({ showCurrentJobs }) {
  const navigator = useNavigate();

  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.UserReducer);
  const [mapData, setMapData] = useState([]);
  const [PopoverData, setPopoverData] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [circleRadius, setCircleRadius] = useState(
    userDetails.data?.areas[0].radius
  );

  const { availableJobsData } = useSelector(
    (state) => state.AvailableJobsReducer
  );
  const { currentJobsData } = useSelector((state) => state.CurrentJobsReducer);

  useEffect(() => {
    dispatch(getUserData());
  }, []);
  useEffect(() => {
    setShowMap(false);
    setCircleRadius(userDetails.data?.areas[0]?.radius);
    setTimeout(() => {
      setShowMap(true);
    }, 300);
  }, [userDetails]);
  const handleDefaultAddressUpdate = () => {
    dispatch(getUserData());
    dispatch(AvailableJobsAction());
    dispatch(CurrentJobsAction());
  };
  const handleDefaultAddress = (e) => {
    console.log(e.target.value);
    dispatch(DefaultAddressAction(e.target.value, handleDefaultAddressUpdate));
  };
  useEffect(() => {
    setShowMap(true);
  }, [2000]);
  useEffect(() => {
    if (showCurrentJobs) {
      setMapData(currentJobsData);
    } else {
      setMapData(availableJobsData);
    }
    // console.log("mapData", mapData);
  }, [availableJobsData, currentJobsData, showCurrentJobs]);
  const handlePopover = (data) => {
    if (PopoverData?.id != data.id) {
      setPopoverData(data);
    }
  };

  const handleOnMouseOut = () => {
    setPopoverData(null);
  };
  const Popover = () => {
    return (
      <table
        style={{ backgroundColor: "white", borderRadius: "0px 15px 0px 15px" }}
      >
        <tbody key={PopoverData.id}>
          <tr key={PopoverData.id}>
            <td className="currentjob_td">
              {PopoverData.make}
              <br /> {PopoverData.model}
            </td>
            <td className="currentjob_td">
              <span style={{ color: "#b0b8c1" }}>price</span>
              <br />{" "}
              <span className="theme-color">${PopoverData.askingPrice}</span>
            </td>
            <td className="currentjob_td">
              <span style={{ color: "#b0b8c1" }}>Miles</span>
              <br /> {PopoverData?.distance?.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
  const defaultProps = {
    center: {
      lat: userDetails.data?.areas[0].latitude,
      lng: userDetails.data?.areas[0].longitude,
    },
    zoom: 11,
  };
  return (
    // Important! Always set the container height explicitly
    <div className="googlemap_container">
      <div className="googlemap_selected_field_container">
        <select
          className="googlemap_selected_field googlemap_select"
          onChange={handleDefaultAddress}
          style={{ pointerEvents: "auto" }}
        >
          {userDetails.data?.areas.map((item) => (
            <option key={item.id} value={item.id} className="silver_Color">
              <span>{item.address1}</span>
            </option>
          ))}
        </select>
      </div>
      {showMap && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDSON5PHjh4GGHAfAIC8ABPcmxGwophm4I",
            // libraries: ["places"],
            // id: "CUSTOM_SCRIPT_ID",
          }}
          // id="__googleMapsScriptId"
          center={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) =>
            new maps.Circle({
              strokeColor: "#a7a9ac",
              strokeOpacity: 0.8,
              strokeWeight: 0,
              fillColor: "#a7a9ac",
              fillOpacity: 0.3,
              map,
              center: {
                lat: userDetails.data?.areas[0].latitude,
                lng: userDetails.data?.areas[0].longitude,
              },
              // radius: radius ? radius * 1 * (1.60934 * 1000) : 0,
              radius: circleRadius ? circleRadius * 1 * 1000 : 0,
            })
          }
        >
          {mapData.map((i) => {
            return (
              <a
                key={i.id}
                lat={i.latitude}
                lng={i.longitude}
                onClick={() => {
                  navigator("/detail", { state: { jobId: i.id } });
                }}
              >
                <img
                  src={pin}
                  alt="pin"
                  style={markerStyle}
                  onMouseOver={() => handlePopover(i)}
                  onMouseOut={handleOnMouseOut}
                />
              </a>
            );
          })}
          {/* <Marker mapData={mapData} /> */}
          {PopoverData != null && (
            <Popover lat={PopoverData?.latitude} lng={PopoverData?.longitude} />
          )}
        </GoogleMapReact>
      )}

      {/* <R8trJobs /> */}
    </div>
  );
}
