import './HireR8tr.css';

import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  ListSubheader,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { completeCreateJob } from 'redux/reducers/buyer/HireR8trReducer';

import { scrappedDetails } from '../../../redux/actions/buyer/BuyerJobAction';
import {
  getAllCategories,
  getAllCosts,
} from '../../../redux/actions/CategoriesExpertiseAction';

const HireR8tr = () => {
  const toastOptions = {
    style: { marginTop: '50px' },
    position: 'top-right',
    autoClose: 1000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ebayUrl = new URL(window.location).searchParams;

  const itemId = ebayUrl.get('itemID');
  const [selectedOption, setSelectedOption] = useState({
    category: '',
    subCategory: '',
  });
  const [hireR8trObject, setHireR8trObject] = useState({});
  const [buyerCostState, setBuyerCostState] = useState();
  const [jobLatitude, setJobLatitude] = useState(null);
  const [jobLongitude, setJobLongitude] = useState(null);
  const [r8tedVehicleAddress, setR8tedVehicleAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [minimumCost, setMinimumCost] = useState();
  const [checked, setChecked] = React.useState(false);
  const [itemIdDataObject, setItemIdDataObject] = useState();
  const { scrappedData } = useSelector((state) => state.BuyerJobReducer);
  const { allCategories } = useSelector(
    (state) => state.CategoriesExpertiseReducer
  );
  const { allCosts } = useSelector((state) => state.CategoriesExpertiseReducer);

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllCosts());
    if (itemId) {
      dispatch(scrappedDetails(itemId));
    }
  }, []);

  useEffect(() => {
    if (scrappedData) {
      setItemIdDataObject(scrappedData.data);
    }
  }, [scrappedData]);

  useEffect(() => {
    if (selectedOption.category || itemIdDataObject?.askingPrice) {
      handlePriceOfVehicle(
        itemIdDataObject?.askingPrice,
        selectedOption.category
      );
    }
  }, [selectedOption.category, itemIdDataObject?.askingPrice]);

  const handleChangeObject = (event) => {
    event.preventDefault();
    setHireR8trObject({
      ...hireR8trObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleItemDataChangeObject = (event) => {
    const tempItemIdDataObject = { ...itemIdDataObject };
    tempItemIdDataObject[event.target.name] = event.target.value;
    setItemIdDataObject(tempItemIdDataObject);
  };

  const handleCheckBoxChange = () => {
    setChecked(!checked);
  };

  const handleChange = (event) => {
    const { category, subCategory } = JSON.parse(event.target.value);
    setSelectedOption({ category, subCategory });
  };

  const handlePriceOfVehicle = (askingPrice, category) => {
    if (askingPrice && askingPrice > 0 && category) {
      if (category) {
        askingPrice = askingPrice * 1;
        let cost = allCosts.filter((el) => {
          return el.categoryId == category;
        });
        let categoryValue = cost[0];
        if (
          categoryValue.priceRangeLow <= askingPrice &&
          askingPrice <= categoryValue.priceRangeHigh
        ) {
          setBuyerCostState(categoryValue.minimumCost);
        } else if (askingPrice >= categoryValue.priceRangeHigh) {
          let buyerCost =
            categoryValue.minimumCost + askingPrice * categoryValue.multiplier;
          buyerCost = buyerCost.toFixed(2);
          setBuyerCostState(buyerCost);
        } else if (askingPrice < categoryValue.priceRangeLow) {
          setBuyerCostState(0);
        }
        setMinimumCost(categoryValue.priceRangeLow);
      }
    } else {
      setBuyerCostState(0);
    }
  };

  const handleSelectAddress = async (jobAddress) => {
    if (jobAddress) {
      try {
        let results = await geocodeByAddress(jobAddress);
        let latLng = await getLatLng(results[0]);
        for (let i = 0; i < results[0].address_components.length; i++) {
          if (
            results[0].address_components[i].types.includes(
              'administrative_area_level_1'
            )
          ) {
            setState(results[0].address_components[i].long_name);
          }

          if (
            results[0].address_components[i].types.includes(
              'administrative_area_level_2'
            )
          ) {
            setCity(results[0].address_components[i].long_name);
          }
        }
        setR8tedVehicleAddress(jobAddress);
        setJobLatitude(latLng.lat);
        setJobLongitude(latLng.lng);
      } catch (error) {
        console.error('Error', error);
        setJobLatitude(null);
        setJobLongitude(null);
      }
    }
  };

  const handleChangeAddress = (address) => {
    setR8tedVehicleAddress(address);
    setJobLatitude(null);
    setJobLongitude(null);
  };

  const handleSubmit = async () => {
    if (
      !hireR8trObject.name ||
      !hireR8trObject.email ||
      !hireR8trObject.email.includes('@') ||
      checked != true ||
      !selectedOption.category ||
      !selectedOption.subCategory ||
      !itemIdDataObject.askingPrice ||
      !itemIdDataObject.vIN ||
      !itemIdDataObject.year ||
      !itemIdDataObject.make ||
      !itemIdDataObject.model ||
      !hireR8trObject.description ||
      !hireR8trObject.zipCode ||
      hireR8trObject.zipCode < 1
    ) {
      toast.error('Please fill all fields properly', toastOptions);
      return;
    } else if (itemIdDataObject.askingPrice < minimumCost) {
      toast.error(
        `Asking Price should be greater than ${minimumCost}`,
        toastOptions
      );
      return;
    }

    let latitude = jobLatitude;
    let longitude = jobLongitude;
    if (!r8tedVehicleAddress || !city || !state) {
      toast.error(`${r8tedVehicleAddress} is an invalid address`, toastOptions);
      return;
    } else if (!latitude || !longitude) {
      try {
        let results = await geocodeByAddress(r8tedVehicleAddress);
        let latLng = await getLatLng(results[0]);
        latitude = latLng.lat;
        longitude = latLng.lng;
      } catch (error) {
        toast.error(
          `${r8tedVehicleAddress} is an invalid address`,
          toastOptions
        );
        return;
      }
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const finalObject = {
      categoryId: selectedOption.category,
      subCategoryId: selectedOption.subCategory,
      askingPrice: itemIdDataObject.askingPrice,
      buyerCost: buyerCostState,
      description: hireR8trObject.description,
      vinOrSerial: itemIdDataObject.vIN,
      make: itemIdDataObject.make,
      year: itemIdDataObject.year,
      model: itemIdDataObject.model,
      address: r8tedVehicleAddress,
      city: city,
      state: state,
      zip: hireR8trObject.zipCode,
      longitude: jobLongitude,
      latitude: jobLatitude,
      contactName: hireR8trObject.name,
      contactNumber: user?.user?.phoneNumber,
      contactEmail: hireR8trObject.email,
      isScrappedJob: true,
    };

    dispatch(completeCreateJob(finalObject));
    if (itemId) {
      navigate(`/submit-payment?itemID=${itemId}`);
    } else {
      navigate('/submit-payment');
    }
  };

  const { name, email, description, zipCode } = hireR8trObject;

  return (
    <div className="main_div_hire_a_r8tr">
      <ToastContainer />
      <Container className="d-flex justify-content-center align-items-center">
        <Box component="div" className="hire_a_r8tr_main_box">
          <ValidatorForm
            style={{
              width: '50% !important',
              // marginTop: "80px",
              // marginBottom: "15px",
              margin: '100px 50px 50px 50px',
            }}
            onSubmit={() => handleSubmit()}
            // onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: '50px', marginBottom: '10px' }}>
              <label className="text-uppercase fw-bold">Personal</label>
            </div>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={2}
              style={{ display: 'flex' }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    '& fieldset': { border: 'none' },
                  }}
                  label="YOUR NAME"
                  onChange={(event) => handleChangeObject(event)}
                  type="text"
                  name="name"
                  value={name || ''}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    '& fieldset': { border: 'none' },
                  }}
                  label="YOUR EMAIL"
                  onChange={(event) => handleChangeObject(event)}
                  type="email"
                  name="email"
                  value={email || ''}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <label className="text-uppercase fw-bold">
                Job Price Calculator
              </label>
            </div>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={2}
              style={{ display: 'flex' }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SelectValidator
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    '& fieldset': { border: 'none' },
                  }}
                  value={JSON.stringify(selectedOption)}
                  onChange={handleChange}
                  label="Select category along with subcategory"
                  inputProps={{
                    name: 'grouped-select',
                    id: 'grouped-select',
                  }}
                >
                  {allCategories.reduce(
                    (listItems, catagory) => [
                      ...listItems,
                      <ListSubheader key={catagory.id} value={catagory.id}>
                        {catagory.name}
                      </ListSubheader>,
                      ...catagory.subCategories.map((subCategory) => (
                        <MenuItem
                          key={subCategory.id}
                          value={JSON.stringify({
                            category: catagory.id,
                            subCategory: subCategory.id,
                          })}
                        >
                          <div className="ps-2">{subCategory.name}</div>
                        </MenuItem>
                      )),
                    ],
                    []
                  )}
                </SelectValidator>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    '& fieldset': { border: 'none' },
                  }}
                  label="Current Asking Price of Vehicle"
                  onChange={handleItemDataChangeObject}
                  type="number"
                  name="askingPrice"
                  value={
                    itemIdDataObject?.askingPrice
                      ? itemIdDataObject?.askingPrice
                      : ''
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    '& fieldset': { border: 'none' },
                  }}
                  disabled
                  type="text"
                  name="byerCost"
                  value={`$${buyerCostState ? buyerCostState : 0.0}`}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <label className="text-uppercase fw-bold">Job Details</label>
            </div>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={2}
              style={{ display: 'flex' }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    '& fieldset': { border: 'none' },
                  }}
                  label="Vin/Serial Number"
                  onChange={handleItemDataChangeObject}
                  type="text"
                  name="vIN"
                  value={itemIdDataObject?.vIN ? itemIdDataObject?.vIN : ''}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    '& fieldset': { border: 'none' },
                  }}
                  label="Year"
                  onChange={handleItemDataChangeObject}
                  type="number"
                  name="year"
                  value={itemIdDataObject?.year ? itemIdDataObject?.year : ''}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    '& fieldset': { border: 'none' },
                  }}
                  label="MAKE"
                  onChange={handleItemDataChangeObject}
                  type="text"
                  name="make"
                  value={itemIdDataObject?.make ? itemIdDataObject?.make : ''}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextValidator
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    '& fieldset': { border: 'none' },
                  }}
                  label="MODEL"
                  onChange={handleItemDataChangeObject}
                  type="text"
                  name="model"
                  value={itemIdDataObject?.model ? itemIdDataObject?.model : ''}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextValidator
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    '& fieldset': { border: 'none' },
                  }}
                  label="DESCRIPTION"
                  onChange={(event) => handleChangeObject(event)}
                  type="text"
                  name="description"
                  value={description}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <label className="text-uppercase fw-bold">Address Details</label>
            </div>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={2}
              style={{ display: 'flex' }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    height: '50px',
                    borderRadius: '5px',
                  }}
                >
                  <PlacesAutocomplete
                    value={r8tedVehicleAddress}
                    onChange={(address) => {
                      handleChangeAddress(address);
                    }}
                    onSelect={(address) => {
                      handleSelectAddress(address);
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div style={{ width: '100%' }}>
                        <input
                          {...getInputProps({
                            placeholder: 'ADDRESS OF THE VEHICLE TO BE R8TED',
                            className:
                              'ps-3 location-search-input input_address_details  w-100 ',
                          })}
                          style={{
                            width: '100%',
                            // backgroundColor: "red",
                            height: '50px',
                            borderRadius: '5px',
                          }}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, suggestionIndex) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            const style = suggestion.active
                              ? {
                                  backgroundColor: '#fafafa',
                                  cursor: 'pointer',
                                }
                              : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                };
                            return (
                              <div
                                key={suggestionIndex}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextValidator
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                    '& fieldset': { border: 'none' },
                  }}
                  label="ZIPCODE"
                  onChange={(event) => handleChangeObject(event)}
                  type="number"
                  name="zipCode"
                  value={zipCode}
                />
              </Grid>

              <div
                style={{
                  marginTop: '15px',
                  padding: '5px',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    // size="large"
                    sx={{
                      padding: 'none',
                      marginLeft: '0px',
                      marginRight: '0px',
                    }}
                    value={checked}
                    control={
                      <Checkbox
                        // checked={termsAndConditions}
                        onChange={handleCheckBoxChange}
                        size="small"
                      />
                    }
                    // label={`   I AGREE TO <a>TERMS & CONDITIONS</a> `}
                    // labelPlacement="end"
                  />
                  <span style={{ fontSize: '14px' }}>
                    I AGREE TO &nbsp;
                    <span
                      style={{
                        textDecoration: 'underline',
                        color: 'rgb(63, 63, 217)',
                        cursor: 'pointer',
                      }}
                    >
                      TERMS & CONDITIONS
                    </span>
                  </span>
                </span>
                <span style={{ width: '40%' }}>
                  <Button
                    type="submit"
                    style={{
                      width: '100%',
                      backgroundColor: '#d40000',
                      borderColor: '#e61d2a',
                      borderRadius: '0px 15px 0px 15px',
                      height: '40px',
                    }}
                  >
                    Next
                  </Button>
                </span>
              </div>
            </Grid>
          </ValidatorForm>
        </Box>
      </Container>
    </div>
  );
};

export default HireR8tr;
