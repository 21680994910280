import apiProtected from 'api/apiClient';
import {
  setLimitedNotificationData,
  setNotificationData,
} from 'redux/reducers/R8tr/R8trNotificationReducer';

import { ApiUrl } from '../../../ApiUrl';

export const R8trNotificationAction = (limit) => (dispatch) => {
  apiProtected
    .get(`${ApiUrl.server_url}v1/notifications/get-all-notifications`, {
      params: {
        limit,
      },
    })
    .then((res) => {
      if (limit) {
        dispatch(setLimitedNotificationData(res.data));
      } else {
        dispatch(setNotificationData(res.data));
      }
    });
};
