import "./HireR8tr.css";

import { Card } from "@mui/material";
import React, { useState } from "react";
import { Col, Container,Nav, Row, Tab } from "react-bootstrap";
import { toast,ToastContainer } from "react-toastify";

import jobdetails from "../../../assets/images/buyer/briefcase.webp";
import jobdetailsfilled from "../../../assets/images/buyer/briefcase-fill.webp";
import calculator from "../../../assets/images/buyer/calculator.webp";
import calculatorfilled from "../../../assets/images/buyer/calculator-fill.webp";
import location from "../../../assets/images/buyer/location.webp";
import locationfilled from "../../../assets/images/buyer/location-fill.png";
import telephone from "../../../assets/images/buyer/telephone.png";
import telephonefilled from "../../../assets/images/buyer/telephone-fill.png";
import AddressDetails from "../AddressDetails/AddressDetails";
import ContactDetails from "../ContactDetails/ContactDetails";
import JobDetails from "../JobDetails/JobDetails";
import JobPriceCalculator from "../JobPriceCalculator/JobPriceCaulator";
const JOB_PRICE_CALCULATOR_KEY = "JOB_PRICE_CALCULATOR";
const JOB_DETAILS_KEY = "JOB_DETAILS";
const ADDRESS_DETAILS_KEY = "ADDRESS_DETAILS_KEY";
const CONTACT_DETAILS_KEY = "CONTACT_DETAILS_KEY";

const HireR8tr = () => {
  const [activeKey, setActiveKey] = useState(JOB_PRICE_CALCULATOR_KEY);
  const showCalculatorIcon = activeKey === JOB_PRICE_CALCULATOR_KEY;
  const showJoBDetailsIcon = activeKey === JOB_DETAILS_KEY;
  const showAddressDetailsIcon = activeKey === ADDRESS_DETAILS_KEY;
  const showContactDetailsIcon = activeKey === CONTACT_DETAILS_KEY;
  const [priceCal, setPriceCal] = useState(false);
  const [jobDetails, setJobDetails] = useState(true);
  const [addressDetails, setAddressDetails] = useState(true);
  const [contactDetails, setContactDetails] = useState(true);

  const toastOptions = {
    style: { marginTop: "50px" },
    position: "top-right",
    autoClose: 1000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };
  const onPressNext = () => {
    if (activeKey == JOB_PRICE_CALCULATOR_KEY) {
      setPriceCal(false);
      setJobDetails(false);
      setActiveKey(JOB_DETAILS_KEY);
    } else if (activeKey == JOB_DETAILS_KEY) {
      setPriceCal(false);
      setJobDetails(false);
      setAddressDetails(false);
      setActiveKey(ADDRESS_DETAILS_KEY);
    } else if (activeKey == ADDRESS_DETAILS_KEY) {
      setPriceCal(false);
      setJobDetails(false);
      setAddressDetails(false);
      setContactDetails(false);
      setActiveKey(CONTACT_DETAILS_KEY);
    } else if (activeKey == CONTACT_DETAILS_KEY) {
      setPriceCal(false);
      setJobDetails(false);
      setAddressDetails(false);
      setContactDetails(false);
      setActiveKey(CONTACT_DETAILS_KEY); // Forward to payment screen here ...
    }
  };
  const handleTosterFire = (message) => {
    toast.error(message, toastOptions);
  };
  return (
    <div
      className={`theme_text_color hire_r8tr_main_div ${
        activeKey === JOB_PRICE_CALCULATOR_KEY
          ? "priceCalculator"
          : activeKey === JOB_DETAILS_KEY
          ? "jobDetail"
          : activeKey === ADDRESS_DETAILS_KEY
          ? "addressDetail"
          : "contactDetail"
      }`}
    >
      <ToastContainer />
      <Container className="hire_r8tr_main_container">
        <Row className="row_r8tr">
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
            <Tab.Container activeKey={activeKey} onSelect={setActiveKey}>
              <Card
                style={{
                  width: "100%",
                  height: "500px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Nav variant="tabs" className="hire_r8tr_grid_tab_div">
                  <Row className="hire_r8tr_row_style">
                    <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={JOB_PRICE_CALCULATOR_KEY}
                          disabled={priceCal ? true : false}
                        >
                          <div className="hire_r8tr_grid_tab_div py-3">
                            <span className="w-100">
                              {showCalculatorIcon ? (
                                <img
                                  src={calculatorfilled}
                                  alt="calculator"
                                  className="nav_imgheading"
                                />
                              ) : (
                                <img
                                  src={calculator}
                                  alt="calculator"
                                  className="nav_imgheading"
                                />
                              )}
                            </span>
                            <span className="w-100 text-uppercase Font_Family theme_text_color fw-bold tab_heading_text">
                              Job price
                              <span className="hireR8tr_price_calculator">
                                calculator
                              </span>
                            </span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={JOB_DETAILS_KEY}
                          disabled={jobDetails ? true : false}
                        >
                          <div className="hire_r8tr_grid_tab_div py-3">
                            <span>
                              {showJoBDetailsIcon ? (
                                <img
                                  src={jobdetailsfilled}
                                  alt="jobdetails"
                                  className="nav_imgheading"
                                />
                              ) : (
                                <img
                                  src={jobdetails}
                                  alt="jobdetails"
                                  className="nav_imgheading"
                                />
                              )}
                            </span>
                            <span className="text-uppercase Font_Family theme_text_color fw-bold tab_heading_text">
                              Job details
                            </span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={ADDRESS_DETAILS_KEY}
                          disabled={addressDetails ? true : false}
                        >
                          <div className="hire_r8tr_grid_tab_div py-3">
                            <span>
                              {showAddressDetailsIcon ? (
                                <img
                                  src={locationfilled}
                                  alt="location"
                                  className="nav_imgheading"
                                />
                              ) : (
                                <img
                                  src={location}
                                  alt="location"
                                  className="nav_imgheading"
                                />
                              )}
                            </span>
                            <span className="text-uppercase Font_Family theme_text_color fw-bold tab_heading_text">
                              ADDRESS DETAILS
                            </span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={CONTACT_DETAILS_KEY}
                          disabled={contactDetails ? true : false}
                        >
                          <div className="hire_r8tr_grid_tab_div py-3">
                            <span>
                              {showContactDetailsIcon ? (
                                <img
                                  src={telephonefilled}
                                  alt="telephone"
                                  className="nav_imgheading"
                                />
                              ) : (
                                <img
                                  src={telephone}
                                  alt="telephone"
                                  className="nav_imgheading"
                                />
                              )}
                            </span>
                            <span className="text-uppercase Font_Family theme_text_color fw-bold tab_heading_text">
                              CONTACT DETAILS
                            </span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                  </Row>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey={JOB_PRICE_CALCULATOR_KEY}>
                    <JobPriceCalculator
                      onPressNext={onPressNext}
                      handleTosterFire={handleTosterFire}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey={JOB_DETAILS_KEY}>
                    <JobDetails
                      onPressNext={onPressNext}
                      handleTosterFire={handleTosterFire}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey={ADDRESS_DETAILS_KEY}>
                    <AddressDetails
                      onPressNext={onPressNext}
                      handleTosterFire={handleTosterFire}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey={CONTACT_DETAILS_KEY}>
                    <ContactDetails
                      onPressNext={onPressNext}
                      handleTosterFire={handleTosterFire}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Card>
            </Tab.Container>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={0} xs={0}></Col>
        </Row>
      </Container>
    </div>
  );
};

export default HireR8tr;
