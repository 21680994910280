import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PublicRoutes = () => {
  const tokens = JSON.parse(localStorage.getItem('tokens'));

  const { isLoggedIn } = useSelector((state) => state.loginSlice);

  return isLoggedIn && tokens?.accessToken ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoutes;
