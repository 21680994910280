import { Grid } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import apiProtected from 'api/apiClient';
import DropIn from 'braintree-web-drop-in-react';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { completeCreateJob } from 'redux/reducers/buyer/HireR8trReducer';
import { useDispatch, useSelector } from 'redux/store';

import { ApiUrl } from '../../../ApiUrl';
import VerifyCoupon from '../VerifyCoupon/VerifyCoupon';
const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};
const SubmitPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createJob } = useSelector((state) => state.HireR8trReducer);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientToken, setClientToken] = useState(null);
  const [discountData, setDiscountData] = useState();
  const [discountCriteria, setDiscountCriteria] = useState();
  const [instance, setInstance] = useState(null);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  const [buyerCost, setBuyerCost] = useState(createJob?.buyerCost);

  const dropinRef = useRef();

  useEffect(() => {
    // Function to check for error elements in the DOM
    const checkForErrors = () => {
      const expirationError = document.getElementById(
        'expiration-date-field-error'
      );
      const numberError = document.getElementById('number-field-error');
      const sheetError = document.getElementById('sheet-error-text');

      if (expirationError || numberError || sheetError) {
        setSubmitDisabled(true);
      } else {
        checkIfPaymentMethodIsRequestable(instance);
      }
    };

    // Attach mutation observer to monitor DOM changes
    const observer = new MutationObserver(checkForErrors);
    observer.observe(document, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [instance]);

  const handleInstance = (dropInInstance) => {
    setInstance(dropInInstance);

    dropInInstance.on('change', () => {
      checkIfPaymentMethodIsRequestable(dropInInstance);
    });

    // Initial check when the component mounts
    checkIfPaymentMethodIsRequestable(dropInInstance);
  };

  const checkIfPaymentMethodIsRequestable = (dropInInstance) => {
    if (!dropInInstance) return;

    const isReady = dropInInstance.isPaymentMethodRequestable();

    setSubmitDisabled(!isReady);
  };

  const handleCouponDiscountAmount = (res) => {
    if (res) {
      dispatch(
        completeCreateJob({
          ...createJob,
          discountedBuyerCost: res?.data?.data?.finalBuyerCost,
        })
      );
      setDiscountData(res?.data?.data?.finalBuyerCost);
      setDiscountCriteria(res?.data?.data?.discountCriteria);
    }
  };

  useEffect(() => {
    (async () => {
      const res = await apiProtected.get(
        `${ApiUrl.server_url}v1/braintree/get-client-token`
      );

      setClientToken(res.data.data);
    })();
  }, []);

  useEffect(() => {
    setBuyerCost(createJob?.buyerCost);
    apiProtected
      .get(
        `${ApiUrl.server_url}v1/discounts/calculate-discount-for-buyer?buyerCost=${createJob?.buyerCost}`
      )
      .then((res) => {
        if (res) {
          dispatch(
            completeCreateJob({
              ...createJob,
              discountedBuyerCost: res?.data?.data?.finalBuyerCost,
            })
          );
          setDiscountData(res?.data?.data?.finalBuyerCost);
          setDiscountCriteria(res?.data?.data?.discountCriteria);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [createJob?.buyerCost]);

  const handleAddCouponModal = () => {
    setOpenModal(!openModal);
  };

  const handleSubmitPayment = async () => {
    try {
      setLoading(true);
      const { nonce } = await instance.requestPaymentMethod();
      const body = { ...createJob, nonce };

      const { data } = await apiProtected.post(
        `${ApiUrl.server_url}v1/jobs/`,
        body
      );
      setLoading(false);
      toast.success(data.message, toastOptions);

      navigate('/');
    } catch (error) {
      setLoading(false);
      const errorMassage = error.response.data.message;
      toast.error(errorMassage, toastOptions);
    }
  };

  if (!clientToken) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!clientToken}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <>
        <ToastContainer />
        <br></br>
        <Grid container>
          <Grid item md={2} lg={2}></Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <p>HIRE A R8TR PAYMENT</p>
            <hr></hr>
            <br></br>
            <p>PAYMENT INFORMATION</p>
            <hr></hr>
            <br></br>
            POINT OF CONTACT NAME
            <br></br>
            {createJob?.contactName}
            <br></br>
            <br></br>
            DESCRIPTION :<br></br>
            {createJob?.description}
            <br></br>
            <br></br>
            <span className="mt-4">
              TOTAL COST :
              {discountData ? (
                <span style={{ color: 'red', textDecoration: 'line-through' }}>
                  ${buyerCost}
                </span>
              ) : (
                <span style={{ color: 'red' }}>${createJob?.buyerCost}</span>
              )}
            </span>
            <span className="mt-6">
              {discountCriteria && (
                <>
                  <br></br>
                  <br></br>
                  DISCOUNT :
                  <span style={{ color: 'red' }}> {discountCriteria} </span>
                </>
              )}
            </span>
            <span className="mt-6">
              {discountData && (
                <>
                  <br></br>
                  <br></br>
                  DISCOUNTED PRICE :
                  <span style={{ color: 'red' }}> ${discountData} </span>
                </>
              )}
            </span>
            {!discountData && (
              <span className="mt-4">
                <br></br>
                <br></br>
                <Button
                  // className="w-25"
                  style={{
                    backgroundColor: '#d40000',
                    borderColor: '#d40000',
                    borderRadius: '0px 15px 0px 15px',
                    height: '50px',
                  }}
                  onClick={handleAddCouponModal}
                >
                  <span className="d-flex justify-content-center align-content-center w-100">
                    {/* {loading ? <Loader /> : <span>Update</span>} */}
                    APPLY COUPON
                  </span>
                </Button>
              </span>
            )}
          </Grid>
          <Grid item md={2} lg={2}></Grid>
        </Grid>
        <Grid container>
          <Grid item md={2} lg={2}></Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <hr></hr>
            <div style={{ marginTop: '50px' }}>
              <DropIn
                ref={dropinRef}
                options={{
                  authorization: clientToken,
                  vaultManager: true,
                }}
                onInstance={handleInstance}
              />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  disabled={isSubmitDisabled}
                  className="w-25"
                  style={{
                    backgroundColor: '#d40000',
                    borderColor: '#d40000',
                    borderRadius: '0px 15px 0px 15px',
                    height: '50px',
                  }}
                  onClick={handleSubmitPayment}
                >
                  <span className="d-flex justify-content-center align-content-center w-100">
                    SUBMIT PAYMENT
                  </span>
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item md={2} lg={2}></Grid>
        </Grid>
        <br></br>
        <br></br>
        <Backdrop
          key={loading}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" style={{ justifySelf: 'center' }} />
        </Backdrop>
        {openModal && (
          <VerifyCoupon
            open={openModal}
            cost={createJob?.buyerCost}
            handleAddCouponModal={handleAddCouponModal}
            handleCouponDiscountAmount={handleCouponDiscountAmount}
          />
        )}
      </>
    );
  }
};

export default SubmitPayment;
