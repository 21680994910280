import './uploadedvideos.css';

import apiProtected from 'api/apiClient';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';

import { ApiUrl } from '../../../ApiUrl';
function UploadedVideos({ jobData }) {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    apiProtected
      .get(
        `${ApiUrl.server_url}v1/rating-criteria-users/get-rating-videos?jobId=${jobData.id}`
      )
      .then((res) => {
        console.log(res);
        setVideos(res?.data?.data);
      });
  }, []);

  return (
    <div style={{ marginTop: '50px' }}>
      <Container className="r8ting_main_container">
        <Row className="VideoUpload_main_row">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            style={{ borderBottom: '1px solid #a7a9ac' }}
          >
            <p
              className="theme_text_color Font_Family text-uppercase fw-bold"
              style={{ fontSize: '18px' }}
            >
              Walk Around Videos
            </p>
          </Col>
          <Col xxl={8} xl={8} lg={8} md={10} sm={12} xs={12}>
            <Row style={{ marginTop: '10px' }}>
              {videos.length > 0 ? (
                videos.map((item) => (
                  <Col
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    key={item}
                    className="d-flex flex-lg-column justify-content-lg-center "
                  >
                    <div
                      style={{
                        borderRadius: '10px',
                        width: '100%',
                      }}
                    >
                      <ReactPlayer
                        url={item.url}
                        width="100%"
                        height="120px"
                        playIcon={'play'}
                        controls={true}
                        style={{
                          borderRadius: '10px',
                        }}
                      />
                    </div>
                  </Col>
                ))
              ) : (
                <Col
                  xs={6}
                  sm={4}
                  md={3}
                  lg={3}
                  style={{
                    width: '100%',
                  }}
                  className="d-flex flex-lg-column justify-content-lg-center"
                >
                  <div
                    style={{
                      borderRadius: '10px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}
                  >
                    No Video Available
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default UploadedVideos;
