import './JobDetails.css';

import { Container } from '@mui/material';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { BsAsterisk } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { completeCreateJob } from 'redux/reducers/buyer/HireR8trReducer';
import swalWithBootstrapButtons from 'sweetalert2';

const JobDetails = ({ onPressNext, handleTosterFire }) => {
  const dispatch = useDispatch();
  const [description, setDiscription] = useState('');
  const [serialNumber, setSerialNumber] = useState();
  const [year, setYear] = useState();
  const [make, setMake] = useState();
  const [model, setModel] = useState();
  const navigate = useNavigate();

  const handleDiscriptionChange = (event) => {
    setDiscription(event.target.value);
  };
  const handleSerialNumberChange = (event) => {
    setSerialNumber(event.target.value);
  };
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const handleMakeChange = (event) => {
    setMake(event.target.value);
  };
  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const { createJob } = useSelector((state) => state.HireR8trReducer);
  const handleJobsCancelled = () => {
    swalWithBootstrapButtons
      .fire({
        title:
          'Do you really want to go back to dashboard without creating job ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel it!',
        cancelButtonText: "No, don't cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success',
        },
        buttonsStyling: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // Swal.fire("Canceled Job!", "Your job has been canceled.", "success");
          navigate('/');
        }
        // status = 1;
      });
  };
  const handleSubmit = () => {
    if (!description) {
      handleTosterFire('Please fill the required fields');
      return;
    }

    let data = {
      description: description,
      vinOrSerial: serialNumber,
      year: year,
      make: make,
      model: model,
      categoryId: createJob.categoryId,
      subCategoryId: createJob.subCategoryId,
      buyerCost: createJob.buyerCost,
      askingPrice: createJob.askingPrice,
    };
    dispatch(completeCreateJob(data));
    onPressNext();
  };
  return (
    <div className="theme_text_color Font_Family job_detail_main_div">
      <Container>
        <div className="w-100 fw-bold mt-3 ps-2 div_title_job_details theme_text_color">
          HIRE A R8TR STEP 2
        </div>

        {/* input field div */}
        <div className="ps-2 div_style_job_details justify-content-between">
          <input
            className="input_job_details  w-100 "
            type="text"
            name="description"
            placeholder="DESCRIBE VEHICLE YOU WANT R8TRED!"
            required
            value={description || ''}
            onChange={handleDiscriptionChange}
            style={{ border: 'none' }}
          />
          <BsAsterisk style={{ color: '#a7a9ac', fontSize: '10px' }} />
        </div>

        <div className="ps-2 div_style_job_details justify-content-between">
          <input
            className="input_job_details  w-100"
            type="text"
            name="serialNummber"
            placeholder="VIN/ SERIAL NUMBER"
            // required
            value={serialNumber || ''}
            onChange={handleSerialNumberChange}
            min={1}
            style={{ border: 'none' }}
          />
        </div>
        <div className="ps-2 div_style_job_details justify-content-between">
          <input
            className="input_job_details  w-100 "
            type="text"
            name="year"
            placeholder="YEAR"
            required
            value={year || ''}
            onChange={handleYearChange}
            min={1}
            style={{ border: 'none' }}
          />
        </div>
        <div className="ps-2 div_style_job_details justify-content-between">
          <input
            className="input_job_details  w-100 "
            type="text"
            name="make"
            placeholder="MAKE"
            required
            value={make || ''}
            onChange={handleMakeChange}
            style={{ border: 'none' }}
          />
        </div>
        <div className="ps-2 div_style_job_details justify-content-between">
          <input
            className="input_job_details  w-100 "
            type="text"
            name="model"
            placeholder="MODEL"
            required
            value={model || ''}
            onChange={handleModelChange}
            min={1}
            style={{ border: 'none' }}
          />
        </div>

        <div className="my-3 text-center">
          <Button
            className="job_price_button theme_text_color"
            style={{
              backgroundColor: '#d40000',
              borderColor: '#e61d2a',
              borderRadius: '0px 15px 0px 15px',
              height: '40px',
            }}
            // disabled={!description}
            type="button"
            onClick={handleSubmit}
          >
            <span className="d-flex justify-content-center align-content-center w-100">
              NEXT
            </span>
          </Button>
          <Button
            className="job_price_button theme_text_color mx-3"
            style={{
              backgroundColor: 'black',
              borderColor: 'black',
              borderRadius: '0px 15px 0px 15px',
              height: '40px',
            }}
            type="button"
            onClick={handleJobsCancelled}
          >
            <span className="d-flex justify-content-center align-content-center w-100 text-uppercase">
              Cancel
            </span>
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default JobDetails;
