import './JobPriceCalculator.css';

import { Checkbox, Container, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BsAsterisk } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { completeCreateJob } from 'redux/reducers/buyer/HireR8trReducer';
import swalWithBootstrapButtons from 'sweetalert2';

import {
  getAllCategories,
  getAllCosts,
} from '../../../redux/actions/CategoriesExpertiseAction';
import TermConditionDialog from '../Term&ConditionDialog/TermConditionDialog';

const JobPriceCaulator = ({ onPressNext, handleTosterFire }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showCategory, setShowCategory] = useState(false);
  const [showSubCategory, setShowSubCategory] = useState(false);
  const [askingPrice, setAskingPrice] = useState();
  const [buyerCostState, setBuyerCostState] = useState();
  const [minimumCost, setMinimumCost] = useState();
  const [openDialoge, setOpenDialoge] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllCosts());
  }, []);

  const { allCategories, allCosts } = useSelector(
    (state) => state.CategoriesExpertiseReducer
  );
  const handleShowCategory = () => {
    setShowCategory(!showCategory);
    setShowSubCategory(false);
  };

  const handleShowSubCategory = () => {
    if (!selectedCategory) {
      setShowSubCategory(false);
    } else {
      setShowSubCategory(!showSubCategory);
    }
    setShowCategory(false);
  };

  const handleSelectedCategory = (category) => {
    setAskingPrice(null);
    setSelectedCategory(category);
    setSelectedSubCategory('');
    setShowCategory(!showCategory);
  };
  const handleSelectedSubCategory = (subCategory) => {
    setAskingPrice(null);
    setSelectedSubCategory(subCategory);
    setShowSubCategory(!showSubCategory);
  };

  const handleAskingPrice = (event) => {
    setAskingPrice(event.target.value);
    handlePriceOfVehicle(event.target.value);
  };

  const handlePriceOfVehicle = (askingPrice) => {
    if (askingPrice && askingPrice > 0) {
      if (selectedCategory.id) {
        askingPrice = askingPrice * 1;
        let cost = allCosts.filter((el) => {
          return el.categoryId == selectedCategory.id;
        });
        let categoryValue = cost[0];
        if (
          categoryValue.priceRangeLow <= askingPrice &&
          askingPrice <= categoryValue.priceRangeHigh
        ) {
          setBuyerCostState(categoryValue.minimumCost);
        } else if (askingPrice >= categoryValue.priceRangeHigh) {
          let buyerCost =
            categoryValue.minimumCost + askingPrice * categoryValue.multiplier;
          buyerCost = buyerCost.toFixed(2);
          setBuyerCostState(buyerCost);
        } else if (askingPrice < categoryValue.priceRangeLow) {
          setBuyerCostState(0);
        }
        setMinimumCost(categoryValue.priceRangeLow);
      }
    } else {
      setBuyerCostState(0);
    }
  };
  const handleJobsCancelled = () => {
    swalWithBootstrapButtons
      .fire({
        title:
          'Do you really want to go back to dashboard without creating job ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel it!',
        cancelButtonText: "No, don't cancel!",
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success',
        },
        buttonsStyling: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // Swal.fire("Canceled Job!", "Your job has been canceled.", "success");
          navigate('/');
        }
        // status = 1;
      });
  };
  const handleCheckBoxChange = () => {
    setChecked(!checked);
  };
  const handleDialogBox = () => {
    setOpenDialoge(!openDialoge);
  };
  const handleSubmit = () => {
    if (
      checked != true ||
      !selectedCategory ||
      !selectedSubCategory ||
      !askingPrice
    ) {
      handleTosterFire('Please fill the required fields');
      return;
    } else if (askingPrice < minimumCost) {
      handleTosterFire(`Asking Price should be greater than ${minimumCost}`);
      return;
    }
    let data = {
      categoryId: selectedCategory.id,
      subCategoryId: selectedSubCategory.id,
      buyerCost: buyerCostState,
      askingPrice: askingPrice,
    };
    dispatch(completeCreateJob(data));
    onPressNext();
  };
  return (
    <div className="theme_text_color Font_Family job_price_main_div_style">
      <Container>
        {/* <ToastContainer /> */}
        <div className="w-100 fw-bold mt-3 ps-2 div_title theme_text_color">
          HIRE A R8TR STEP 1
        </div>
        <div
          className="w-100  ps-2 div_style theme_text_color"
          onClick={handleShowCategory}
        >
          {selectedCategory ? selectedCategory.name : 'VEHICLE CATEGORY'}
        </div>
        <div
          className="parent_category_div_style"
          style={{
            height: showCategory ? `${allCategories?.length * 25}px` : '0px',
          }}
        >
          {showCategory &&
            allCategories.map((category) => (
              <div
                className="category_div_style silver_Color"
                key={category.id}
                onClick={() => handleSelectedCategory(category)}
              >
                {category.name}
              </div>
            ))}
        </div>

        {/* SubCategory div */}
        <div
          className="w-100  ps-2 div_style theme_text_color"
          onClick={handleShowSubCategory}
        >
          {selectedSubCategory
            ? selectedSubCategory.name
            : 'VEHICLE SUBCATEGORY'}
        </div>
        <div
          className="parent_sub_category_div_style"
          style={{
            height: showSubCategory
              ? `${selectedCategory.subCategories?.length * 30}px`
              : '0px',
          }}
        >
          {showSubCategory &&
            selectedCategory &&
            selectedCategory.subCategories.map((subCategory) => (
              <div
                className="category_div_style silver_Color"
                key={subCategory.id}
                onClick={() => handleSelectedSubCategory(subCategory)}
              >
                {subCategory.name}
              </div>
            ))}
        </div>

        {/* input field div */}
        <div className="div_style justify-content-between">
          <input
            className="w-100  ps-2 input_job_calculator"
            type="number"
            name="askingPrice"
            placeholder="CURRENT ASKING PRICE OF VEHICLE"
            required
            value={askingPrice || ''}
            onChange={handleAskingPrice}
            min={0}
            style={{ border: 'none' }}
          />
          <BsAsterisk style={{ color: '#a7a9ac', fontSize: '10px' }} />
        </div>

        {/* Price div */}
        <div className="mt-2 checkBox_price_div w-100">
          <span className="term_condition_span_container">
            <FormControlLabel
              // size="large"
              sx={{ padding: 'none', marginLeft: '0px', marginRight: '0px' }}
              value={checked}
              control={<Checkbox onChange={handleCheckBoxChange} />}
              // label={`   I AGREE TO <a>TERMS & CONDITIONS</a> `}
              // labelPlacement="end"
            />
            <span className="term_condition_span_text">
              I AGREE TO &nbsp;
              <span className="term_condition_span" onClick={handleDialogBox}>
                TERMS & CONDITIONS
              </span>
            </span>
          </span>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#d40000',
              fontSize: '32px',
            }}
          >
            {/* <AttachMoney fontSize="large" /> */}
            <span>${buyerCostState || '0.00'}</span>
          </span>
        </div>
        <div className="my-3 text-center w-100">
          <Button
            className="job_price_button theme_text_color"
            style={{
              backgroundColor: '#d40000',
              borderColor: '#e61d2a',
              borderRadius: '0px 15px 0px 15px',
              height: '40px',
            }}
            // disabled={
            //   checked != true ||
            //   !selectedCategory ||
            //   !selectedSubCategory ||
            //   !askingPrice ||
            //   askingPrice < 1
            //     ? true
            //     : false
            // }
            type="button"
            onClick={handleSubmit}
          >
            <span className="d-flex justify-content-center align-content-center w-100">
              ACCEPT
            </span>
          </Button>

          <Button
            className="job_price_button theme_text_color mx-3"
            style={{
              backgroundColor: 'black',
              borderColor: 'black',
              borderRadius: '0px 15px 0px 15px',
              height: '40px',
            }}
            type="button"
            onClick={handleJobsCancelled}
          >
            <span className="d-flex justify-content-center align-content-center w-100 text-uppercase">
              Cancel
            </span>
          </Button>
        </div>
      </Container>
      {openDialoge && <TermConditionDialog handleDialogBox={handleDialogBox} />}
    </div>
  );
};

export default JobPriceCaulator;
