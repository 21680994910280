import './forgotpassword.css';

import { LockOutlined } from '@mui/icons-material';
import { api } from 'api/apiClient';
import { useContext, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../ApiUrl';
import SubAccountContext from '../../utils/SubAccountContext';
import Loader from '../Loader/Loader';
export default function ForgetPassword() {
  const ebayUrl = new URL(window.location).searchParams;

  const itemId = ebayUrl.get('itemID');

  const parentId = useContext(SubAccountContext);
  const navigator = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [loading, setLoading] = useState(false);

  const toastOptions = {
    position: 'top-right',
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  };
  const handlepasswordChange = () => {
    setLoading(true);
    api
      .patch(`${ApiUrl.server_url}v1/auth/reset-password`, {
        phoneNumber: location.state.phoneNumber,
        password: newPassword,
        confirmPassword: confirmPassword,
        parentUserId: parentId.userId,
      })
      .then((res) => {
        setLoading(false);

        localStorage.setItem(
          'tokens',
          JSON.stringify({
            accessToken: res.data.data.token,
            refreshToken: res.data.data.refreshToken,
          })
        );
        localStorage.setItem('user', JSON.stringify(res.data.data));
        localStorage.setItem('userRole', res.data.data.userRole);
        if (itemId) {
          navigator(`/ebay-hire-a-r8tr?itemID=${itemId}`);
        } else {
          if (location?.state?.title == 'Reset Password') {
            navigator('/login');
          } else {
            if (res.data.data.userRole == 'buyer') {
              navigator('/');
            } else {
              navigator('/personal-info');
            }
          }
        }
      })
      .catch((error) => {
        let errorMessage = error.response.data.message;

        toast.error(
          errorMessage ? errorMessage : 'Something went wrong',
          toastOptions
        );
        setLoading(false);
      });
  };

  return (
    <div className="forgotpassword_container">
      <ToastContainer />
      <Container className="forgotpassword_row_container">
        <Row className="forgotpassword_row_container">
          <Col xxl={4} xl={4} lg={4} md={5} sm={12} xs={12}>
            <Form className="w-100">
              <p className="text-center h3 mb-5 text-bold Font_Family forgotpassword_p_tage">
                {location.state.title}
              </p>
              <div className="forgotpassword-input-container">
                <i className="fa fa-user forgotpassword_icon">
                  <LockOutlined className="signup_input_icon" />
                </i>
                <input
                  className="forgotpassword-input-field"
                  type="password"
                  placeholder="NEW PASSWORD"
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  style={{ borderBottomLeftRadius: '0px' }}
                />
              </div>
              <div className="forgotpassword-input-container ">
                <i className="fa fa-user forgotpassword_icon">
                  <LockOutlined className="signup_input_icon" />
                </i>
                <input
                  className="forgotpassword-input-field"
                  type="password"
                  placeholder="CONFIRM PASSWORD"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  style={{ borderBottomLeftRadius: '0px' }}
                />
              </div>
              <div className="text-center w-100">
                <Button
                  className="w-100 Font_Family"
                  style={{
                    backgroundColor: '#d40000',
                    borderColor: '#d40000',
                    borderRadius: '0px 25px 0px 25px',
                  }}
                  size="lg"
                  type="button"
                  onClick={handlepasswordChange}
                >
                  <span className="d-flex justify-content-center align-content-center w-100">
                    {loading ? <Loader /> : <span>SUBMIT</span>}
                  </span>
                </Button>
              </div>
            </Form>
          </Col>
          <Col
            xxl={6}
            lg={6}
            md={6}
            sm={0}
            xs={0}
            className="forgotpassword_column_two"
          ></Col>
        </Row>
      </Container>
    </div>
  );
}
