import apiProtected from 'api/apiClient';
import {
  getBuyerCurrentJobs,
  getBuyerPastJobs,
} from 'redux/reducers/buyer/BuyerJobReducer';

import { ApiUrl } from '../../../ApiUrl';

export const getAllBuyerCurrentJob = () => (dispatch) => {
  apiProtected
    .get(ApiUrl.server_url + 'v1/jobs/get-current-jobs-for-buyer')
    .then((res) => {
      dispatch(getBuyerCurrentJobs(res.data));
    });
};

export const getAllBuyerPastJob = () => (dispatch) => {
  apiProtected
    .get(ApiUrl.server_url + 'v1/jobs/get-past-jobs-for-buyer')
    .then((res) => {
      dispatch(getBuyerPastJobs(res.data));
    });
};

export const createBuyerJob = (data) => (dispatch) => {
  apiProtected.get(ApiUrl.server_url + 'v1/jobs/', data).then((res) => {
    dispatch(getAllBuyerCurrentJob());
  });
};

export const scrappedDetails = (itemId) => (dispatch) => {
  apiProtected
    .get(`${ApiUrl.server_url}v1/scrap/scrapped-item-details`, {
      params: { itemId },
    })
    .then((res) => {
      dispatch(scrappedDetails(res.data.data));
    })
    .catch((error) => {
      console.log('error: ' + error);
    });
};
