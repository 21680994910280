import "./LevelOfExpertise.css";

import { Slider,Typography } from "@mui/material";
import React, { useEffect,useState } from "react";
import { Button, Col,Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast,ToastContainer } from "react-toastify";

import {
  getUserData,
  updateLevelOfExperties,
} from "../../../redux/actions/UserAction";
import Loader from "./../../Loader/Loader";

const LevelOfExpertise = () => {
  const dispatch = useDispatch();
  const [isReadonly, setIsReadonly] = useState(true);
  const [levelOfExperties, setLevelOfExperties] = useState([]);
  const { userDetails } = useSelector((state) => state.UserReducer);
  const [loading, setLoading] = useState(false);
  const toastOptions = {
    position: "top-right",
    autoClose: 4000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  useEffect(() => {
    if (userDetails) {
      let levels = userDetails.data?.levelOfExpertise;
      setLevelOfExperties(levels);
    }
  }, [userDetails]);

  let handleReadOnly = () => {
    setIsReadonly(!isReadonly);
  };

  let handleChangeExperityLevel = (index, event) => {
    let expertiesLevels = [...levelOfExperties];
    expertiesLevels[index].selectedValue = event.target.value;
    setLevelOfExperties(expertiesLevels);
  };

  const handleResponse = (data) => {
    if (data.status) {
      setIsReadonly(true);
      setLoading(false);
      toast.success(data.message, toastOptions);
    } else {
      setLoading(false);
      toast.error(data.message, toastOptions);
    }
  };

  let handleSubmit = () => {
    setLoading(true);
    dispatch(updateLevelOfExperties(levelOfExperties, handleResponse));
  };

  return (
    <div className="Font_Family theme_text_color level_expertise_main_div">
      <Container>
        <ToastContainer />
        <Row className="level_expertise_row_container">
          <Col xxl={8} xl={8} lg={8} md={8} sm={11} xs={11}>
            <div className="level_expertise_heading_div">
              <Typography variant="h6" component="div">
                LEVEL OF EXPERTISE
              </Typography>
              <Button
                className="text-uppercase"
                style={{
                  color: "#d40000",
                  backgroundColor: "white",
                  borderColor: "white",
                  fontWeight: "bold",
                  boxShadow: "none",
                  outline: "none",
                }}
                type="submit"
                onClick={handleReadOnly}
              >
                {isReadonly ? <span>Edit</span> : <span>Cancel</span>}
              </Button>
            </div>

            <form>
              <Typography variant="h6" component="div">
                {levelOfExperties?.map((category, index) => (
                  <div key={category.value}>
                    <span
                      className="category_span_container fw-bold text-uppercase ps-3"
                      style={{ fontSize: "16px" }}
                    >
                      {category.key}
                      <span className="category_span text-center">
                        <p
                          className="text-center"
                          style={{ fontSize: "12px", color: "white" }}
                        >
                          {category.selectedValue}
                        </p>
                      </span>
                    </span>
                    <Slider
                      size="meduim"
                      aria-label="Temperature"
                      defaultValue={category.selectedValue}
                      valueLabelDisplay="auto"
                      disabled={isReadonly}
                      onChange={(event) => {
                        handleChangeExperityLevel(index, event);
                      }}
                      step={1}
                      marks
                      min={0}
                      max={8}
                      sx={{
                        color: "#edf0f8",
                        "& .MuiSlider-thumb": {
                          color: "#414042",
                          height: "15px",
                          width: "15px",
                        },
                        height: "1.5px",
                      }}
                    />
                  </div>
                ))}
              </Typography>
              <div className="my-5 text-center">
                {isReadonly == true ? (
                  <Button
                    className="level_expertise_button text-uppercase"
                    style={{
                      backgroundColor: "#d40000",
                      borderColor: "#d40000",
                      borderRadius: "0px 15px 0px 15px",
                      height: "50px",
                    }}
                    disabled
                  >
                    <span>Update</span>
                  </Button>
                ) : (
                  <Button
                    className="level_expertise_button text-uppercase"
                    style={{
                      backgroundColor: "#d40000",
                      borderColor: "#d40000",
                      borderRadius: "0px 15px 0px 15px",
                      height: "50px",
                    }}
                    onClick={handleSubmit}
                  >
                    <span className="d-flex justify-content-center align-content-center w-100">
                      {loading ? <Loader /> : <span>Update</span>}
                    </span>
                  </Button>
                )}
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LevelOfExpertise;
