import React from "react";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { TailSpin } from "react-loader-spinner";
function Loader() {
  return (
    <TailSpin
      color="#fff"
      height={35}
      width={35}
      visible={true}
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
}

export default Loader;
