import apiProtected from 'api/apiClient';
import { setR8tingCategoryData } from 'redux/reducers/R8tr/R8tingCategoryDataReducer';

import { ApiUrl } from '../../../ApiUrl';

export const R8tingCategoryDataAction = (optionId) => (dispatch) => {
  apiProtected
    .get(
      `${ApiUrl.server_url}v1/rating-criteria-users/get-rating-criteria-options`,
      {
        params: {
          optionId,
        },
      }
    )
    .then((res) => {
      dispatch(setR8tingCategoryData(res.data));
    });
};
