import "firebase/messaging";

import firebase from "firebase/app";

// const firebaseConfig = {
//   apiKey: "AIzaSyCerriIeX-rcGwauX41wdZkLki-qlx2nBI",
//   authDomain: "r8tr-web-push-notifications.firebaseapp.com",
//   projectId: "r8tr-web-push-notifications",
//   storageBucket: "r8tr-web-push-notifications.appspot.com",
//   messagingSenderId: "135382388261",
//   appId: "1:135382388261:web:486f5df13d65b1453ab9cc",
//   measurementId: "G-9F04BCZDZV",
// };
const firebaseConfig = {
  apiKey: "AIzaSyDSON5PHjh4GGHAfAIC8ABPcmxGwophm4I",
  authDomain: "r8tr-fe17e.firebaseapp.com",
  databaseURL: "https://r8tr-fe17e.firebaseio.com",
  projectId: "r8tr-fe17e",
  storageBucket: "r8tr-fe17e.appspot.com",
  messagingSenderId: "800501660029",
  appId: "1:800501660029:web:b328389f09564bf1793414",
  measurementId: "G-S4P72PCNR1",
};
// firebase.initializeApp(firebaseConfig);

// const messaging = firebase.messaging();
let messaging = null;
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(firebaseConfig);
  messaging = firebase.messaging();
} else {
  console.log("no-support :(");
}

// if (firebase.messaging.isSupported()) {
//   messaging = firebase.messaging();
// }
const publicKey =
  "BLBHFL1Vy4I6YVUz0G6wsdA7y5gZKRkRgoGFfjtAqqtyo9iP1Dtl337uPEn-Cbrql7LZF2P7Mu9IjZiyQDVLQ2k";
// "BJlu7iQKKnYF6spkpXmYqc_SZeENjyVyuCWZmJLSIgS-YWzt35e3VWccykhZNg83TLsV80Kegq8BN7OKvda68_k";

export const getToken = async (setTokenFound) => {
  let currentToken = "";
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      localStorage.setItem("deviceToken", currentToken);
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occured while getting the push notification token");
    console.log(error);
  }
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
};
