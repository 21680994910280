import "./buyerratingr8tr.css";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useState } from "react";
import { Col,Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast,ToastContainer } from "react-toastify";

import {
  getBuyerRatingR8tr,
  getBuyerRatingResponce,
} from "../../../redux/actions/buyer/BuyerRatingR8trAction";
import Loader from "../../Loader/Loader";
const toastOptions = {
  position: "top-right",
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
};
function BuyerRatingR8tr() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const jobData = location.state.jobData;
  const TabsArray = [8, 7, 6, 5, 4, 3, 2, 1];
  const TipArray = ["8%", "18%", "28%", "AMT"];
  const [tabeR8tr, setTabeR8tr] = useState(8);
  const [helpR8tr, setHelepR8tr] = useState(false);
  const [tabeApp, setTabeApp] = useState(8);
  const [saveMoney, setSaveMoney] = useState(0);
  const [tipValue, setTipValue] = useState(null);
  const [tipTab, setTipTab] = useState("");
  const [buyProduct, setBuyProduct] = useState(false);
  const [commentOne, setCommentOne] = useState("");
  const [commentTwo, setCommentTwo] = useState("");
  const handler8tingTipTab = (value) => {
    let tip = 0;
    TipArray.map((item) => {
      if (value == item) {
        if (value == "8%") {
          tip = (jobData?.buyerCost / 100) * 8;
          tip = tip.toFixed(2);
        } else if (value == "18%") {
          tip = (jobData?.buyerCost / 100) * 18;
          tip = tip.toFixed(2);
        } else if (value == "28%") {
          tip = (jobData?.buyerCost / 100) * 28;
          tip = tip.toFixed(2);
        }
        setTipValue(tip);
        setTipTab(item);
      }
    });
  };
  const handler8tingR8trTab = (value) => {
    TabsArray.map((item) => {
      if (value == item) {
        setTabeR8tr(item);
      }
    });
  };
  const handler8tingAppTab = (value) => {
    TabsArray.map((item) => {
      if (value == item) {
        setTabeApp(item);
      }
    });
  };
  const handlePressNextRes = (message) => {
    setLoading(false);
    toast.error(message, toastOptions);
    // if(!message){
    //   if
    // }
  };
  const handlePressNextSuccessRes = (message) => {
    setLoading(false);
    toast.success(message, toastOptions);
    if (tipValue != null && tipValue > 0) {
      navigate("/tip-payment", { state: { tip: tipValue, jobId: jobData.id } });
    } else {
      navigate("/");
    }
  };
  const handlePressNext = () => {
    setLoading(true);
    dispatch(
      getBuyerRatingR8tr(
        jobData.id,
        tabeApp,
        tabeR8tr,
        commentOne,
        commentTwo,
        handlePressNextRes
      )
    );
    dispatch(
      getBuyerRatingResponce(
        jobData.id,
        saveMoney,
        helpR8tr,
        buyProduct,
        handlePressNextRes,
        handlePressNextSuccessRes
      )
    );
  };
  return (
    <div>
      <ToastContainer />
      <Container className="BuyerRatingR8tr_text_row">
        <Row className="BuyerRatingR8tr_text_row mt-4 mb-5">
          <Col
            xs={12}
            sm={12}
            md={8}
            lg={8}
            className="BuyerRatingR8tr_text_col"
          >
            <div className="BuyerRatingR8tr_text_div mt-2">
              <p
                style={{ fontSize: "18px" }}
                className="fw-bold theme_text_color Font_Family"
              >
                TIP YOUR R8TR ON
              </p>
              <p
                style={{ fontSize: "50px" }}
                className="fw-bold theme-color Font_Family"
              >
                ${jobData?.buyerCost}
              </p>
              <p
                style={{ fontSize: "16px" }}
                className=" theme_text_color Font_Family text-uppercase"
              >
                (The does not profit on tips)
              </p>
              <div className="BuyerRatingR8tr_tabs_div mt-2">
                <ul className="nav nav-pills">
                  {TipArray.length > 0 &&
                    TipArray.map((item) => (
                      <li
                        className={` Font_Family  BuyerRatingR8tr__tip_titeltabs fw-bold ${
                          tipTab == item ? "BuyerRatingR8tr__active" : ""
                        }`}
                        style={{ fontSize: "18px" }}
                        key={item.id}
                        onClick={() => {
                          handler8tingTipTab(item);
                        }}
                      >
                        <a data-toggle="pill">{item}</a>
                      </li>
                    ))}
                  <li className={` Font_Family  BuyerRatingR8tr__inputtabs`}>
                    <input
                      type="text"
                      placeholder="$"
                      value={tipValue}
                      className="BuyerRatingR8tr__input_tabs"
                      onChange={(e) => setTipValue(e.target.value)}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="BuyerRatingR8tr_tabs_div mt-4">
              <p
                style={{ fontSize: "18px" }}
                className="fw-bold theme_text_color Font_Family text-uppercase"
              >
                How satisfied are you with this R8tr
              </p>
              <ul className="nav nav-pills">
                {TabsArray.length > 0 &&
                  TabsArray.map((item) => (
                    <li
                      className={` Font_Family  BuyerRatingR8tr__titeltabs fw-bold ${
                        tabeR8tr == item ? "BuyerRatingR8tr__active" : ""
                      }`}
                      style={{ fontSize: "18px" }}
                      key={item.id}
                      onClick={() => {
                        handler8tingR8trTab(item);
                      }}
                    >
                      <a data-toggle="pill">{item}</a>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="BuyerRatingR8tr_tabs_div mt-4">
              <p
                style={{ fontSize: "18px" }}
                className="fw-bold theme_text_color Font_Family text-uppercase"
              >
                How likely are you to use the r8tr app again
              </p>
              <ul className="nav nav-pills">
                {TabsArray.length > 0 &&
                  TabsArray.map((item) => (
                    <li
                      className={` Font_Family  BuyerRatingR8tr__titeltabs fw-bold ${
                        tabeApp == item ? "BuyerRatingR8tr__active" : ""
                      }`}
                      style={{ fontSize: "18px" }}
                      key={item.id}
                      onClick={() => {
                        handler8tingAppTab(item);
                      }}
                    >
                      <a data-toggle="pill">{item}</a>
                    </li>
                  ))}
              </ul>
            </div>
          </Col>
          <hr className="mt-5"></hr>
          <Row className="R8trRating_inner_textarea_row mt-5 mb-5">
            <Col xs={12} sm={12} md={4} lg={4} className="R8trRating_text_col">
              <div style={{ width: "100%" }}>
                <p
                  style={{ fontSize: "16px" }}
                  className="fw-bold theme_text_color Font_Family"
                >
                  WHAT CAN WE DO BETTER AS COMPANY
                </p>
                <textarea
                  type="text"
                  placeholder="TYPE YOUR MESSAGE HERE..."
                  value={commentOne}
                  className="textArea"
                  style={{
                    width: "100%",
                    height: "100px",
                    resize: "none",
                    border: "1px solid #ebebeb",
                  }}
                  onChange={(e) => setCommentOne(e.target.value)}
                ></textarea>
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="R8trRating_text_col">
              <div style={{ width: "100%" }}>
                <p
                  style={{ fontSize: "16px" }}
                  className="fw-bold theme_text_color Font_Family"
                >
                  ANY SUGGESTION TO IMPROVE R8TR
                </p>
                <textarea
                  type="text"
                  value={commentTwo}
                  className="textArea"
                  placeholder="TYPE YOUR MESSAGE HERE..."
                  style={{
                    width: "100%",
                    height: "100px",
                    resize: "none",
                    border: "1px solid #ebebeb",
                  }}
                  onChange={(e) => setCommentTwo(e.target.value)}
                ></textarea>
              </div>
            </Col>
          </Row>
          <hr></hr>
          <Row className="BuyerRatingR8tr_text_row ">
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className="BuyerRatingR8tr_text_col checkbox_col"
            >
              <p
                style={{ fontSize: "18px" }}
                className="fw-bold theme_text_color Font_Family"
              >
                THANK YOU FOR USING R8TR!
              </p>
            </Col>
            <Row className="R8trRating_inner_row">
              <Col xs={12} sm={12} md={4} lg={4} className="checkbox_col ">
                <FormGroup>
                  <FormControlLabel
                    label="DID YOU BUY THE PRODUCT?"
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setBuyProduct(e.target.value);
                        }}
                      />
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} className="checkbox_col ">
                <FormGroup>
                  <FormControlLabel
                    className="theme_text_color Font_Family"
                    label="DID R8TR HELP YOU MAKE THE DECISION?"
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setHelepR8tr(e.target.value);
                        }}
                      />
                    }
                    style={{
                      fontFamily: "Roboto, sans-serif",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={4}
                lg={5}
                className="px-4 mt-3 text-center checkbox_col1"
              >
                <p
                  style={{ fontSize: "15px" }}
                  className="theme_text_color Font_Family"
                >
                  APROXIMATELY HOW MUCH MONEY DID R8TR SAVE YOU IN TIME TRAVEL
                  AND EFFORT?
                </p>
                <input
                  type="text"
                  className="save_money_input mt-2"
                  placeholder="$"
                  // style={{
                  //   border: "1px solid #ebebeb",
                  //   height: "45px",
                  //   width: "55%",
                  // }}
                  onChange={(e) => {
                    setSaveMoney(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </Row>
          <button
            className="btn Font_Family mt-5 next_button"
            style={{}}
            // disabled={!specialNotes}
            onClick={() => {
              handlePressNext();
            }}
          >
            <span className="d-flex justify-content-center align-content-center w-100">
              {loading ? <Loader /> : <span> NEXT</span>}
              {/* NEXT */}
            </span>
          </button>
        </Row>
      </Container>
    </div>
  );
}

export default BuyerRatingR8tr;
