import './r8trjobs.css';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useScreenType from 'react-screentype-hook';

import icon from '../../../assets/images/current_jobs/icon.webp';
import icon2 from '../../../assets/images/current_jobs/icon2.webp';
import { AvailableJobsAction } from '../../../redux/actions/R8tr/AvailableJobsAction';
import { CurrentJobsAction } from '../../../redux/actions/R8tr/CurrentJobsAction';
const R8trJobs = ({ showCurrentJobs }) => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [buttonBackground, setDuttonBackground] = useState(true);
  const [jobsDataState, setJobsDataState] = useState([]);
  const screenType = useScreenType();
  const toggle = () => setIsOpen(!isOpen);
  const { availableJobsData } = useSelector(
    (state) => state.AvailableJobsReducer
  );
  const { currentJobsData } = useSelector((state) => state.CurrentJobsReducer);

  useEffect(() => {
    setJobsDataState([]);

    if (showCurrentJobs) {
      setJobsDataState(
        [...currentJobsData].sort((a, b) => a.distance - b.distance)
      );

      return;
    }

    if (availableJobsData.length > 0) {
      setJobsDataState(
        [...availableJobsData].sort((a, b) => a.distance - b.distance)
      );
    }
  }, [availableJobsData, currentJobsData, showCurrentJobs]);

  useEffect(() => {
    dispatch(AvailableJobsAction());
    dispatch(CurrentJobsAction());
  }, []);
  useEffect(() => {
    if (screenType.isMobile) {
      setIsOpen(false);
    }
  }, []);
  const handleJobsSortting = (e) => {
    if (e == 'distance') {
      setDuttonBackground(true);
      let tempArr = [];
      tempArr = jobsDataState.sort((a, b) => {
        return a.distance - b.distance;
      });
      setJobsDataState(tempArr);
    }
    if (e == 'value') {
      setDuttonBackground(false);
      let tempArr = [];
      tempArr = jobsDataState.sort((a, b) => {
        return a.askingPrice - b.askingPrice;
      });
      setJobsDataState(tempArr);
      // setValueColor(true);
    }
  };

  return (
    <div
      className={` job_container ${
        isOpen ? 'job_container_width ' : 'job_container_width2'
      }`}
    >
      <div className="top_section">
        <div
          className={` jobs_bars ${
            isOpen ? 'jobs_bars_margin_open ' : 'jobs_bars_margin_close'
          }`}
          onClick={toggle}
        >
          {isOpen ? (
            <img src={icon2} alt="icon" style={{ height: '13px' }} />
          ) : (
            <img src={icon} alt="icon" style={{ height: '13px' }} />
          )}
          {/* <img src={icon} alt="icon" style={{ height: "13px" }} /> */}
        </div>
        <h6
          className="logo Font_Family theme_text_color text-uppercase"
          style={{ display: isOpen ? 'block' : 'none' }}
        >
          {showCurrentJobs ? 'Current Jobs' : 'Available Jobs'}
        </h6>
      </div>
      {/* <div
        className="jobs_sidebar"
      > */}
      <div
        className={`${
          buttonBackground
            ? 'currentjob_button_backgroun_container1'
            : 'currentjob_button_backgroun_container2'
        }`}
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <button
          type="button"
          // className=" "
          className={` currentjob_button_distance Font_Family ${
            buttonBackground
              ? 'currentjob_button_backgroun_container1_button'
              : 'currentjob_button_backgroun_container2_button'
          }`}
          onClick={() => handleJobsSortting('distance')}
        >
          DISTANCE
        </button>
        <button
          type="button"
          // className="currentjob_button_value Font_Family"
          className={` currentjob_button_value Font_Family ${
            buttonBackground
              ? 'currentjob_button_backgroun_container2_button'
              : 'currentjob_button_backgroun_container1_button'
          }`}
          onClick={() => handleJobsSortting('value')}
        >
          VALUE
        </button>
      </div>
      {/* <table style={{ width: "100%" }}> */}
      {/* <Table> */}
      {jobsDataState.length > 0 ? (
        jobsDataState.map((item) => (
          <tbody
            key={item.id}
            style={{ display: isOpen ? 'block' : 'none' }}
            className="currentjob_tbody Font_Family"
            onClick={() => navigator('/detail', { state: { jobId: item.id } })}
          >
            <tr key={item.id} className="currentjob_tr">
              {/* <td className="currentjob_td">
                <img
                  src={item.category.icon}
                  alt="car"
                  style={{ width: "30px", height: "30px" }}
                />
              </td> */}
              <td
                className="currentjob_td Font_Family text-theme_text_color text-uppercase d-flex flex-row "
                style={{
                  width: '55%',
                }}
              >
                <svg
                  viewBox="0 0 100 100"
                  version="1.1"
                  xmlns={item?.category?.icon}
                  style={{ marginRight: '10px' }}
                  className="currentjob_svg"
                >
                  <defs>
                    <pattern
                      id={item.id}
                      patternUnits="userSpaceOnUse"
                      width="100"
                      height="100"
                    >
                      <image
                        xlinkHref={item?.category?.icon}
                        x="-25"
                        width="250"
                        height="100"
                      />
                    </pattern>
                  </defs>
                  <polygon
                    points="50 1 95 25 95 75 50 99 5 75 5 25"
                    fill={`url(#${item.id})`}
                  />
                </svg>
                <br />
                <span
                  style={{
                    width: '65%',
                    alignItems: 'center',
                  }}
                >
                  {/* <span> */}
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      marginBottom: '0px',
                    }}
                  >
                    {item.make} {item.model}
                  </p>
                  {/* </span>
                  <br /> */}
                  {item.year && (
                    <span
                      style={{
                        fontSize: '10px',
                        backgroundColor: '#edeeee',
                        borderRadius: '15px',
                        padding: '2px 4px',
                        textAlign: 'center',
                        color: '#414042',
                      }}
                      className="Font_Family fw-bold"
                    >
                      {item.year}
                    </span>
                  )}
                </span>
              </td>
              <td
                className="currentjob_td Font_Family text-theme_text_color "
                style={{
                  width: '80px',
                }}
              >
                <span
                  style={{ color: '#5c647c', fontSize: '11px' }}
                  className="text-uppercase fw-bold"
                >
                  price
                </span>
                <br />{' '}
                <span
                  className="theme-color Font_Family"
                  style={{ fontSize: '15px' }}
                >
                  ${item.buyerCost / 2}
                </span>
              </td>
              <td className="currentjob_td Font_Family text-theme_text_color">
                <span
                  style={{ color: '#5c647c', fontSize: '11px' }}
                  className="text-uppercase fw-bold"
                >
                  Miles
                </span>
                <br />
                <span
                  className="theme_text_color Font_Family"
                  style={{ fontSize: '15px' }}
                >
                  {item?.distance?.toFixed(2)}
                </span>
              </td>
            </tr>
          </tbody>
        ))
      ) : (
        // <tbody
        //   style={{ display: isOpen ? "block" : "none" }}
        //   className="currentjob_tbody Font_Family"
        // >
        <tr className="currentjob_tr_error">
          <th
            className="fw-bold Font_Family theme_text_color"
            style={{ display: isOpen ? 'block' : 'none' }}
          >
            No Job Available
          </th>
        </tr>
        // </tbody>
      )}

      {/* </Table> */}
      {/* </table> */}
      {/* </div> */}
    </div>
  );
};

export default R8trJobs;
