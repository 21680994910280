import "./app.css";

import CloseIcon from "@mui/icons-material/Close";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useScreenType from "react-screentype-hook";
// import socketServcies from "./socketService";
import socketIO from "socket.io-client";

import { ApiUrl } from "./ApiUrl";
import Notification from "./components/Notifications/Notifications";
import ReactNotifications from "./components/Notifications/ReactNotifications";
import { onMessageListener } from "./firebaseInit";
import { getAllBuyerCurrentJob } from "./redux/actions/buyer/BuyerJobAction";
import { getAllBuyerPastJob } from "./redux/actions/buyer/BuyerJobAction";
import { AvailableJobsAction } from "./redux/actions/R8tr/AvailableJobsAction";
import { CurrentJobsAction } from "./redux/actions/R8tr/CurrentJobsAction";
import { UserDashboardStatAction } from "./redux/actions/R8tr/UserDashboardStatAction";
import AppRouter from "./routes/index";
import ProfileClickedContext from "./utils/context";
import SubAccountContext from "./utils/SubAccountContext";
const socket = socketIO.connect(ApiUrl.socket_url);
export default function App() {
  const dispatch = useDispatch();
  const [profileClickedState, setProfileClickedState] = React.useState(false);
  const [userIdState, setUserIdState] = React.useState(null);
  const [open, setOpen] = React.useState(true);
  const screenType = useScreenType();
  const [showNotification, setShowNotification] = React.useState(false);
  const [notification, setNotification] = React.useState({
    title: "",
    body: "",
  });
  onMessageListener()
    .then((payload) => {
      dispatch(AvailableJobsAction());
      dispatch(CurrentJobsAction());
      dispatch(UserDashboardStatAction());
      dispatch(getAllBuyerCurrentJob());
      dispatch(getAllBuyerPastJob());
      setShowNotification(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((error) => {
      console.log(error);
    });

  const profileClickedHandler = (value) => {
    setProfileClickedState(value);
  };
  const subAccountIdHandler = (value) => {
    setUserIdState(value);
  };
  return (
    <>
      {screenType.isMobile && (
        // ||screenType.isTablet
        <Collapse
          in={open}
          style={{ position: "absolute", zIndex: "1500", width: "100%" }}
        >
          <Alert
            icon={<PhoneIphoneIcon />}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.r8tr_app"
              style={{
                textDecoration: "none",
                color: "#414042",
                cursor: "pointer",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Get R8tr App for Mobile and browse faster
            </a>
          </Alert>
        </Collapse>
      )}
      <ProfileClickedContext.Provider
        value={{
          profileClicked: profileClickedState,
          profileClickedHandler: profileClickedHandler,
        }}
      >
        <SubAccountContext.Provider
          value={{
            userId: userIdState,
            subAccountIdHandler: subAccountIdHandler,
          }}
        >
          {showNotification ? (
            <ReactNotifications
              title={notification.title}
              body={notification.body}
            />
          ) : (
            <></>
          )}
          <Notification />
          <AppRouter socket={socket} />
        </SubAccountContext.Provider>
      </ProfileClickedContext.Provider>
    </>
  );
}
