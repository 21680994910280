import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userDetails: {},
  userCreate: {},
  allStates: [],
  allCosts: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserDetail: (state, action) => {
      state.userDetails = action.payload;
    },
    completeProfileUser: (state, action) => {
      state.userCreate = action.payload;
    },
    getAllStates: (state, action) => {
      state.allStates = [...action.payload.data];
    },
  },
});

export const { getUserDetail, completeProfileUser, getAllStates } =
  userSlice.actions;

export default userSlice.reducer;
