import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  defaultAddress: {},
};

const defaultAddressSlice = createSlice({
  name: 'defaultAddress',
  initialState,
  reducers: {
    setDefaultAddress: (state, action) => {
      state.defaultAddress = action.payload;
    },
  },
});

export const { setDefaultAddress } = defaultAddressSlice.actions;

export default defaultAddressSlice.reducer;
