import { makeStyles } from "@material-ui/core";

export const TopBarStyle = makeStyles((theme) => ({
  topBar: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "250px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    backgroundColor: "white",
    color: "black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
  },
  topBarR8tr: {
    [theme.breakpoints.up("sm")]: {
      // marginLeft: "250px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    backgroundColor: "white",
    color: "black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
  },
  topBarLogo: {
    height: "50px",
    width: "88px",
  },
  navlink_list: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    display: "none",
  },
  navlink_list2: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 6px",
  },
  topBarContent: {
    color: "black",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  notification_alert: {
    width: "15px",
    height: "15px",
    zIndex: "1",
    position: "absolute",
    left: "30px",
    bottom: "20px",
    backgroundColor: "#e61d2a",
    textAlign: "center",
    borderRadius: "25px",
  },
}));
