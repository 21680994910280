import './getotp.css';

import { CallOutlined } from '@mui/icons-material';
import { api } from 'api/apiClient';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../ApiUrl';
import Loader from '../Loader/Loader';
import OtpVerification from '../OtpVerification/OtpVerification';
function GetOtp() {
  const ebayUrl = new URL(window.location).searchParams;

  const itemId = ebayUrl.get('itemID');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOtpVerificationModal, setShowOtpVerificationModal] =
    useState(false);
  const toastOptions = {
    position: 'top-right',
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  };

  const handleShowOtpVerificationModal = () => {
    setShowOtpVerificationModal(!showOtpVerificationModal);
  };
  const handlePhoneNumber = () => {
    setLoading(true);

    api
      .post(`${ApiUrl.server_url}v1/auth/send-otp`, {
        phoneNumber: phoneNumber,
        forgetPasswordOTP: true,
      })
      .then(() => {
        setLoading(false);

        setShowOtpVerificationModal(!showOtpVerificationModal);
      })
      .catch((error) => {
        let errorMessage = error.response.data.message;

        toast.error(
          errorMessage ? errorMessage : 'Something went wrong',
          toastOptions
        );
        setLoading(false);
      });
  };
  return (
    <div className="getotp_container">
      <ToastContainer />
      <Container className="getotp_row_container">
        <Row className="getotp_row_container">
          <Col xxl={4} xl={4} lg={4} md={5} sm={12} xs={12}>
            <p className="h1 text-center Font_Family getotp_p_tage">
              ENTER YOUR PHONE NUMBER
            </p>
            <form className="w-100">
              <div className="getotp-input-container">
                <i className="fa fa-user getotp-icon">
                  <CallOutlined className="signup_input_icon" />
                </i>
                <input
                  className="getotp-input-field"
                  type="text"
                  placeholder="PHONE NUMBER/EMAIL"
                  id="phoneNumber"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  style={{ borderBottomLeftRadius: '0px' }}
                />
              </div>
              <button
                type="button"
                className="btn  w-100 Font_Family"
                style={{
                  height: '45px',
                  borderRadius: '0px 25px 0px 25px',
                  backgroundColor: '#e61d2a',
                  color: 'white',
                }}
                onClick={handlePhoneNumber}
              >
                <span className="d-flex justify-content-center align-content-center w-100">
                  {loading ? <Loader /> : <span>SEND OTP</span>}
                </span>
              </button>
            </form>
          </Col>
          <Col
            xxl={6}
            lg={6}
            md={6}
            sm={0}
            xs={0}
            className="getotp_column_two"
          ></Col>
        </Row>
        {showOtpVerificationModal && (
          <OtpVerification
            handleShowOtpVerificationModal={handleShowOtpVerificationModal}
            forgetPassword={true}
            phoneNumber={phoneNumber}
            itemId={itemId}
          />
        )}
      </Container>
    </div>
  );
}

export default GetOtp;
