import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsLoggedIn } from 'redux/reducers/loginSlice';

const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(setIsLoggedIn(false));
    localStorage.removeItem('tokens');
    localStorage.removeItem('userRole');

    navigate('/login');
  };

  return logout;
};

export default useLogout;
