import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import Layout from '../components/SideNav/layout/Layout';

const ProtectedRoute = ({ socket }) => {
  const tokens = JSON.parse(localStorage.getItem('tokens'));
  const { isLoggedIn } = useSelector((state) => state.loginSlice);

  const { id } = useParams();
  const navigate = useNavigate();

  return isLoggedIn && tokens?.accessToken ? (
    <Layout socket={socket}>
      <Outlet />
    </Layout>
  ) : (
    navigate('/login', { state: { userId: id } })
  );
};

export default ProtectedRoute;
