import './ProfileInformation.css';

import { Avatar } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import apiProtected from 'api/apiClient';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { validate } from 'react-email-validator';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import S3FileUpload from 'react-s3';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../../ApiUrl';
import {
  completeProfileUser,
  getUserData,
} from '../../../redux/actions/UserAction';
import ProfileClickedContext from './../../../utils/context';
window.Buffer = window.Buffer || require('buffer').Buffer;

import useLogout from 'redux/actions/useLogout';

import camara from '../../../assets/images/personal_info/icon.png';
import { DeleteUserAccountAction } from '../../../redux/actions/DeleteUserAccountAction';
import * as SweetAlert from '../../SweetAlert/index';
import Loader from './../../Loader/Loader';
const ProfileInformation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigator = useNavigate();
  const massage = location?.state?.massage;
  const profileClickedContext = useContext(ProfileClickedContext);
  profileClickedContext.profileClickedHandler(true);
  const [isReadonly, setIsReadonly] = useState(true);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [bio, setBio] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [userProfilePic, setUserProfilePic] = useState();
  const [userProfile, setUserProfile] = useState();
  const [imageUploading, setImageUploading] = useState(false);
  const logout = useLogout();
  // const [subAccountParentId, setSubAccountParentId] = useState(parentId.userId);
  const toastOptions = {
    position: 'top-right',
    autoClose: 4000,
    pauseOnHover: true,
    draggable: true,
    theme: 'dark',
  };

  const { userDetails } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    dispatch(getUserData());
  }, []);
  useEffect(() => {
    if (massage) {
      toast.error(massage, toastOptions);
    }
  }, [massage]);

  useEffect(() => {
    if (
      (userDetails?.data?.user?.firstName == null ||
        userDetails?.data?.user?.firstName == '') &&
      (userDetails?.data?.user?.email == null ||
        userDetails?.data?.user?.email == '')
    ) {
      setIsReadonly(false);
    }
    //  else {
    setFirstName(userDetails?.data?.user?.firstName);
    setMobileNumber(userDetails?.data?.user?.mobileNumber);
    setEmail(userDetails?.data?.user?.email);
    setCompanyName(userDetails?.data?.user?.companyName);
    setBio(userDetails?.data?.user?.bio);
    setUserProfilePic(userDetails?.data?.user?.avatar);
    // setSubAccountParentId(parentId.userId);
    // }
  }, [userDetails]);

  const handlefirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleBioChange = (event) => {
    setBio(event.target.value);
  };

  let handleFormEnabled = () => {
    setIsReadonly(!isReadonly);
    setShowPasswordField(!showPasswordField);
  };

  const handleResponse = (data) => {
    if (data.status) {
      setIsReadonly(true);
      setShowPasswordField(false);
      setLoading(false);
      toast.success(data.message, toastOptions);
    } else {
      setLoading(false);
      toast.error(data.message, toastOptions);
    }
  };
  const handleUploadImage = async (file) => {
    const config = {
      bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
      dirName: `${process.env.REACT_APP_ENVIRONMENT}/profilePics`,
      region: process.env.REACT_APP_S3_REGION,
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    };

    try {
      setImageUploading(true);
      let uploadRes = await S3FileUpload.uploadFile(file, config);
      setImageUploading(false);
      return uploadRes.location;
    } catch (error) {
      setImageUploading(false);
      return;
    }
  };
  const handleSubmit = () => {
    let emailValid = validate(email);
    if (!emailValid) {
      toast.error('Please Add valid Email', toastOptions);
      return;
    }
    setLoading(true);

    if (currentPassword) {
      apiProtected.patch(`${ApiUrl.server_url}v1/users/change-password`, {
        currentPassword: currentPassword,
        password: password,
        confirmPassword: confirmPassword,
      });
      setLoading(false);
      setIsReadonly(true);
      setShowPasswordField(false);
    }
    let data = {
      firstName: firstName,
      email: email,
      companyName: companyName,
      bio: bio,
      avatar: userProfile,
    };
    dispatch(completeProfileUser(data, handleResponse));
  };

  const handleProfilePic = async (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    setUserProfilePic(url);
    const latestImagesUrls = await handleUploadImage(e.target.files[0]);
    setUserProfile(latestImagesUrls);
  };

  const DeleteUserAccountActionRes = () => {
    logout();
  };

  const handleDeleteVideosRes = () => {
    dispatch(DeleteUserAccountAction(DeleteUserAccountActionRes));
  };

  const handleDeleteAccount = () => {
    SweetAlert.deleteAccountAlert(handleDeleteVideosRes);
  };

  return (
    <div className="Font_Family theme_text_color profile_information_main_div">
      <Container>
        <ToastContainer />
        <Row className="profile_information_row">
          <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
            <div className="profile_information_heading_div">
              <Typography variant="h6" component="div">
                <span className="profile_information_heading_span">
                  ACCOUNT SETTINGS
                </span>
              </Typography>
              {userDetails?.data?.user.firstName ? (
                <span className="profile_information_button_span">
                  <Button
                    className="text-uppercase"
                    style={{
                      color: '#d40000',
                      backgroundColor: 'white',
                      borderColor: 'white',
                      fontWeight: '600',
                      boxShadow: 'none',
                      outline: 'none',
                    }}
                    onClick={handleFormEnabled}
                  >
                    {isReadonly ? (
                      <span className="pl-2 capitalize">Edit</span>
                    ) : (
                      <span className="pl-2 capitalize">Cancel</span>
                    )}
                  </Button>

                  <div className="delete_button_web_screen">
                    <Button
                      className="text-uppercase "
                      style={{
                        color: '#d40000',
                        backgroundColor: 'white',
                        borderColor: 'white',
                        fontWeight: '600',
                        boxShadow: 'none',
                        outline: 'none',
                      }}
                      onClick={handleDeleteAccount}
                    >
                      <span className="pl-2 capitalize">Delete Account</span>
                    </Button>
                  </div>
                </span>
              ) : (
                ''
              )}
            </div>

            <form style={{ width: '100%' }}>
              <div className="w-100 d-flex flex-column justify-content-center align-items-center position-relative mt-5 mb-5">
                <div className="profile_information_profile_pic_div">
                  <input
                    type="file"
                    id="profile"
                    accept="image/*"
                    onChange={handleProfilePic}
                    disabled={isReadonly}
                    style={{ width: '0px', height: '0px' }}
                  />
                  <Avatar
                    alt="Remy Sharp"
                    src={userProfilePic}
                    style={{ width: '200px', height: '200px' }}
                  />
                </div>
                <img
                  src={camara}
                  alt="camara"
                  className="camara_icon"
                  style={{
                    width: '40px',
                    height: '40px',
                    display: isReadonly ? 'none' : 'block',
                  }}
                  onClick={() => {
                    document.getElementById('profile').click();
                  }}
                />
              </div>
              <TableContainer style={{ width: '100%' }}>
                <Table style={{ width: '100%' }} aria-label="simple table">
                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className="Font_Family text-uppercase"
                        style={{
                          color: '#414042',
                          borderBottom: isReadonly
                            ? '1px solid #E0E0E0'
                            : 'none',
                        }}
                      >
                        <b>Name</b>
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#414042',
                          borderBottom: isReadonly
                            ? '1px solid #E0E0E0'
                            : 'none',
                        }}
                      >
                        <input
                          style={{
                            border: isReadonly ? 'none' : '1px solid #E0E0E0',
                            borderBottom: isReadonly
                              ? 'none'
                              : '1px solid #E0E0E0',
                          }}
                          className="silver_Color w-full input_field_profile_information"
                          value={firstName ? firstName : ''}
                          name="firstName"
                          onChange={handlefirstNameChange}
                          disabled={isReadonly}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="Font_Family text-uppercase"
                        style={{
                          color: '#414042',
                          borderBottom: isReadonly
                            ? '1px solid #E0E0E0'
                            : 'none',
                        }}
                      >
                        <b>Mobile</b>
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#414042',
                          borderBottom: isReadonly
                            ? '1px solid #E0E0E0'
                            : 'none',
                        }}
                      >
                        <input
                          className="silver_Color w-full input_field_profile_information"
                          // defaultValue={mobileNumber}
                          value={userDetails?.data?.user?.mobileNumber}
                          disabled
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="Font_Family text-uppercase"
                        style={{
                          color: '#414042',
                          borderBottom: isReadonly
                            ? '1px solid #E0E0E0'
                            : 'none',
                        }}
                      >
                        <b>Company</b>
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#414042',
                          borderBottom: isReadonly
                            ? '1px solid #E0E0E0'
                            : 'none',
                        }}
                      >
                        <input
                          style={{
                            border: isReadonly ? 'none' : '1px solid #E0E0E0',
                          }}
                          className="silver_Color w-full input_field_profile_information"
                          value={companyName ? companyName : ''}
                          name="companyName"
                          onChange={handleCompanyNameChange}
                          disabled={isReadonly}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="Font_Family text-uppercase"
                        style={{
                          color: '#414042',
                          borderBottom: isReadonly
                            ? '1px solid #E0E0E0'
                            : 'none',
                        }}
                      >
                        <b>Email</b>
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#414042',
                          borderBottom: isReadonly
                            ? '1px solid #E0E0E0'
                            : 'none',
                        }}
                      >
                        <input
                          style={{
                            border: isReadonly ? 'none' : '1px solid #E0E0E0',
                          }}
                          className="silver_Color w-full input_field_profile_information"
                          value={email ? email : ''}
                          name="email"
                          onChange={handleEmailChange}
                          disabled={isReadonly}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="Font_Family text-uppercase"
                        style={{
                          color: '#414042',
                          borderBottom: isReadonly
                            ? '1px solid #E0E0E0'
                            : 'none',
                        }}
                      >
                        <b>Bio</b>
                      </TableCell>
                      <TableCell
                        style={{
                          color: '#414042',
                          borderBottom: isReadonly
                            ? '1px solid #E0E0E0'
                            : 'none',
                        }}
                      >
                        <textarea
                          style={{
                            border: isReadonly ? 'none' : '1px solid #E0E0E0',
                          }}
                          className="silver_Color w-full input_field_profile_information_textfield"
                          value={bio ? bio : ''}
                          name="bio"
                          onChange={handleBioChange}
                          disabled={isReadonly}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {userDetails?.data?.user.firstName && (
                <div
                  className="Font_Family my-3 ps-3 "
                  style={{
                    color: '#414042',
                    width: '100%',
                    borderBottom: isReadonly ? '1px solid #E0E0E0' : 'none',
                  }}
                >
                  <p>
                    <b>CHANGE PASSWORD</b>
                  </p>
                </div>
              )}
              {showPasswordField && (
                <>
                  <div className="Font_Family p-3 profile_information_div_password">
                    <input
                      style={{
                        border: isReadonly ? 'none' : '1px solid #E0E0E0',
                      }}
                      className="silver_Color w-full input_field_profile_information_password"
                      type="password"
                      placeholder="CURRENT PASSWORD"
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </div>
                  <div className="Font_Family p-3 profile_information_div_password">
                    <input
                      style={{
                        border: isReadonly ? 'none' : '1px solid #E0E0E0',
                      }}
                      className="silver_Color w-full input_field_profile_information_password"
                      type="password"
                      placeholder="NEW PASSWORD"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="Font_Family p-3 profile_information_div_password">
                    <input
                      style={{
                        border: isReadonly ? 'none' : '1px solid #E0E0E0',
                      }}
                      className="silver_Color w-full input_field_profile_information_password"
                      type="password"
                      placeholder="CONFIRM PASSWORD"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </>
              )}
              <div className="delete_button_mobile_screen">
                <Button
                  className="text-uppercase"
                  style={{
                    color: '#d40000',
                    backgroundColor: 'white',
                    borderColor: 'white',
                    fontWeight: '600',
                    boxShadow: 'none',
                    outline: 'none',
                  }}
                  onClick={handleDeleteAccount}
                >
                  <span className="pl-2 capitalize">Delete Account</span>
                </Button>
              </div>
              <div className="my-5 text-center">
                <Button
                  className="personal_info_button text-uppercase"
                  style={{
                    backgroundColor: '#d40000',
                    borderColor: '#d40000',
                    borderRadius: '0px 15px 0px 15px',
                    height: '50px',
                  }}
                  disabled={
                    isReadonly || !firstName || !email || imageUploading
                  }
                  type="button"
                  onClick={handleSubmit}
                >
                  <span className="d-flex justify-content-center align-content-center w-100 fw-bold">
                    {loading ? (
                      <Loader />
                    ) : (
                      <span>{imageUploading ? 'Uploading' : 'Update'}</span>
                    )}
                  </span>
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfileInformation;
