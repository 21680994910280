import apiProtected from 'api/apiClient';
import { setCurrentJobs } from 'redux/reducers/R8tr/CurrentJobsReducer';

import { ApiUrl } from '../../../ApiUrl';

export const CurrentJobsAction = () => (dispatch) => {
  apiProtected
    .get(`${ApiUrl.server_url}v1/jobs/get-current-jobs-for-r8tr`)
    .then((res) => {
      dispatch(setCurrentJobs(res.data));
    });
};
