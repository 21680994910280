export const ApiUrl = {
  server_url:
    window.location.origin == "https://r8trapp.com" ||
    window.location.origin == "https://www.r8trapp.com"
      ? "https://api.r8trapp.com/api/"
      : "https://devapi.r8trapp.com/api/",
  socket_url:
    window.location.origin == "https://r8trapp.com" ||
    window.location.origin == "https://www.r8trapp.com"
      ? "https://api.r8trapp.com/"
      : "https://devapi.r8trapp.com/",
};
