import "./styles.css";

import { LockOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import { Button, Col,Container, Form, Row } from "react-bootstrap";

export default function ResetPassword() {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const onSubmit = () => {
    if (password === confirmPassword) {
      // console.log("password", password);
      // console.log("confirmPassword", confirmPassword);
    } else {
      // console.log("password did not match");
    }
  };
  return (
    <div className="main_container">
      <Container>
        <Row className="row_container">
          <Col
            xxl={4}
            xl={4}
            lg={4}
            md={4}
            sm={10}
            xs={10}
            className="offset-md-7 offset-lg-7"
          >
            <Form className="w-100">
              {/* <p className="text-center h3 mb-5 text-bold Font_Family">
                Reset Password
              </p> */}
              <p className="text-center h1 text-bold Font_Family">
                Enter Your Password
              </p>
              <div className="input-container mx-auto">
                <i className="fa fa-user icon">
                  <LockOutlined style={{ color: "gray" }} />
                </i>
                <input
                  className="input-field"
                  type="password"
                  autoComplete="on"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{ borderBottomLeftRadius: "0px" }}
                />
              </div>
              <div className="input-container mx-auto">
                <i className="fa fa-user icon">
                  <LockOutlined style={{ color: "gray" }} />
                </i>
                <input
                  className="input-field"
                  type="password"
                  autoComplete="on"
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  style={{ borderBottomLeftRadius: "0px" }}
                />
              </div>
              <div className="text-center w-100">
                <Button
                  className="w-100 Font_Family"
                  style={{
                    height: "45px",
                    backgroundColor: "#d40000",
                    borderColor: "#d40000",
                    borderRadius: "0px 50px 0px 50px",
                  }}
                  size="lg"
                  type="button"
                  onClick={onSubmit}
                >
                  Done
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
