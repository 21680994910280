import "./buyerdashboard.css";

import { Grid } from "@mui/material";
import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BuyerJobs from "../../components/BuyerJobs/BuyerJob/BuyerJobs";
import { getUserData } from "../../redux/actions/UserAction";
function Dashboard() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [showDashboard, setShowDashboard] = useState(false);
  const { userDetails } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userRole") == "buyer") {
      if (userDetails?.data?.user?.phoneNumber) {
        if (
          userDetails.data?.user?.firstName == null ||
          userDetails.data?.user?.firstName == "" ||
          userDetails.data?.user?.email == null ||
          userDetails.data?.user?.email == ""
        ) {
          navigator("/personal-info");
        } else {
          setShowDashboard(true);
        }
      }
    } else {
      setShowDashboard(true);
    }
  }, [userDetails]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="buyerdashboard_container"
      >
        {showDashboard && <BuyerJobs />}
      </Grid>
    </Grid>
  );
}

export default Dashboard;
